import {useEffect, useRef, useState} from 'react';

export type Vec2 = [number, number];
export type EasingFunction = (t: number) => number;

const sub = (a: Vec2, b: Vec2): Vec2 => [a[0] - b[0], a[1] - b[1]];
const scale = (vec: Vec2, a: number): Vec2 => [vec[0] * a, vec[1] * a];

export function useAnimatedPosition(
  from: Vec2,
  to: Vec2,
  easingFunction: EasingFunction,
  duration: number,
  enabled: boolean,
) {
  const [currentPosition, setCurrentPosition] = useState(to);
  const alpha = useRef(1);
  const animating = useRef(false);

  let position = currentPosition;

  if (enabled) {
    alpha.current = 0;
    position = from;
  }

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let elapsed = 0;
    let previous: number | null = null;

    animating.current = true;

    const step = (t: number) => {
      if (previous === null) {
        previous = t;
      }

      elapsed += t - previous;

      if (elapsed >= duration) {
        elapsed = 0;
        previous = 0;
        setCurrentPosition(to);
        alpha.current = 1;
        animating.current = false;
        return;
      }

      alpha.current = easingFunction(elapsed / duration);

      const nextPosition = sub(from, scale(sub(from, to), alpha.current));

      setCurrentPosition(nextPosition);

      requestAnimationFrame(step);

      previous = t;
    };

    requestAnimationFrame(step);
  }, [enabled, to, from, easingFunction, duration]);

  return {
    alpha: alpha.current,
    position: position,
    animating: animating.current,
    setPosition: setCurrentPosition,
  };
}
