/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionStatusEnum {
    Active = 'Active',
    Processing = 'Processing',
    Complete = 'Complete',
    Accepted = 'Accepted',
    Error = 'Error',
    Deleted = 'Deleted'
}

export function TransactionStatusEnumFromJSON(json: any): TransactionStatusEnum {
    return TransactionStatusEnumFromJSONTyped(json, false);
}

export function TransactionStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionStatusEnum {
    return json as TransactionStatusEnum;
}

export function TransactionStatusEnumToJSON(value?: TransactionStatusEnum | null): any {
    return value as any;
}

