import {fatalOTCError} from '../api';

export const calculateOtcPercentage = (
  page: number,
  totalPages: number,
): number => {
  const percentage = page / totalPages;
  return percentage;
};

export const determineLoadingStatus = (
  loading: boolean,
  syncLoading: boolean,
  fatalError?: fatalOTCError,
): boolean => {
  if (fatalError && fatalError.error && fatalError.process === 'download') {
    return true;
  }
  return loading || syncLoading;
};
