import React, {memo, useEffect, useRef} from 'react';
// import swiper js
import {Swiper, SwiperSlide} from 'swiper/react';

// import required modules
import {Navigation, Pagination, Swiper as SwiperType} from 'swiper';

const swiper = {
  width: '100%',
  height: '100%',
  zIndex: 0,
};

const slideWidth = {
  width: 'auto',
};

export const Carousel = memo(
  ({...props}: React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    // store a ref to the swiper instance we get so that we can have some control over nav buttons/arrows
    const swiperRef = useRef<SwiperType>();

    // throws up an indicator that no child elements were submitted as props and therefore we have an empty carousel
    const onEmptyProps = () => {
      return (
        <SwiperSlide style={slideWidth}>
          <h2 data-testid="carousel-emptyindicator">
            No slide element props detected!
          </h2>
        </SwiperSlide>
      );
    };

    useEffect(() => {
      /**
       * TODO: Swiper does not give you direct access to the inline styles for the navigation buttons.
       * If we import a CSS file, it works while viewing storybook, but webpack throws an error when
       * trying to build the app-pos workspace.
       *
       * This is a workaround for now, but we should look into a better solution.
       */
      if (swiperRef && swiperRef.current) {
        if (swiperRef.current.navigation.prevEl) {
          // set button styles for prev button
          swiperRef.current.navigation.prevEl.style.opacity = '0.75';
          swiperRef.current.navigation.prevEl.style.backgroundColor =
            'whitesmoke';
          swiperRef.current.navigation.prevEl.style.height = '100%';
          swiperRef.current.navigation.prevEl.style.top = '8%';
          swiperRef.current.navigation.prevEl.style.width = '50px';
          swiperRef.current.navigation.prevEl.style.left = '0px';
        }

        if (swiperRef.current.navigation.nextEl) {
          // set button styles for next button
          swiperRef.current.navigation.nextEl.style.opacity = '0.75';
          swiperRef.current.navigation.nextEl.style.backgroundColor =
            'whitesmoke';
          swiperRef.current.navigation.nextEl.style.height = '100%';
          swiperRef.current.navigation.nextEl.style.top = '8%';
          swiperRef.current.navigation.nextEl.style.width = '50px';
          swiperRef.current.navigation.nextEl.style.right = '0px';
        }
      }
    }, [swiperRef]);

    return (
      <Swiper
        style={swiper}
        slidesPerView={'auto'}
        spaceBetween={30}
        centeredSlides={false}
        pagination={{
          clickable: true,
          type: 'custom',
        }}
        // event emitted by Swiper when user changes slides/navs through carousel
        onSlideChange={
          /* istanbul ignore next - cannot effectively test as we are relying on a mock of swiper here */
          () => {
            if (swiperRef && swiperRef.current) {
              // logic for controlling whether nav buttons/arrows are 'hidden' depending on where user is with carousel
              if (
                !swiperRef.current.isBeginning &&
                swiperRef.current.navigation.prevEl.hidden
              ) {
                swiperRef.current.navigation.prevEl.hidden = false;
                swiperRef.current.navigation.prevEl.style.display = '';
              }

              if (
                !swiperRef.current.isEnd &&
                swiperRef.current.navigation.nextEl.hidden
              ) {
                swiperRef.current.navigation.nextEl.hidden = false;
                swiperRef.current.navigation.nextEl.style.display = '';
              }
            }
          }
        }
        onReachEnd={swiper => {
          if (swiper?.navigation?.nextEl) {
            swiper.navigation.nextEl.hidden = true;
            swiper.navigation.nextEl.style.display = 'none';
          }
        }}
        onReachBeginning={swiper => {
          if (swiper?.navigation?.prevEl) {
            swiper.navigation.prevEl.hidden = true;
            swiper.navigation.prevEl.style.display = 'none';
          }
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        // onBeforeInit and onAfterInit events are also available (though many of the Swiper dom elements have not actually been built out yet with onBeforeInit)
        onInit={
          /* istanbul ignore next - cannot effectively test as we are relying on a mock of swiper here*/
          swiper => {
            swiperRef.current = swiper;
            swiperRef.current.navigation.prevEl.hidden = true;
            swiperRef.current.navigation.prevEl.style.display = 'none';
          }
        }
      >
        {props &&
          React.Children.map(props.children, (element: any, index: number) => (
            <SwiperSlide style={slideWidth} key={index}>
              {element}
            </SwiperSlide>
          ))}
        {!props.children && onEmptyProps()}
      </Swiper>
    );
  },
);
