import {token} from 'brandi';
import {
  ITransactionDatabaseAccess,
  ITransactionPaymentsDatabaseAccess,
} from './localDb/IDatabaseAccess';
import {ITransactionService} from './services/ITransactionService';

export const TOKENS = {
  TransactionService: token<ITransactionService>('TransactionService'),
  TransactionDatabaseAccess: token<ITransactionDatabaseAccess>(
    'TransactionDatabaseAccess',
  ),
  TransactionPaymentsDatabaseAccess: token<ITransactionPaymentsDatabaseAccess>(
    'ITransactionPaymentsDatabaseAccess',
  ),
};
