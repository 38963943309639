export enum ConsoleLoggerVariant {
  PURPLE = 'background-color:#554971; color: white;',
  PINK = 'background-color:#FFAEC3; color: black;',
  GREEN = 'background-color:#339989; , color: white;',
  CYAN = 'background-color:#01BAEF; color: black;',
  SLATE = 'background-color:#63768D; color: white;',
  ERROR = 'background-color:#6F1A07; color: white;',
  WARNING = 'background-color:#FFBF69; color: black;',
  NONE = '',
}

export interface IConsoleLogger {
  log: (...args: unknown[]) => void;
  styledLog: (
    title: string,
    variant: ConsoleLoggerVariant,
    ...args: unknown[]
  ) => void;
  logWarning: (...args: unknown[]) => void;
  logError: (...args: unknown[]) => void;
}

export class ConsoleLogger implements IConsoleLogger {
  private _nodeEnv: string | undefined;

  constructor() {
    const {NODE_ENV} = process.env;
    this._nodeEnv = NODE_ENV || undefined;
  }

  public log(...args: unknown[]): void {
    this._log(...args);
  }

  public styledLog(
    title: string,
    variant: ConsoleLoggerVariant | string,
    ...args: unknown[]
  ): void {
    this._log(
      `%c${title}`,
      `${variant} font-size: 1em; padding: 0.5em; font-weight: bold;`,
      ...args,
    );
  }

  public logWarning(...args: unknown[]): void {
    this.styledLog('WARNING:', ConsoleLoggerVariant.WARNING, ...args);
  }

  public logError(...args: unknown[]): void {
    this.styledLog('ERROR:', ConsoleLoggerVariant.ERROR, ...args);
  }

  private _shouldLog(): boolean {
    return this._nodeEnv === 'development' || this._nodeEnv === 'test';
  }

  private _log(...args: unknown[]): void {
    if (this._shouldLog()) console.log(...args);
  }
}
