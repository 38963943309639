import {ScanResult, Symbology} from '../KeyboardScannerService';
import {
  BarcodeParsedResult,
  CompleteBarcodeComponent,
  IPMSStrategy,
} from './IPMSStrategy';

export abstract class BaseStrategy implements IPMSStrategy {
  abstract validSymbologies: Symbology[];

  public decode(
    scanResult: ScanResult,
    barcodeComponents: CompleteBarcodeComponent[],
  ): BarcodeParsedResult {
    const {
      componentsCopy,
      barcode,
      delimiter,
      parsedResult,
      componentNames,
      componentValues,
    } = this.preparation(scanResult, barcodeComponents);

    if (!this.validate(componentsCopy, scanResult.symbology)) {
      return parsedResult;
    }

    const regexPattern = this.generateRegexPattern(
      componentsCopy,
      componentNames,
      delimiter,
    );

    const matches = barcode.match(regexPattern);

    if (!matches) return parsedResult;

    this.getComponentsValues(componentValues, matches, delimiter);

    return this.reduceComponents(parsedResult, componentNames, componentValues);
  }

  protected preparation(
    scanResult: ScanResult,
    barcodeComponents: CompleteBarcodeComponent[],
  ) {
    const componentsCopy = [...barcodeComponents]?.sort(
      (a, b) => a.componentIndex - b.componentIndex,
    );
    const delimiter = this.getDelimiter(componentsCopy, scanResult.raw);
    const barcode = scanResult.raw.trim();
    const parsedResult: BarcodeParsedResult = {
      fill: undefined,
      partial: undefined,
      rx: undefined,
      site: undefined,
    };
    const componentNames: string[] = [];
    const componentValues: string[] = [];

    return {
      componentsCopy,
      barcode,
      delimiter,
      parsedResult,
      componentNames,
      componentValues,
    };
  }

  protected validate(
    components: CompleteBarcodeComponent[],
    symbology: Symbology | null,
  ): boolean {
    return (
      components.length > 0 &&
      symbology !== null &&
      this.validSymbologies.includes(symbology)
    );
  }

  //#region Abstract methods
  abstract getDelimiter(
    components: CompleteBarcodeComponent[],
    rawBarcode: string,
  ): string | undefined;

  abstract generateRegexPattern(
    componentsCopy: CompleteBarcodeComponent[],
    componentNames: string[],
    delimiter: string | undefined,
  ): string;

  abstract getComponentsValues(
    componentValues: string[],
    matches: RegExpMatchArray,
    delimiter: string | undefined,
  ): void;

  abstract reduceComponents(
    parsedResult: BarcodeParsedResult,
    componentNames: string[],
    componentValues: string[],
  ): BarcodeParsedResult;
  //#endregion
}
