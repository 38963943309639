import {
  Customer,
  WillCallApi,
  WillCallRequest,
  WillCallResponseBodyApiResponse,
} from '@emporos/api-enterprise';
import {useCallback, useMemo} from 'react';
import {
  ApiLogTypes,
  mapTransactionCustomer,
  useLog,
  UseOpenApiHook,
  useTransactionsState,
} from '../';
import {useApi} from '../contexts/ApiProvider';

interface UseCustomerWillCall
  extends Omit<UseOpenApiHook<WillCallApi, 'clientWillCallPut'>, 'run'> {
  run: (param: WillCallRequest) => Promise<WillCallResponseBodyApiResponse>;
}

export function useCustomerWillCall(): UseCustomerWillCall & {
  customer: Customer | null;
} {
  const api = useApi();
  const {session} = useTransactionsState();
  const {logApi} = useLog();
  const hook = api.PutWillCall();
  const {run: _run, data} = hook;
  const {siteId, stationId} = session;
  const boundRun: (
    param: WillCallRequest,
  ) => Promise<WillCallResponseBodyApiResponse> = useCallback(
    param => {
      logApi(ApiLogTypes.CustomerPending);
      return _run({
        siteId: siteId,
        stationId: stationId,
        willCallRequest: param,
      });
    },
    [siteId, stationId, logApi, _run],
  );
  const customer = useMemo(
    () =>
      data && data.data?.customers[0]
        ? mapTransactionCustomer(
            data.data?.customers[0],
            data.data.prescriptions,
          )
        : null,
    [data],
  );

  return {...hook, run: boundRun, customer};
}
