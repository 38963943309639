/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentVoidRequest10
 */
export interface PaymentVoidRequest10 {
    /**
     * The site id
     * @type {number}
     * @memberof PaymentVoidRequest10
     */
    siteId: number;
    /**
     * ReferenceNumber for the void Request.
     * @type {string}
     * @memberof PaymentVoidRequest10
     */
    referenceNumber: string;
    /**
     * Id for the Transaction, this is sent as metadata to the Gateway
     * @type {string}
     * @memberof PaymentVoidRequest10
     */
    transactionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentVoidRequest10
     */
    userId?: string | null;
    /**
     * Unique Identifier for terminal, if required.
     * @type {string}
     * @memberof PaymentVoidRequest10
     */
    terminalId?: string | null;
}

export function PaymentVoidRequest10FromJSON(json: any): PaymentVoidRequest10 {
    return PaymentVoidRequest10FromJSONTyped(json, false);
}

export function PaymentVoidRequest10FromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentVoidRequest10 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteId': json['siteId'],
        'referenceNumber': json['referenceNumber'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'terminalId': !exists(json, 'terminalId') ? undefined : json['terminalId'],
    };
}

export function PaymentVoidRequest10ToJSON(value?: PaymentVoidRequest10 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteId': value.siteId,
        'referenceNumber': value.referenceNumber,
        'transactionId': value.transactionId,
        'userId': value.userId,
        'terminalId': value.terminalId,
    };
}


