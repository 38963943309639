import {CashDrawer, Site, Station} from '@emporos/api-enterprise';
import {
  Gutter,
  RowItem,
  ScrollContainer,
  SidebarWrapper,
  Stack,
  Text,
  TextVariant as Variant,
} from '@emporos/components';
import {startCase} from 'lodash';
import {memo} from 'react';
import {animated, useSpring} from 'react-spring';

interface Props {
  fullName: string;
  site: Site | null;
  station: Station | null;
  till: CashDrawer | null;
  paymentDeviceAddress: string | null;
}

const SessionSidebarChild = ({
  site,
  station,
  till,
  paymentDeviceAddress,
}: Props): JSX.Element => {
  const transition = useSpring({opacity: 1, from: {opacity: 0}});

  return (
    <SidebarWrapper
      as={animated.div}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      style={transition as any}
      data-testid="SessionSidebar"
    >
      <Stack style={{height: '100%'}}>
        <ScrollContainer>
          <Text
            variant={Variant.Caption}
            style={{paddingLeft: 16, paddingBottom: 16}}
          >
            My Session
          </Text>

          <Stack gutter={Gutter.S}>
            {site ? (
              <RowItem
                title="Site"
                subtitle={startCase(site.shortName)}
                variant="payment"
                leftIcon="Store"
                leftIconColor="purple"
              />
            ) : (
              <RowItem title="Site" variant="inverted" />
            )}
            {station ? (
              <RowItem
                title="Station"
                subtitle={station.description || ''}
                variant="payment"
                leftIcon="CashRegister"
                leftIconColor="indigo"
              />
            ) : (
              <RowItem title="Station" variant="inverted" />
            )}
            {till ? (
              <RowItem
                title="Till"
                subtitle={till.cashDrawerName || ''}
                variant="payment"
                leftIcon="Cash"
              />
            ) : (
              <RowItem title="Till" variant="inverted" />
            )}
            {paymentDeviceAddress ? (
              <RowItem
                title="Payment Device Address"
                subtitle={paymentDeviceAddress || ''}
                variant="payment"
                leftIcon="CreditCard"
              />
            ) : (
              <RowItem title="Payment" variant="inverted" />
            )}
          </Stack>
        </ScrollContainer>
      </Stack>
    </SidebarWrapper>
  );
};

export const SessionSidebar = memo(SessionSidebarChild);
