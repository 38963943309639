/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SettingListApiResponse,
    SettingListApiResponseFromJSON,
    SettingListApiResponseToJSON,
} from '../models';

export interface ClientSettingsGetRequest {
    siteId?: number;
    stationId?: number;
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * Gets Settings.
     */
    async clientSettingsGetRaw(requestParameters: ClientSettingsGetRequest): Promise<runtime.ApiResponse<SettingListApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingListApiResponseFromJSON(jsonValue));
    }

    /**
     * Gets Settings.
     */
    async clientSettingsGet(requestParameters: ClientSettingsGetRequest): Promise<SettingListApiResponse> {
        const response = await this.clientSettingsGetRaw(requestParameters);
        return await response.value();
    }

}
