import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY';

export const isBeforeNow = (date: string): boolean =>
  moment(date, DATE_FORMAT, true).isBefore(moment());

export const isAfterNow = (date: string): boolean =>
  moment(date, DATE_FORMAT, true).isAfter(moment());

export const diffYears = (date: string): number => {
  const years = moment().diff(moment(date, DATE_FORMAT, true), 'years');

  /**
   * the way moment.js rounds when obtaining a difference between
   * two dates, it will come up a year short when you provide a
   * date that's in the future.
   *
   * example: today is 02/15/2023 compare to 02/15/2073
   * result: -49 years when it should be -50
   *
   * we correct for this by subtracting 1 for any result that's negative.
   * if the result is positive (you provided a date that's in the past),
   * we just return the result as is.
   */
  return years < 0 ? years - 1 : years;
};

// Ensures two different dates are the same (used when verifying patient birthdays)
export const isSame = (customerInput: string, dateToCompare: string): boolean =>
  moment(moment(customerInput).format(DATE_FORMAT)).isSame(
    moment(dateToCompare).format(DATE_FORMAT),
  );

// Ensures that a date being entered is formatted correctly
export const isValid = (date: string): boolean =>
  moment(date, DATE_FORMAT).format(DATE_FORMAT) === date;
