/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Plan,
    PlanFromJSON,
    PlanFromJSONTyped,
    PlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface Prescription
 */
export interface Prescription {
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    adjudicationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    bin?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    counselRequiredIndicator?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Prescription
     */
    offerCounsel?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    dispensable?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    discontinuedIndicator: number;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    fill?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    itemNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    noSafetyCapIndicator?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    partial?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    partialFillIndicator: number;
    /**
     * 
     * @type {Array<Plan>}
     * @memberof Prescription
     */
    plans: Array<Plan>;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    proofIDIndicator?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    captureRelationshipIndicator?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    receiptDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    recordStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    rx?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    rxCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    site?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    taxableIndicator?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    siteShortName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    siteId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    medicationStatus: number;
}

export function PrescriptionFromJSON(json: any): Prescription {
    return PrescriptionFromJSONTyped(json, false);
}

export function PrescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adjudicationDate': !exists(json, 'adjudicationDate') ? undefined : json['adjudicationDate'],
        'bin': !exists(json, 'bin') ? undefined : json['bin'],
        'counselRequiredIndicator': !exists(json, 'counselRequiredIndicator') ? undefined : json['counselRequiredIndicator'],
        'offerCounsel': !exists(json, 'offerCounsel') ? undefined : json['offerCounsel'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dispensable': !exists(json, 'dispensable') ? undefined : json['dispensable'],
        'discontinuedIndicator': json['discontinuedIndicator'],
        'fill': !exists(json, 'fill') ? undefined : json['fill'],
        'itemNumber': !exists(json, 'itemNumber') ? undefined : json['itemNumber'],
        'noSafetyCapIndicator': !exists(json, 'noSafetyCapIndicator') ? undefined : json['noSafetyCapIndicator'],
        'partial': !exists(json, 'partial') ? undefined : json['partial'],
        'partialFillIndicator': json['partialFillIndicator'],
        'plans': ((json['plans'] as Array<any>).map(PlanFromJSON)),
        'price': json['price'],
        'proofIDIndicator': !exists(json, 'proofIDIndicator') ? undefined : json['proofIDIndicator'],
        'captureRelationshipIndicator': !exists(json, 'captureRelationshipIndicator') ? undefined : json['captureRelationshipIndicator'],
        'receiptDescription': !exists(json, 'receiptDescription') ? undefined : json['receiptDescription'],
        'recordStatus': !exists(json, 'recordStatus') ? undefined : json['recordStatus'],
        'rx': !exists(json, 'rx') ? undefined : json['rx'],
        'rxCode': !exists(json, 'rxCode') ? undefined : json['rxCode'],
        'site': !exists(json, 'site') ? undefined : json['site'],
        'taxableIndicator': !exists(json, 'taxableIndicator') ? undefined : json['taxableIndicator'],
        'siteShortName': !exists(json, 'siteShortName') ? undefined : json['siteShortName'],
        'siteId': !exists(json, 'siteId') ? undefined : json['siteId'],
        'medicationStatus': json['medicationStatus'],
    };
}

export function PrescriptionToJSON(value?: Prescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adjudicationDate': value.adjudicationDate,
        'bin': value.bin,
        'counselRequiredIndicator': value.counselRequiredIndicator,
        'offerCounsel': value.offerCounsel,
        'description': value.description,
        'dispensable': value.dispensable,
        'discontinuedIndicator': value.discontinuedIndicator,
        'fill': value.fill,
        'itemNumber': value.itemNumber,
        'noSafetyCapIndicator': value.noSafetyCapIndicator,
        'partial': value.partial,
        'partialFillIndicator': value.partialFillIndicator,
        'plans': ((value.plans as Array<any>).map(PlanToJSON)),
        'price': value.price,
        'proofIDIndicator': value.proofIDIndicator,
        'captureRelationshipIndicator': value.captureRelationshipIndicator,
        'receiptDescription': value.receiptDescription,
        'recordStatus': value.recordStatus,
        'rx': value.rx,
        'rxCode': value.rxCode,
        'site': value.site,
        'taxableIndicator': value.taxableIndicator,
        'siteShortName': value.siteShortName,
        'siteId': value.siteId,
        'medicationStatus': value.medicationStatus,
    };
}


