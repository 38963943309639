import {TransactionStatusEnum} from '@emporos/api-enterprise';
import {Table} from 'dexie';
import {TransactionConsolidate} from '../api';
import {HiloDb} from './dbcontext';
import {ITransactionDatabaseAccess} from './IDatabaseAccess';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';
import {reduceSignatures} from '../utils';

// instantiate with DIFactory.getTransactionDatabaseAccess
export class TransactionLocaldb implements ITransactionDatabaseAccess {
  db!: HiloDb;
  table!: Table<TransactionConsolidate>;
  private _sessionId!: string;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();

  initialize = (sessionId: string): void => {
    this._sessionId = sessionId;
    this.db = new HiloDb(this._sessionId);
    this.table = this.db.TransactionLocaldb;
  };

  async getAll(
    status?: TransactionStatusEnum,
  ): Promise<TransactionConsolidate[]> {
    this._consoleLogger.styledLog(
      'Getting All Transactions with Session ID:',
      ConsoleLoggerVariant.GREEN,
      this._sessionId,
    );

    if (status) {
      return this.table
        .where({sessionId: this._sessionId, status: status})
        .toArray();
    } else {
      return this.table.where({sessionId: this._sessionId}).toArray();
    }
  }

  preprocessTransaction = (
    transaction: TransactionConsolidate,
  ): TransactionConsolidate => {
    return {
      ...transaction,
      signatures: reduceSignatures(transaction.signatures),
    };
  };

  async get(transactionId: string): Promise<TransactionConsolidate> {
    this._consoleLogger.styledLog(
      'GettingTransactions with ID:',
      ConsoleLoggerVariant.GREEN,
      transactionId,
    );

    const transactionresult = await this.table.get(transactionId);

    if (transactionresult) {
      return transactionresult;
    } else {
      return {} as TransactionConsolidate;
    }
  }

  async add(
    transaction: TransactionConsolidate,
  ): Promise<TransactionConsolidate> {
    this._consoleLogger.styledLog(
      'Adding Transaction:',
      ConsoleLoggerVariant.GREEN,
      transaction,
    );

    const processedTransaction = this.preprocessTransaction(transaction);

    await this.table.add(processedTransaction);
    return processedTransaction;
  }

  async update(
    transaction: TransactionConsolidate,
  ): Promise<TransactionConsolidate> {
    this._consoleLogger.styledLog(
      'Updating Transaction:',
      ConsoleLoggerVariant.GREEN,
      transaction,
    );

    const processedTransaction = this.preprocessTransaction(transaction);

    await this.table.update(transaction.transactionId, processedTransaction);

    return processedTransaction;
  }

  async delete(transactionId: string): Promise<void> {
    this._consoleLogger.styledLog(
      'Deleting Transaction:',
      ConsoleLoggerVariant.GREEN,
      transactionId,
    );

    return this.table.delete(transactionId);
  }

  async deleteAll(): Promise<void> {
    this._consoleLogger.styledLog(
      'Deleting All Transactions',
      ConsoleLoggerVariant.GREEN,
    );

    return this.table.clear();
  }
}
