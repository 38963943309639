import {CashDrawer, Site, Station} from '@emporos/api-enterprise';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Row,
  ScrollContainer,
  Select,
  Stack,
  TextInputWithPlaceholder,
  TextSize as Size,
  toCurrency,
  Variant,
} from '@emporos/components';
import {memo, useEffect, useReducer, useState} from 'react';
import {useNetworkAvailable} from '../../../contexts';

type FormState = {
  site: Site;
  station: Station;
  paymentDeviceAddress: string;
  paymentDevicePort: string;
  till: CashDrawer;
  tillStartingAmount: number;
};

type FormStateWithSite = Pick<FormState, 'site'> & {
  [K in keyof Omit<FormState, 'site'>]: null;
};
type FormStateWithStation = Pick<FormState, 'site' | 'station'> & {
  [K in keyof Omit<FormState, 'site' | 'station'>]:
    | Omit<FormState, 'site' | 'station'>[K]
    | null;
};
export type SessionConfig =
  | {[K in keyof FormState]: null}
  | FormStateWithSite
  | FormStateWithStation;

export function isSessionConfigCompleted(
  config: SessionConfig,
): config is FormState {
  return (
    config.site !== null &&
    config.station !== null &&
    config.till !== null &&
    config.tillStartingAmount !== null
  );
}

const compareCollator = new Intl.Collator('en', {sensitivity: 'base'});

interface Props {
  sites: Site[];
  stations: Station[];
  tills: CashDrawer[];
  config: SessionConfig;
  configSession?: boolean;
  loading: boolean;
  onChange: (config: SessionConfig) => void;
  onLogout?: () => void;
  onConfirm?: () => void;
}
function getTitle({name}: Station): string {
  return name || '';
}

type FormOptionsState = {
  stations: Station[] | null;
  tills: CashDrawer[] | null;
};

type FormOptionsReducerAction = {
  type?: 'clear' | 'setStations' | 'setTills';
  value?: Station[] | CashDrawer[];
};

const formOptionsReducer = (
  state: FormOptionsState,
  {type, value}: FormOptionsReducerAction,
) => {
  switch (type) {
    case 'clear':
      return {...state, stations: null, tills: null};
    case 'setStations':
      return {...state, stations: value as Station[]};
    case 'setTills':
      return {...state, tills: value as CashDrawer[]};
    default:
      return {...state};
  }
};

function CreateSessionComponent({
  sites,
  stations,
  tills,
  config,
  configSession = false,
  onChange,
  onLogout,
  onConfirm,
  loading,
}: Props): JSX.Element {
  const {site, station, till, tillStartingAmount} = config;
  const [formOptionsState, dispatchFormOptionsState] = useReducer(
    formOptionsReducer,
    {stations: null, tills: null},
  );
  const confirmDisabled = !isSessionConfigCompleted(config);
  const disableField = configSession || !site;
  const disableFieldStation = !station;
  const online = useNetworkAvailable();
  const orderedSites = [...sites].sort((a, b) =>
    compareCollator.compare(a.shortName, b.shortName),
  );
  const [tillStartingAmountDisplay, setTillStartingAmountDisplay] =
    useState<string>(
      tillStartingAmount ? `$${toCurrency(tillStartingAmount)}` : '',
    );

  useEffect(() => {
    dispatchFormOptionsState({type: 'clear'});
    setTillStartingAmountDisplay(
      tillStartingAmount ? `$${toCurrency(tillStartingAmount)}` : '',
    );
  }, [site]);

  useEffect(() => {
    // update station options when the stations prop changes
    const orderedStation = stations.sort((a, b) =>
      compareCollator.compare(a.name ?? '', b.name ?? ''),
    );
    dispatchFormOptionsState({type: 'setStations', value: orderedStation});
  }, [stations]);

  useEffect(() => {
    const orderedTills = [...tills].sort((a, b) =>
      compareCollator.compare(a.cashDrawerName ?? '', b.cashDrawerName ?? ''),
    );
    // update till options when the tills prop changes
    dispatchFormOptionsState({type: 'setTills', value: orderedTills});
  }, [tills]);

  // common function to update the config
  const updateConfig = (newConfig: Partial<SessionConfig>) => {
    onChange({...config, ...(newConfig as SessionConfig)});
  };

  return (
    <Stack>
      {!configSession && <Header title="Create Session" />}
      <ScrollContainer>
        <Stack
          gutter={Gutter.L}
          style={{flex: 1, marginTop: 6, paddingTop: configSession ? 16 : 0}}
        >
          <Row>
            <Select
              disabled={!online || configSession}
              name="site"
              label="Site*"
              options={orderedSites.map(({id}) => id)}
              optionsText={orderedSites.map(s => s.shortName)}
              value={site?.id || null}
              onChangeValue={value => {
                const foundSite = sites.find(({id}) => id === value);
                if (foundSite) {
                  updateConfig({
                    site: foundSite,
                    station: null,
                    paymentDeviceAddress: null,
                    paymentDevicePort: null,
                    till: null,
                    tillStartingAmount: null,
                  });
                }
              }}
            />
            <Select
              disabled={
                !online || disableField || !formOptionsState.stations?.length
              }
              name="station"
              label="Station*"
              loading={Boolean(site && !formOptionsState.stations)}
              options={(formOptionsState.stations || []).map(({id}) => id)}
              optionsText={(formOptionsState.stations || []).map(getTitle)}
              value={station?.id || null}
              onChangeValue={value => {
                const foundStation = (formOptionsState.stations || []).find(
                  ({id}) => id === value,
                );
                if (foundStation) {
                  const foundPaymentDevice = {
                    address: (foundStation as Station)?.paymentDeviceAddress,
                    port: (foundStation as Station)?.paymentDevicePort,
                  };
                  updateConfig({
                    station: foundStation,
                    paymentDeviceAddress: foundPaymentDevice.address || '',
                    paymentDevicePort: foundPaymentDevice.port || '',
                  });
                }
              }}
            />
          </Row>
          <Row>
            <Select
              disabled={
                !online || disableField || !formOptionsState.tills?.length
              }
              name="till"
              label="Till*"
              loading={Boolean(site && !formOptionsState.tills)}
              options={(formOptionsState.tills || []).map(
                ({cashDrawerId}) => cashDrawerId,
              )}
              optionsText={(formOptionsState.tills || []).map(
                ({cashDrawerName}) => cashDrawerName || '',
              )}
              value={till?.cashDrawerId || null}
              onChangeValue={value => {
                const foundTill = (formOptionsState.tills || []).find(
                  ({cashDrawerId}) => cashDrawerId === value,
                );
                if (foundTill) {
                  updateConfig({
                    till: foundTill,
                  });
                }
              }}
            />
            <TextInputWithPlaceholder
              disabled={!online || disableField}
              name="tillStartingAmount"
              label="Till Starting Amount*"
              placeholder="$0.00"
              value={tillStartingAmountDisplay}
              onFocus={() => {
                if (!tillStartingAmount) {
                  updateConfig({tillStartingAmount: null});
                  setTillStartingAmountDisplay('$0.00');
                }
              }}
              onBlur={() => {
                if (tillStartingAmount === null) {
                  updateConfig({tillStartingAmount: null});
                  setTillStartingAmountDisplay('');
                }
              }}
              onChange={value => {
                const valueAsCurrency = toCurrency(value.target.value, '.');
                updateConfig({tillStartingAmount: Number(valueAsCurrency)});
                setTillStartingAmountDisplay(`$${valueAsCurrency}`);
              }}
              onClear={() => {
                updateConfig({tillStartingAmount: null});
                setTillStartingAmountDisplay('');
              }}
              style={{flex: '1'}}
              inputSize={Size.Large}
              inputMode="numeric"
            />
          </Row>
          <Row>
            <TextInputWithPlaceholder
              disabled={!online || disableFieldStation}
              name="paymentDeviceAddress"
              label="Payment Device Address"
              placeholder=""
              value={config.paymentDeviceAddress || ''}
              onChange={value => {
                updateConfig({
                  paymentDeviceAddress: value.target.value,
                  paymentDevicePort:
                    (config.station as Station)?.paymentDevicePort || '',
                });
              }}
              onClear={() => {
                updateConfig({
                  paymentDeviceAddress: '',
                });
              }}
              style={{flex: '0.5', marginRight: '15px'}}
              inputSize={Size.Large}
            />
          </Row>
        </Stack>
      </ScrollContainer>
      {!configSession && onLogout && onConfirm && (
        <FooterGroup>
          <Button
            variant={Variant.Danger}
            flex
            disabled={!online}
            onClick={onLogout}
          >
            Logout
          </Button>
          <Button
            flex
            disabled={!online || confirmDisabled}
            onClick={confirmDisabled ? undefined : onConfirm}
            loading={loading}
          >
            Confirm
          </Button>
        </FooterGroup>
      )}
    </Stack>
  );
}

export const CreateSession = memo(CreateSessionComponent);
