import {useState, useEffect} from 'react';
import {throttle} from 'lodash';

export function useWindowResize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', throttle(handleResize, 200));
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
}
