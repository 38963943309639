/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentDetailRequest
 */
export interface PaymentDetailRequest {
    /**
     * The site id
     * @type {number}
     * @memberof PaymentDetailRequest
     */
    siteId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetailRequest
     */
    transportKey: string;
}

export function PaymentDetailRequestFromJSON(json: any): PaymentDetailRequest {
    return PaymentDetailRequestFromJSONTyped(json, false);
}

export function PaymentDetailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDetailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteId': json['siteId'],
        'transportKey': json['transportKey'],
    };
}

export function PaymentDetailRequestToJSON(value?: PaymentDetailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteId': value.siteId,
        'transportKey': value.transportKey,
    };
}


