import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  AnalyticType,
  Transaction,
  OfflineCustomer,
  OfflineSynced,
  useAnalyticsProvider,
  useTransaction,
  withChildPage,
} from '../../../../../';
import {Receipts} from './';

export interface EmailForm {
  email: string;
}

function calculateFirstFillPercent(transaction: Transaction) {
  const {items, customer} = transaction;
  const totalFirstFillRx = (customer as OfflineCustomer)?.prescriptions?.filter(
    // Checking solely on fill will not work once we are fully integrated with Epic
    ({fill}) => fill === '00',
  );
  const firstFillPurchased = items.filter(({fill, rx}) => rx && fill === '00');
  if (totalFirstFillRx?.length) {
    return (firstFillPurchased.length / totalFirstFillRx.length) * 100;
  }
}

function ReceiptsIntegrationComponent({navigate}: RouteComponentProps) {
  const {track} = useAnalyticsProvider();
  const {transaction, updateTransaction} = useTransaction();
  const {NODE_ENV} = process.env;

  assert(
    navigate,
    '<ReceiptsIntegration /> must have a `navigate` prop.' +
      String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );
  assert(transaction, 'Missing transaction');

  const trackReceiptType = (receiptType: string) =>
    track(AnalyticType.ReceiptType, {receiptType});

  const onNoReceipt = () => {
    trackReceiptType('none');
    completeTransaction({
      isCompleted: true,
      isSynced: true,
    } as Partial<Transaction> & OfflineSynced);
  };

  const onEmailReceipt = (data: EmailForm) => {
    trackReceiptType('email');
    completeTransaction({
      emailReceipt: data.email,
      isCompleted: true,
      isSynced: true,
    } as Partial<Transaction> & OfflineSynced);
  };

  const completeTransaction = (
    transactionUpdates: Partial<Transaction> & OfflineSynced,
  ) => {
    updateTransaction(transactionUpdates, true);

    track(AnalyticType.OrderCompleted, {
      total: transaction.totalSale,
      firstFillPickupPercent: calculateFirstFillPercent(transaction),
      products: transaction.items
        .filter(({rx}) => !rx)
        .map(({description, itemNumber, price, quantity}) => {
          return {
            category: 'OTC',
            name: description || '',
            price,
            productId: itemNumber || '',
            quantity,
          };
        }),
      saleDuration: +new Date() - +transaction.saleDate,
    });

    setTimeout(() => navigate('/sales'), 400);
  };

  const onBack = () => {
    navigate('../customer-payment');
  };

  return (
    <Receipts
      transaction={transaction}
      onPrint={() => {
        trackReceiptType('printed');
        return navigate('/sales');
      }}
      onNoReceipt={onNoReceipt}
      onEmailReceipt={onEmailReceipt}
      onBack={onBack}
      navigate={navigate}
    />
  );
}

export const ReceiptsIntegration = memo(
  withChildPage(ReceiptsIntegrationComponent),
);
