/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface SignatureImage
 */
export interface SignatureImage {
    /**
     * 
     * @type {string}
     * @memberof SignatureImage
     */
    signatureImageId: string;
    /**
     * 
     * @type {number}
     * @memberof SignatureImage
     */
    serverSignatureId: number;
    /**
     * 
     * @type {string}
     * @memberof SignatureImage
     */
    signatureImageType: string;
    /**
     * 
     * @type {string}
     * @memberof SignatureImage
     */
    imageData: string;
    /**
     * 
     * @type {Array<Array<Point>>}
     * @memberof SignatureImage
     */
    pointData: Array<Array<Point>>;
    /**
     * 
     * @type {string}
     * @memberof SignatureImage
     */
    dataVersion?: string | null;
}

export function SignatureImageFromJSON(json: any): SignatureImage {
    return SignatureImageFromJSONTyped(json, false);
}

export function SignatureImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatureImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signatureImageId': json['signatureImageId'],
        'serverSignatureId': json['serverSignatureId'],
        'signatureImageType': json['signatureImageType'],
        'imageData': json['imageData'],
        'pointData': json['pointData'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
    };
}

export function SignatureImageToJSON(value?: SignatureImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signatureImageId': value.signatureImageId,
        'serverSignatureId': value.serverSignatureId,
        'signatureImageType': value.signatureImageType,
        'imageData': value.imageData,
        'pointData': value.pointData,
        'dataVersion': value.dataVersion,
    };
}


