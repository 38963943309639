import {Customer} from '@emporos/api-enterprise';
import {
  Button,
  Gutter,
  IndicatorCircle,
  RemovePadding,
  Row,
  RowSidebar,
  Stack,
  Size,
  Text,
  TextVariant as Variant,
  useMounted,
} from '@emporos/components';
import {RouteComponentProps, useLocation} from '@reach/router';
import assert from 'assert';
import {memo, useEffect, useMemo} from 'react';
import {animated, useTransition, UseTransitionProps} from 'react-spring';
import {
  behindTabBarStackingContext,
  fullHeightStack,
  Transaction,
  TransactionLogTypes,
  transactionSynced,
  mapTransaction,
  useLog,
  useNetworkAvailable,
  useSession,
  useTransactionsState,
} from '../../';
import {useConsoleLogger} from '../../contexts/ConsoleLoggingProvider';
import {ConsoleLoggerVariant} from '../../utils/console-logger';

function SalesSidebarComponent({
  navigate,
  requestedUrl,
  onConfirmNavigation,
}: RouteComponentProps & {
  requestedUrl: string | null;
  onConfirmNavigation: (url?: string) => void;
}) {
  const {NODE_ENV} = process.env;
  const online = useNetworkAvailable();
  assert(
    navigate,
    '<SalesSidebar /> must have a `navigate` prop.' + String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );

  const {pathname} = useLocation();
  const {logUserSelection} = useLog();
  const {session, currentTransactionId, setCurrentTransactionId} =
    useTransactionsState();
  const {
    pendingTransactions: transactions,
    updateSession,
    canCreateTransaction,
  } = useSession();
  const {styledLog} = useConsoleLogger();

  useEffect(() => {
    if (requestedUrl) {
      onConfirmNavigation();
    }
  }, [requestedUrl, onConfirmNavigation]);

  const onNew = () => {
    const transaction = mapTransaction(session.sessionId, session.siteId);
    setCurrentTransactionId(transaction.transactionId);
    updateSession(
      prevSession => ({
        transactions: [transaction, ...prevSession.transactions],
      }),
      transaction.transactionId,
    );

    styledLog(
      'SalesSidebar - New Transaction:',
      ConsoleLoggerVariant.PURPLE,
      transaction,
    );

    logUserSelection(TransactionLogTypes.NewTransaction);
    onConfirmNavigation('/sales/transactions');
  };

  const getSidebarTitle = (customer?: Customer): string =>
    customer ? `${customer.firstName} ${customer.lastName}` : 'General Sale';

  const mounted = useMounted();
  const transition = useMemo<UseTransitionProps<Transaction>>(
    () =>
      mounted
        ? {
            keys: (transaction: Transaction) => transaction.transactionId,
            from: {y: -68, opacity: 0},
            leave: (transaction, i) => ({
              y: i * 68 - 68,
              opacity: 0,
            }),
            enter: (transaction, i) => ({y: i * 68, opacity: 1}),
            update: (transaction, i) => ({y: i * 68}),
          }
        : {
            keys: (transaction: Transaction) => transaction.transactionId,
            from: (transaction, i) => ({y: i * 68, opacity: 1}),
            leave: (transaction, i) => ({y: i * 68, opacity: 1}),
            enter: (transaction, i) => ({y: i * 68, opacity: 1}),
          },
    [mounted],
  );
  const transactionRowTransitions = useTransition(
    transactions.concat([{transactionId: 'spacer'} as Transaction]),
    transition,
  );

  return (
    <>
      {transactions.length > 0 ? (
        <Stack gutter={Gutter.XL} style={fullHeightStack}>
          <Row align="center" justify="space-between">
            <Text variant={Variant.T1}>Sales</Text>
            {online && (
              <Button
                size={Size.Small}
                icon="PlusCircle"
                onClick={onNew}
                disabled={!canCreateTransaction}
              >
                New
              </Button>
            )}
            {!online && (
              <Button size={Size.Small} icon="NetworkNone" disabled>
                Offline
              </Button>
            )}
          </Row>

          <RemovePadding style={behindTabBarStackingContext}>
            {transactionRowTransitions(({y, opacity}, iv, state, index) =>
              iv.transactionId === 'spacer' ? (
                <animated.div
                  style={{
                    transform: y.to((n: number) => `translate3d(0,${n}px,0)`),
                    position: 'absolute',
                    height: '6em',
                    width: '100%',
                  }}
                />
              ) : (
                <animated.div
                  key={iv.transactionId}
                  data-testid="transactionItem"
                  style={{
                    zIndex: transactions.length - index,
                    transform: y.to((n: number) => `translate3d(0,${n}px,0)`),
                    position: 'absolute',
                    width: '100%',
                    opacity,
                  }}
                >
                  <RowSidebar
                    title={getSidebarTitle(iv.customer)}
                    // TODO: make this dynamic when we have different types of transactions
                    icon="Bed"
                    iconColor="indigo"
                    badgeText={iv.roomNumber || ''}
                    selected={
                      (iv.transactionId === currentTransactionId &&
                        !iv.items.length) ||
                      (pathname.indexOf('/transactions') >= 0 &&
                        iv.transactionId === currentTransactionId)
                    }
                    onClick={() => {
                      setCurrentTransactionId(iv.transactionId);
                      logUserSelection(TransactionLogTypes.Selection, {
                        item: iv.transactionId,
                      });
                      return navigate('/sales/transactions');
                    }}
                    synced={transactionSynced(iv)}
                  />
                </animated.div>
              ),
            )}
          </RemovePadding>
        </Stack>
      ) : (
        <>
          <Row align="center" justify="space-between">
            <Text variant={Variant.T1}>Sales</Text>
            {online && (
              <Button size={Size.Small} icon="PlusCircle" onClick={onNew}>
                New
              </Button>
            )}
            {!online && (
              <Button size={Size.Small} icon="NetworkNone" disabled>
                Offline
              </Button>
            )}
          </Row>
          <Stack
            align="center"
            gutter={Gutter.XL}
            data-testid="sales-sidebar-indicator"
          >
            {online && (
              <>
                <IndicatorCircle
                  icon="CashRegister"
                  variant="gray"
                  size="large"
                />
                <Text variant={Variant.Main} align="center">
                  Tap the new sale button
                </Text>
                <Text
                  variant={Variant.Main}
                  align="center"
                  style={{marginTop: 4}}
                >
                  above to get started
                </Text>
              </>
            )}
            {!online && (
              <>
                <IndicatorCircle
                  icon="CashRegister"
                  variant="gray"
                  size="large"
                />
                <Text variant={Variant.Main} align="center">
                  Go online to start
                </Text>
                <Text
                  variant={Variant.Main}
                  align="center"
                  style={{marginTop: 4}}
                >
                  a new transaction
                </Text>
              </>
            )}
          </Stack>
          <div style={{height: 70}}>
            {/* TODO: just here to fix the layout */}
          </div>
        </>
      )}
    </>
  );
}

export const SalesSidebar = memo(SalesSidebarComponent);
