import React, {memo} from 'react';
import styled from 'styled-components';
import {
  Gutter,
  Icons,
  IndicatorCircle,
  Row,
  Text,
  TextVariant as Variant,
} from './';

export interface AlertMessageProps {
  variant?: 'primary' | 'success' | 'warning' | 'error';
  icon: keyof typeof Icons;
  message?: string;
}

const Banner = styled(Row)<AlertMessageProps>`
  width: 100%;
  border-radius: 12px;
  border: 2px solid ${({theme}) => theme.colors.gray_200};
  background-color: ${({theme}) => theme.colors.gray_100};
  align-items: center;
  position: relative;
`;

const Bar = styled.div<{variant?: 'primary' | 'success' | 'warning' | 'error'}>`
  width: 10px;
  height: calc(100% + 4px);
  align-self: stretch;
  border-radius: 12px 0 0 12px;
  background-color: ${({variant, theme}) => {
    switch (variant) {
      case 'success':
        return theme.colors.success;
      case 'warning':
        return theme.colors.warning;
      case 'error':
        return theme.colors.error;
      case 'primary':
      default:
        return theme.colors.blue;
    }
  }};
  position: absolute;
  top: -2px;
  left: -2px;
`;

export const AlertMessage = memo(
  ({
    variant,
    icon,
    message,
    ...props
  }: AlertMessageProps &
    React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    return (
      <Banner gutter={Gutter.S} icon={icon} {...props}>
        <Bar variant={variant} />
        <IndicatorCircle
          icon={icon}
          size="small"
          variant={variant}
          style={{marginLeft: 20}}
        />
        <Text variant={Variant.Main} style={{padding: '16px 0'}}>
          {message}
        </Text>
      </Banner>
    );
  },
);
