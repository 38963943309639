/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SignatureType,
    SignatureTypeFromJSON,
    SignatureTypeToJSON,
} from '../models';

export interface ClientSignaturesTypesTypeIdGetRequest {
    typeId: number;
}

/**
 * 
 */
export class SignatureTypesApi extends runtime.BaseAPI {

    /**
     * Gets SignatureTypes.
     */
    async clientSignaturesTypesGetRaw(): Promise<runtime.ApiResponse<Array<SignatureType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/signatures/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignatureTypeFromJSON));
    }

    /**
     * Gets SignatureTypes.
     */
    async clientSignaturesTypesGet(): Promise<Array<SignatureType>> {
        const response = await this.clientSignaturesTypesGetRaw();
        return await response.value();
    }

    /**
     * Gets a signature type by id
     */
    async clientSignaturesTypesTypeIdGetRaw(requestParameters: ClientSignaturesTypesTypeIdGetRequest): Promise<runtime.ApiResponse<SignatureType>> {
        if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
            throw new runtime.RequiredError('typeId','Required parameter requestParameters.typeId was null or undefined when calling clientSignaturesTypesTypeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/signatures/types/{typeId}`.replace(`{${"typeId"}}`, encodeURIComponent(String(requestParameters.typeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureTypeFromJSON(jsonValue));
    }

    /**
     * Gets a signature type by id
     */
    async clientSignaturesTypesTypeIdGet(requestParameters: ClientSignaturesTypesTypeIdGetRequest): Promise<SignatureType> {
        const response = await this.clientSignaturesTypesTypeIdGetRaw(requestParameters);
        return await response.value();
    }

}
