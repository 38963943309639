/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiResponseMeta
 */
export interface ApiResponseMeta {
    /**
     * 
     * @type {Pagination}
     * @memberof ApiResponseMeta
     */
    pagination: Pagination;
}

export function ApiResponseMetaFromJSON(json: any): ApiResponseMeta {
    return ApiResponseMetaFromJSONTyped(json, false);
}

export function ApiResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResponseMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ApiResponseMetaToJSON(value?: ApiResponseMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
    };
}


