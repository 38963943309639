/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PhoneCapabilityFlags {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_4 = 4
}

export function PhoneCapabilityFlagsFromJSON(json: any): PhoneCapabilityFlags {
    return PhoneCapabilityFlagsFromJSONTyped(json, false);
}

export function PhoneCapabilityFlagsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneCapabilityFlags {
    return json as PhoneCapabilityFlags;
}

export function PhoneCapabilityFlagsToJSON(value?: PhoneCapabilityFlags | null): any {
    return value as any;
}

