import {Prescription} from '@emporos/api-enterprise';

/**
 * An enum that gives mores insight into the string representation of the medicationStatus values that come down as a number from BE
 */
export enum MedicationStatus {
  ReadyToDispense,
  ReadyToDeliver,
  PendingFill,
  Leave,
  Delivered,
  OutForDelivery,
  Discontinued,
  DiscontinuedButDispensable,
}

/**
 * Evaluates whether a prescription is ready to be added to a transaction and dispensed to a customer based on its medicationStatus property.
 * We are excluding Rx in any status other than ReadyToDispense, ReadyToDeliver, Leave, and DiscontinuedButDispensable from being added to a transaction,
 * but we could potentially open that up to other statuses in the future (10/13/23). We may need to answer questions like: if a Rx is in status
 * 'Delivered' we could say it is certainly 'ready', but should we add it to a transaction (when it likely already has been)?
 * @param prescription - a typed Prescription record that is associated to a customer
 * @returns a boolean evaluation of whether that prescription can be added to a transaction and dispensed to the customer
 */
export const IsPrescriptionReady = (prescription: Prescription): boolean => {
  return (
    prescription.medicationStatus === MedicationStatus.ReadyToDispense ||
    prescription.medicationStatus === MedicationStatus.ReadyToDeliver ||
    prescription.medicationStatus === MedicationStatus.Leave ||
    prescription.medicationStatus ===
      MedicationStatus.DiscontinuedButDispensable
  );
};
