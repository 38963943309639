import {NotificationAlert} from '@emporos/components';
import {endpointsConfig} from '../api/endpoints-config';

export interface endpointConfig {
  errorAlert?: Omit<NotificationAlert, 'id'> | undefined;
  offline?: boolean | undefined;
  preferOffline?: boolean | undefined;
}

export const endpointConfig = (
  url: string,
  method?: string,
): endpointConfig | undefined => {
  const methodMappings = endpointsConfig[method || 'GET'];
  const match = Object.keys(methodMappings).find(exp => url.match(exp));
  if (match) {
    return methodMappings[match];
  }
};

export const formatDateForUrl = (compareDate: Date): string => {
  const stringified = compareDate.toISOString();
  const formatted = stringified.replace(new RegExp(':', 'g'), '%3A');
  return formatted;
};
