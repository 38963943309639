import {MutableRefObject, useEffect} from 'react';

// Click outside hook added here only because the navbar is imported in more than one location
// Used solution by Gabe Ragland https://usehooks.com/useOnClickOutside/
export const useClickOutside = (
  ref: MutableRefObject<HTMLDivElement>,
  handler: (event: Event) => void,
): void => {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref element or descendent elements
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
