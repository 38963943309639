import {Transaction, TransactionStatusEnum} from '@emporos/api-enterprise';
import {orderBy} from 'lodash';
import {
  TransactionConsolidate,
  OfflineTransaction,
  OfflineSynced,
  SessionUpdates,
  useTransactionsState,
} from '../';

import {filterDeletedTransactionChildren} from './';
import {useConsoleLogger} from '../contexts/ConsoleLoggingProvider';

export const useSession = (): {
  siteId: number;
  pendingTransactions: Array<Transaction>;
  completedTransactions: Array<TransactionConsolidate>;
  updateSession: (
    updates: SessionUpdates,
    currentTransactionId?: string,
  ) => void;
  canClose: boolean;
  canCreateTransaction: boolean;
} => {
  const {session, setSession} = useTransactionsState();

  const {logError} = useConsoleLogger();

  const transactions = session.transactions.filter(
    transaction => !(transaction as OfflineSynced).isDeleted,
  );
  const siteId = session.siteId;

  const pendingTransactions = orderBy(
    transactions
      .filter(
        transaction =>
          !(transaction as OfflineTransaction).isCompleted &&
          transaction.status !== TransactionStatusEnum.Complete &&
          // Filter out deleted status' temporarily for partial nplex implementation - Product Team
          transaction.status !== TransactionStatusEnum.Deleted &&
          transaction.status !== TransactionStatusEnum.Accepted &&
          transaction.status !== TransactionStatusEnum.Error,
      )
      .map(filterDeletedTransactionChildren),
    ['saleDate'],
    ['desc'],
  );

  const completedTransactions = orderBy(
    transactions
      .filter(
        transaction =>
          (transaction as OfflineTransaction).isCompleted ||
          transaction.status === TransactionStatusEnum.Complete ||
          transaction.status === TransactionStatusEnum.Error ||
          transaction.status === TransactionStatusEnum.Accepted,
      )
      .map(filterDeletedTransactionChildren),
    ['saleDate'],
    ['desc'],
  );
  const canClose = !transactions.some(
    transaction =>
      transaction.status !== TransactionStatusEnum.Complete &&
      transaction.status !== TransactionStatusEnum.Deleted &&
      transaction.status !== TransactionStatusEnum.Accepted &&
      transaction.status !== TransactionStatusEnum.Error,
  );
  const canCreateTransaction = !pendingTransactions.some(
    transaction => !transaction.customer && !transaction.items.length,
  );

  const updateSession = async (updates: SessionUpdates) => {
    try {
      if (typeof updates === 'function') {
        setSession(prevSession => ({
          ...prevSession,
          ...updates(session),
        }));
      } else {
        setSession(prevSession => ({
          ...prevSession,
          triggerSync: true,
          ...updates,
        }));
      }
    } catch (e) {
      logError('Error occurred during React session update:', e);
    }
  };

  return {
    siteId,
    pendingTransactions,
    completedTransactions,
    updateSession,
    canClose,
    canCreateTransaction,
  };
};
