/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    CustomerAccountResponse,
    CustomerAccountResponseFromJSON,
    CustomerAccountResponseFromJSONTyped,
    CustomerAccountResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerAccountResponseApiResponse
 */
export interface CustomerAccountResponseApiResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerAccountResponseApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof CustomerAccountResponseApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {CustomerAccountResponse}
     * @memberof CustomerAccountResponseApiResponse
     */
    data?: CustomerAccountResponse;
}

export function CustomerAccountResponseApiResponseFromJSON(json: any): CustomerAccountResponseApiResponse {
    return CustomerAccountResponseApiResponseFromJSONTyped(json, false);
}

export function CustomerAccountResponseApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAccountResponseApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : CustomerAccountResponseFromJSON(json['data']),
    };
}

export function CustomerAccountResponseApiResponseToJSON(value?: CustomerAccountResponseApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': CustomerAccountResponseToJSON(value.data),
    };
}


