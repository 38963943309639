import {TransactionItem, TransactionItemRequest} from '@emporos/api-enterprise';

export function ConvertTransactionItemsToRequests(
  items: TransactionItem[],
): TransactionItemRequest[] {
  const requests: TransactionItemRequest[] = [];
  items.forEach(item => {
    requests.push(ConvertTransactionItemToRequest(item));
  });
  return requests;
}

export function ConvertTransactionItemToRequest(
  item: TransactionItem,
): TransactionItemRequest {
  return {
    transactionItemId: item.transactionItemId,
    serverDetailID: item.serverDetailID,
    serverTransactionID: item.serverTransactionID,
    itemID: item.itemID,
    itemNumber: item.itemNumber,
    rx: item.rx,
    fill: item.fill,
    partial: item.partial,
    site: item.site,
    rxCode: item.rxCode,
    cost: item.cost,
    description: item.description,
    quantity: item.quantity,
    listPrice: item.listPrice,
    price: item.price,
    itemListPrice: item.itemListPrice,
    saleType: item.saleType,
    itemTypeID: item.itemTypeID,
    discountPercent: item.discountPercent,
    discount: item.discount,
    extension: item.extension,
    quantityReturned: item.quantityReturned,
    qhpIndicator: item.qhpIndicator,
    qhpPrivateIndicator: item.qhpPrivateIndicator,
    taxableIndicator: item.taxableIndicator,
    taxGroupID: item.taxGroupID,
    priceChangeIndicator: item.priceChangeIndicator,
    discountableIndicator: item.discountableIndicator,
    noSafetyCapIndicator: item.noSafetyCapIndicator,
    proofIDIndicator: item.proofIDIndicator,
    unitOfMeasure: item.unitOfMeasure,
    saleUnitOfMeasure: item.saleUnitOfMeasure,
    counselRequiredIndicator: item.counselRequiredIndicator,
    counselPromptIndicator: item.counselPromptIndicator,
    captureRelationshipIndicator: item.captureRelationshipIndicator,
    hipaaCompliant: item.hipaaCompliant,
    hipaaRelationship: item.hipaaRelationship,
    departmentID: item.departmentID,
    controlGrams: item.controlGrams,
    adjudicationDate: item.adjudicationDate,
    inventoryGroupID: item.inventoryGroupID,
    updateInventoryIndicator: item.updateInventoryIndicator,
    relatedDetailID: item.relatedDetailID,
    familyDetailID: item.familyDetailID,
    parentDetailID: item.parentDetailID,
    kitIndicator: item.kitIndicator,
    saleUnitOfMeasureID: item.saleUnitOfMeasureID,
    unitOfMeasureRatio: item.unitOfMeasureRatio,
    serialNumberIndicator: item.serialNumberIndicator,
    rentalIndicator: item.rentalIndicator,
    rentalPrice: item.rentalPrice,
    rentalQuantity: item.rentalQuantity,
    timeUnitOfMeasureID: item.timeUnitOfMeasureID,
    employeeID: item.employeeID,
    pricingMethod: item.pricingMethod,
    extensionBeforeDiscount: item.extensionBeforeDiscount,
    relatedDetailID2: item.relatedDetailID2,
    saleIndicator: item.saleIndicator,
    cashDisbursementReasonID: item.cashDisbursementReasonID,
    discountReasonID: item.discountReasonID,
    returnReasonID: item.returnReasonID,
    vendor: item.vendor,
    priceChangeReasonID: item.priceChangeReasonID,
    originalPrice: item.originalPrice,
    type2BarcodeIndicator: item.type2BarcodeIndicator,
    weighedItemIndicator: item.weighedItemIndicator,
    departmentKeyIndicator: item.departmentKeyIndicator,
    attachmentIndicator: item.attachmentIndicator,
    priceQuantity: item.priceQuantity,
    manualWeighedIndicator: item.manualWeighedIndicator,
    commitStockIndicator: item.commitStockIndicator,
    priceLevelID: item.priceLevelID,
    eventCodeID: item.eventCodeID,
    priceGroupID: item.priceGroupID,
    displayPrice: item.displayPrice,
    vendorID: item.vendorID,
    detailReference: item.detailReference,
    negativeInventoryIndicator: item.negativeInventoryIndicator,
    createItemNumber: item.createItemNumber,
    directShipmentIndicator: item.directShipmentIndicator,
    description2: item.description2,
    discountType: item.discountType,
    itemCrossReference: item.itemCrossReference,
    signatureIndicator: item.signatureIndicator,
    rxCustomerID: item.rxCustomerID,
    categoryID: item.categoryID,
    subCategoryID: item.subCategoryID,
    deaClass: item.deaClass,
    adjInsDate: item.adjInsDate,
    plan1: item.plan1,
    planAmount1: item.planAmount1,
    plan2: item.plan2,
    planAmount2: item.planAmount2,
    plan3: item.plan3,
    planAmount3: item.planAmount3,
    plan4: item.plan4,
    planAmount4: item.planAmount4,
    plan5: item.plan5,
    planAmount5: item.planAmount5,
    prescriptionID: item.prescriptionID,
    ebtIndicator: item.ebtIndicator,
    wicIndicator: item.wicIndicator,
    rxRelationship: item.rxRelationship,
    pickupAllEmployeeID: item.pickupAllEmployeeID,
    customerMRN: item.customerMRN,
    rxBarcodeScanData: item.rxBarcodeScanData,
    controlledSubstanceIndicator: item.controlledSubstanceIndicator,
    counselLater: item.counselLater,
    counselorID: item.counselorID,
    counselIndicator: item.counselIndicator,
    offerCounsel: item.offerCounsel,
    recordStatus: item.recordStatus,
    dispensable: item.dispensable,
    receiptDescription: item.receiptDescription,
    medicationStatus: item.medicationStatus,
    requiresHIPAACompliance: item.requiresHIPAACompliance,
    requiredAge: item.requiredAge,
    discontinuedIndicator: item.discontinuedIndicator,
  };
}
