import React, {
  ReactNode,
  createContext,
  useMemo,
  useRef,
  useEffect,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import {IPMSStrategy} from '../BarcodeScannerService/IPMSStrategy';
import {
  EpicStrategy,
  McKessonStrategy,
} from '../BarcodeScannerService/Strategies';
interface PMSStrategyContext {
  strategy: IPMSStrategy;
  setPms: Dispatch<SetStateAction<'mckesson' | 'epic'>>;
}

const PMSStrategyContext = createContext<PMSStrategyContext>({
  strategy: new McKessonStrategy(),
  setPms: () => undefined,
});

export const PMSStrategyProvider = ({children}: {children: ReactNode}) => {
  const mcKessonStrategy = useRef<IPMSStrategy>(new McKessonStrategy());
  const epicStrategy = useRef<IPMSStrategy>(new EpicStrategy());
  const [strategy, setStrategy] = useState<IPMSStrategy>(
    mcKessonStrategy.current,
  );
  const [pms, setPms] = useState<'mckesson' | 'epic'>('mckesson');
  const value = useMemo(() => ({strategy: strategy, setPms}), [strategy]);

  useEffect(() => {
    switch (pms) {
      case 'epic':
        setStrategy(epicStrategy.current);
        break;
      case 'mckesson':
      default:
        setStrategy(mcKessonStrategy.current);
        break;
    }
  }, [pms]);

  return (
    <PMSStrategyContext.Provider value={value}>
      {children}
    </PMSStrategyContext.Provider>
  );
};

export const usePMSStrategy = (): PMSStrategyContext =>
  useContext(PMSStrategyContext);
