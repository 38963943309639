import {Transaction} from '@emporos/api-enterprise';
import {IPaymentService} from './IPaymentService';

interface ApiResponse {
  transactionId: string;
  urlCode: string;
  errorMessages: string[];
}

export class PaymentService implements IPaymentService {
  private PAYMENT_API_URL: string | undefined;
  private PAYMENT_API_UI: string | undefined;

  constructor() {
    this.PAYMENT_API_URL = process.env.PAYMENT_API_URL;
    this.PAYMENT_API_UI = process.env.PAYMENT_API_UI;
  }

  private navigateToUrl(url: string): void {
    window.location.href = url;
  }

  async RedirectPayment(transaction: Transaction): Promise<void> {
    const getCode = this.PAYMENT_API_URL + 'integration/init';

    try {
      const response = await fetch(getCode, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transaction),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data: ApiResponse = await response.json();

      this.navigateToUrl(this.PAYMENT_API_UI + data.urlCode);
    } catch (error) {
      console.log('Error while making POST request:', error);
      throw error;
    }
  }
}
