import {
  Gutter,
  Header,
  TextInput as Input,
  RadioButtons,
  ScrollContainer,
  Stack,
  RadioOptions,
  DisabledRadioButton,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import {noop} from 'lodash';
import {memo} from 'react';
import {
  BetaFeatures,
  useBetaFeatures,
} from '../../../../contexts/BetaFeatureProvider';
import {useTransactionsState} from '../../../../contexts/TransactionsStateProvider';
import {useSettings} from '../../../../contexts/SettingsProvider';

function SettingsDeveloperComponent(_: RouteComponentProps): JSX.Element {
  const {toggleFlag, ...flags} = useBetaFeatures();
  const {session} = useTransactionsState();
  const {NODE_ENV, ENABLE_BETA_FEATURES} = process.env;
  const {
    creditCardDeviceRequestTimeoutSeconds,
    setCreditCardDeviceRequestTimeoutSeconds,
    creditCardPendingPaymentExpirationMinutes,
    setCreditCardPendingPaymentExpirationMinutes,
  } = useSettings();
  const hostedPaymentOptions: RadioOptions = {
    label: 'Hosted Payment',
    value: 'hostedPayment',
    checked: session.hostedPaymentsEnabled,
    disabled: true,
  };

  return (
    <Stack gutter={Gutter.None} style={{height: '100%'}} as="form">
      <Header title="Developer" />
      <ScrollContainer>
        {(ENABLE_BETA_FEATURES === 'enabled' ||
          String(NODE_ENV) !== 'production') && (
          <>
            <Stack style={{flex: 1, paddingBottom: 16}} />
            <Stack style={{marginBottom: 16}}>
              <RadioButtons
                name="Beta Features"
                type="checkbox"
                onChange={event => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const value = event.currentTarget.value as keyof BetaFeatures;
                  toggleFlag(value);
                }}
                options={Object.keys(flags).map(flag => ({
                  label: flag,
                  value: flag,
                  checked: !!flags[flag as keyof typeof flags],
                }))}
              />
              <DisabledRadioButton
                name="HostedPayment"
                type="checkbox"
                onChange={noop}
                options={hostedPaymentOptions}
              />
            </Stack>
          </>
        )}
        <Stack>
          <Input
            type="number"
            label="Credit Card Device Request Timeout (Seconds)"
            value={creditCardDeviceRequestTimeoutSeconds}
            onChange={event =>
              setCreditCardDeviceRequestTimeoutSeconds(
                Number(event.target.value || 0),
              )
            }
          />
          <Input
            type="number"
            label="Credit Card Pending Payment Expiration (Minutes)"
            value={creditCardPendingPaymentExpirationMinutes}
            onChange={event =>
              setCreditCardPendingPaymentExpirationMinutes(
                Number(event.target.value || 0),
              )
            }
          />
        </Stack>
      </ScrollContainer>
    </Stack>
  );
}

export const SettingsDeveloper = memo(SettingsDeveloperComponent);
