import React, {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
import {Icon, IconSize} from './';

export interface NumberInputProps {
  disabled?: boolean;
  onClear?: () => void;
  defaultValue?: string | number;
}

const InputContainer = styled.div`
  position: relative;
`;

const StyledInput = styled.input<NumberInputProps>`
  -webkit-appearance: none;
  border: none;
  height: 80px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.steel};
  border-radius: 8px;
  text-align: center;
  font-family: ${({theme}) => theme.typography.Title1.fontFamily};
  font-weight: ${({theme}) => theme.typography.Title1.fontWeight};
  font-size: ${({theme}) => theme.typography.Title1.fontSize};
  line-height: ${({theme}) => theme.typography.Title1.lineHeight};
  color: ${({theme}) => theme.colors.white};
  box-shadow: none;

  opacity: ${({disabled}) => (disabled ? '0.5' : '1')};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'initial')};

  &:focus {
    outline: none;
    background-color: ${({theme}) => theme.colors.blue};
  }

  ::placeholder {
    color: ${({theme}) => theme.colors.white_50};
  }
`;

const ClearButton = styled.button<NumberInputProps>`
  background: none;
  border: none;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 0;
  position: absolute;
  top: 0;
  right: 22px;
  height: 80px;
  display: flex;
  align-items: center;
`;

export const NumberInput = memo(
  ({
    onClear,
    defaultValue = '',
    ...props
  }: NumberInputProps & PropsWithoutRef<JSX.IntrinsicElements['input']>) => {
    return (
      <InputContainer>
        <StyledInput
          {...props}
          pattern="\d*"
          data-testid="number-input"
          placeholder="- -"
        />
        {props.value &&
          defaultValue !== props.value &&
          !props.disabled &&
          props.value !== '$0.00' &&
          onClear && (
            <ClearButton
              type="button"
              onClick={onClear}
              data-testid="ClearButton"
            >
              <Icon icon="XCircle" size={IconSize.XL} variant="inverted" />
            </ClearButton>
          )}
      </InputContainer>
    );
  },
);
