import {TransactionItem} from '@emporos/api-enterprise';
import assert from 'assert';
import {OfflineSynced} from '../';
import {round} from './';

type OfflineSyncedTransactionItem = TransactionItem & OfflineSynced;

export const updateDiscount = (
  transactionItem: TransactionItem,
  percent: number,
): OfflineSyncedTransactionItem => {
  assert(
    percent >= 0 && percent <= 1,
    'Percentage should: 0 <= percentage <= 1',
  );
  const _discount = discount(
    percent,
    transactionItem.listPrice,
    transactionItem.quantity,
  );

  return {
    ...transactionItem,
    isSynced: false,
    price: transactionItem.listPrice,
    discountPercent: percent,
    discount: _discount,
    extension: extension(
      transactionItem.listPrice,
      transactionItem.quantity,
      _discount,
    ),
    extensionBeforeDiscount: extensionBeforeDiscount(
      transactionItem.listPrice,
      transactionItem.quantity,
    ),
  };
};

export const updatePrice = (
  transactionItem: TransactionItem,
  price: number,
): OfflineSyncedTransactionItem => ({
  ...transactionItem,
  isSynced: false,
  price,
  discountPercent: 0,
  discount: 0,
  extension: extension(price, transactionItem.quantity, 0),
  extensionBeforeDiscount: extensionBeforeDiscount(
    price,
    transactionItem.quantity,
  ),
});

export const updateQuantity = (
  transactionItem: TransactionItem,
  quantity: number,
): OfflineSyncedTransactionItem => {
  const _discount = discount(
    transactionItem.discountPercent,
    transactionItem.price,
    quantity,
  );

  return {
    ...transactionItem,
    isSynced: false,
    quantity,
    discount: _discount,
    extension: extension(transactionItem.price, quantity, _discount),
    extensionBeforeDiscount: extensionBeforeDiscount(
      transactionItem.price,
      quantity,
    ),
  };
};

const discount = (percent: number, price: number, quantity: number) =>
  round(percent * price * quantity);

const extension = (
  price: number,
  quantity: number,
  itemDiscount: number,
): number => price * quantity - itemDiscount;

const extensionBeforeDiscount = (price: number, quantity: number): number =>
  price * quantity;
