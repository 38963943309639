import {TransactionItem, OtcItem, Prescription} from '@emporos/api-enterprise';
import {Icons} from '@emporos/components';
import {ITEM_TYPE_IDS} from '../constants';

type ArrayOfIcons = Array<keyof typeof Icons>;

export function otcIcons(otcItem: OtcItem): ArrayOfIcons {
  const icons: ArrayOfIcons = [];

  (otcItem.proofOfIdRequired ||
    otcItem.itemType.id === ITEM_TYPE_IDS.METH_FREE) &&
    icons.push('IdCard');
  otcItem.counselPromptIndicator && icons.push('MessageDouble');
  (otcItem.qhpIndicator || otcItem.qhpPrivateIndicator) &&
    icons.push('BadgeDollar');
  otcItem.taxableIndicator && icons.push('BadgePercent');

  return icons;
}

export function prescriptionIcons(prescription: Prescription): ArrayOfIcons {
  const icons: ArrayOfIcons = [];

  prescription.proofIDIndicator && icons.push('IdCard');
  prescription.counselRequiredIndicator && icons.push('MessageDouble');
  prescription.taxableIndicator && icons.push('BadgePercent');

  return icons;
}

export function transactionItemIcons(
  transactionItem: TransactionItem,
): ArrayOfIcons {
  const icons: ArrayOfIcons = [];

  (transactionItem.proofIDIndicator ||
    transactionItem.itemTypeID === ITEM_TYPE_IDS.METH_FREE) &&
    icons.push('IdCard');
  (transactionItem.counselPromptIndicator ||
    transactionItem.counselRequiredIndicator) &&
    icons.push('MessageDouble');
  (transactionItem.qhpIndicator || transactionItem.qhpPrivateIndicator) &&
    icons.push('BadgeDollar');
  transactionItem.taxableIndicator && icons.push('BadgePercent');

  return icons;
}
