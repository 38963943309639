import {Transaction} from '@emporos/api-enterprise';
import {OfflineTransaction, OfflineSynced} from '../';

export const filterDeletedTransactionChildren = (
  transaction: OfflineTransaction,
): Transaction =>
  ({
    ...transaction,
    items: transaction.items.filter(item => !(item as OfflineSynced).isDeleted),
    payments: transaction.payments.filter(
      payment => !(payment as OfflineSynced).isDeleted,
    ),
    taxes: transaction.taxes.filter(tax => !(tax as OfflineSynced).isDeleted),
    signatures: transaction.signatures.filter(
      signature => !(signature as OfflineSynced).isDeleted,
    ),
    extras: transaction.extras.filter(tax => !(tax as OfflineSynced).isDeleted),
    identification:
      transaction.identification &&
      !(transaction.identification as OfflineSynced).isDeleted
        ? transaction.identification
        : undefined,
    signatureImage:
      transaction.signatureImage &&
      !(transaction.signatureImage as OfflineSynced).isDeleted
        ? transaction.signatureImage
        : undefined,
  } as OfflineTransaction);
