import {
  AlertBadge,
  DividerLine,
  Gutter,
  Icon,
  IconSize,
  LogosHilo,
  ProgressBar,
  ProgressVariant,
  Row,
  Stack,
  Text,
  TextVariant as Variant,
  useClickOutside,
} from '@emporos/components';
import {SessionStatus} from '@emporos/pos/src';
import React, {
  memo,
  PropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, {DefaultTheme} from 'styled-components';

export interface ShowProgressProps {
  isLoadingOtc: boolean;
  percentageOtcLoaded: number;
  hasOtcLoadingError: boolean;
}

export interface NavbarProps {
  username: string;
  onSessionStatus: () => void | Promise<void>;
  sessionStatus: SessionStatus;
  onLogout: () => void | Promise<void>;
  isLoadingData: boolean;
  hideSessionStatus?: boolean;
  onLoadingOtcError?: () => void;
  isLoadingOtc?: boolean;
  percentageOtcLoaded?: number;
  hasFatalOtcLoadingError?: boolean;
}

const StyledRow = styled(Row)`
  background: ${({theme}) => theme.colors.celestial};
  padding: 0 36px;
  height: 68px;
`;

const Dropdown = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 200px;
  box-shadow: ${({theme}) => theme.shadows.shadow_4};
  border-radius: 0 0 8px 8px;
  position: absolute;
  right: 16px;
  z-index: 2;
`;

const DropdownRow = styled.li`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({theme}) => theme.colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.colors.gray_100};
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

const Status = styled.div<{$sessionStatus?: SessionStatus}>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${({theme, $sessionStatus}) =>
    statusColor(theme, $sessionStatus)};
  border: 2px solid ${({theme}) => theme.colors.white_50};
`;

const statusColor = (theme: DefaultTheme, sessionStatus?: SessionStatus) =>
  sessionStatus === 'success' ? theme.colors.success : theme.colors.warning;

export const Navbar = memo(
  ({
    username,
    onSessionStatus,
    sessionStatus,
    onLogout,
    isLoadingData,
    hideSessionStatus,
    isLoadingOtc,
    percentageOtcLoaded,
    hasFatalOtcLoadingError,
    onLoadingOtcError,
  }: NavbarProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const [offline, setOffline] = useState(!navigator.onLine);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    // useCallback to cache setDropdownOpen state action so we can use it as a handler in the useClickOutside hook
    const closeDropdown = useCallback(() => setDropdownOpen(false), []);
    useClickOutside(ref, closeDropdown);

    useEffect(() => {
      const _setOnline = () => setOffline(false);
      const _setOffline = () => setOffline(true);

      window.addEventListener('online', _setOnline);
      window.addEventListener('offline', _setOffline);

      return () => {
        window.removeEventListener('online', _setOnline);
        window.removeEventListener('offline', _setOffline);
      };
    }, []);

    return (
      <div ref={ref} style={{position: 'relative'}} data-testid="navbar">
        <StyledRow justify="space-between" align="center" noWrap>
          <Stack>
            <LogosHilo.HiloHorizontalWhite width={108} />
          </Stack>
          <Row
            justify="end"
            gutter={Gutter.XXL}
            style={{flex: '1 0 auto'}}
            noWrap
          >
            {offline && (
              <AlertBadge
                variant="warning"
                text="No Connection"
                icon="NetworkNone"
              />
            )}
            {!!isLoadingData && (
              <AlertBadge
                variant="general"
                icon="Spinner"
                text="Syncing Data"
                animated={!!isLoadingData}
              />
            )}
            {(isLoadingOtc || hasFatalOtcLoadingError) && (
              <div>
                <ProgressBar
                  barWidth={75}
                  percentComplete={
                    percentageOtcLoaded ? percentageOtcLoaded : 0
                  }
                  isError={
                    hasFatalOtcLoadingError ? hasFatalOtcLoadingError : false
                  }
                  onErrorClick={
                    onLoadingOtcError ? onLoadingOtcError : undefined
                  }
                  containerWidth={'150px'}
                  itemType={'OTC'}
                  variant={ProgressVariant.Invert}
                />
              </div>
            )}

            <Row
              align="center"
              gutter={Gutter.XXL}
              style={{cursor: 'pointer'}}
              onClick={() => setDropdownOpen(!dropdownOpen)}
              data-testid="navbar-user"
            >
              <Row align="center" gutter={Gutter.S}>
                <Status
                  $sessionStatus={sessionStatus}
                  data-testid="header-status"
                />
                <Text variant={Variant.Main} style={{color: '#fff'}}>
                  {username}
                </Text>
              </Row>
              <Row gutter={Gutter.XS}>
                <Icon
                  icon="ChevronDown"
                  variant="inverted"
                  size={IconSize.XS}
                />
              </Row>
            </Row>
          </Row>
        </StyledRow>
        <Dropdown style={dropdownOpen ? {display: 'block'} : {display: 'none'}}>
          {!hideSessionStatus && navigator.onLine && (
            <DropdownRow
              onClick={() => {
                onSessionStatus();
                setDropdownOpen(false);
              }}
              data-testid="session-status"
            >
              <Text variant={Variant.Main}>Session Status</Text>
              <Icon icon="Upload" variant="black" />
            </DropdownRow>
          )}
          <DividerLine />
          <DropdownRow
            onClick={() => {
              onLogout();
              setDropdownOpen(false);
            }}
            data-testid="logout"
          >
            <Text variant={Variant.Main}>Logout</Text>
            <Icon icon="LogOut" variant="black" />
          </DropdownRow>
        </Dropdown>
      </div>
    );
  },
);

export function EmptyNavbar() {
  return (
    <div style={{position: 'relative'}} data-testid="navbar">
      <StyledRow justify="space-between" align="center">
        <Stack
          style={{
            cursor: 'pointer',
          }}
        >
          <LogosHilo.HiloHorizontalWhite width={108} height={30} />
        </Stack>
      </StyledRow>
    </div>
  );
}
