import {Intermission, IntermissionProps, theme} from '@emporos/components';
import {ThemeProvider} from 'styled-components';
import {GlobalStyles} from './';

export function IntermissionThemed(props: IntermissionProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Intermission {...props} />
    </ThemeProvider>
  );
}
