import {
  ClientCustomersCustomerIdGetRequest,
  ClientCustomersEmployeeGetRequest,
  ClientCustomersSearchPostRequest,
  ClientCustomersCustomerCodePatchRequest,
  CustomersApi,
} from '@emporos/api-enterprise/src/gen/apis/CustomersApi';
import {ApiVersion, useOpenApi, UseOpenApiHook} from '../api/useOpenApi';
import {createContext, useContext} from 'react';
import {
  BarcodesApi,
  ClientBarcodesGetRequest,
  ClientCacheSessionsMyGetRequest,
  ClientCacheSessionsPostRequest,
  ClientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRequest,
  ClientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRequest,
  ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRequest,
  ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRequest,
  ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRequest,
  ClientCacheSessionsSessionIdTransactionsPostRequest,
  ClientCacheSessionsSessionIdTransactionsPutRequest,
  ClientCacheSessionsSessionIdPaymentDeviceAddressPutRequest,
  ClientCacheSignaturesImagesPutRequest,
  ClientEligibilityGetRequest,
  ClientPaymentDetailSiteIdPostRequest,
  ClientPaymentOptionsGetRequest,
  ClientPaymentTendersGetRequest,
  ClientPaymentVoidSiteIdPostRequest,
  ClientPseCheckPutRequest,
  ClientRelationshipsGetRequest,
  ClientSettingsGetRequest,
  ClientSitesGetRequest,
  ClientStationsSiteIdGetRequest,
  ClientTaxGroupsGetRequest,
  ClientTillsGetRequest,
  ClientWillCallPutRequest,
  CustomerAccountsApi,
  CustomersCustomerIdAccountsArPostRequest,
  EligibilityApi,
  TransactionPaymentsApi,
  TransactionsApi,
  PaymentOptionsApi,
  PaymentsApi,
  PaymentTypesApi,
  PseCheckApi,
  RelationshipsApi,
  SessionsApi,
  SettingsApi,
  SignatureImagesApi,
  SignatureTypesApi,
  SitesApi,
  StationsApi,
  TaxGroupsApi,
  TillsApi,
  WillCallApi,
  CustomersCustomerIdAccountsPdPostRequest,
} from '@emporos/api-enterprise';

const API_VERSION: ApiVersion = process.env.REACT_APP_API_VERSION as ApiVersion;

// Context Definition
interface Context {
  CreateSession: (
    requestParameters?: [ClientCacheSessionsPostRequest] | undefined,
  ) => UseOpenApiHook<SessionsApi, 'clientCacheSessionsPost'>;
  CreateTransaction: (
    requestParameters?:
      | [ClientCacheSessionsSessionIdTransactionsPostRequest]
      | undefined,
  ) => UseOpenApiHook<
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsPost'
  >;
  GetBarcodes: (
    requestParameters?: [ClientBarcodesGetRequest] | undefined,
  ) => UseOpenApiHook<BarcodesApi, 'clientBarcodesGet'>;
  GetTaxGroups: (
    requestParameters?: [ClientTaxGroupsGetRequest] | undefined,
  ) => UseOpenApiHook<TaxGroupsApi, 'clientTaxGroupsGet'>;
  GetSettings: (
    requestParameters?: [ClientSettingsGetRequest] | undefined,
  ) => UseOpenApiHook<SettingsApi, 'clientSettingsGet'>;
  GetRelationships: (
    requestParameters?: [ClientRelationshipsGetRequest] | undefined,
  ) => UseOpenApiHook<RelationshipsApi, 'clientRelationshipsGet'>;
  GetPaymentTenders: (
    requestParameters?: [ClientPaymentTendersGetRequest] | undefined,
  ) => UseOpenApiHook<PaymentTypesApi, 'clientPaymentTendersGet'>;
  GetPaymentOptions: (
    requestParameters?: [ClientPaymentOptionsGetRequest] | undefined,
  ) => UseOpenApiHook<PaymentOptionsApi, 'clientPaymentOptionsGet'>;
  GetSignatureTypes: (
    requestParameters?: [] | undefined,
  ) => UseOpenApiHook<SignatureTypesApi, 'clientSignaturesTypesGet'>;
  GetSites: (
    requestParameters?: [ClientSitesGetRequest] | undefined,
  ) => UseOpenApiHook<SitesApi, 'clientSitesGet'>;
  GetMySession: (
    requestParameters?: [ClientCacheSessionsMyGetRequest] | undefined,
  ) => UseOpenApiHook<SessionsApi, 'clientCacheSessionsMyGet'>;
  GetCustomer: (
    requestParameters?: [ClientCustomersCustomerIdGetRequest] | undefined,
  ) => UseOpenApiHook<CustomersApi, 'clientCustomersCustomerIdGet'>;
  PatchCustomer: (
    requestParameters?: [ClientCustomersCustomerCodePatchRequest] | undefined,
  ) => UseOpenApiHook<CustomersApi, 'clientCustomersCustomerCodePatch'>;
  GetEmployee: (
    requestParameters?: [ClientCustomersEmployeeGetRequest] | undefined,
  ) => UseOpenApiHook<CustomersApi, 'clientCustomersEmployeeGet'>;
  GetEligibility: (
    requestParameters?: [ClientEligibilityGetRequest] | undefined,
  ) => UseOpenApiHook<EligibilityApi, 'clientEligibilityGet'>;
  GetStations: (
    requestParameters?: [ClientStationsSiteIdGetRequest] | undefined,
  ) => UseOpenApiHook<StationsApi, 'clientStationsSiteIdGet'>;
  GetTills: (
    requestParameters?: [ClientTillsGetRequest] | undefined,
  ) => UseOpenApiHook<TillsApi, 'clientTillsGet'>;
  PutSignatureImage: (
    requestParameters?: [ClientCacheSignaturesImagesPutRequest] | undefined,
  ) => UseOpenApiHook<SignatureImagesApi, 'clientCacheSignaturesImagesPut'>;
  PutTransaction: (
    requestParameters?:
      | [ClientCacheSessionsSessionIdTransactionsPutRequest]
      | undefined,
  ) => UseOpenApiHook<
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsPut'
  >;
  PutTransactionPayment: (
    requestParameters?:
      | [
          ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRequest,
        ]
      | undefined,
  ) => UseOpenApiHook<
    TransactionPaymentsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPut'
  >;
  PutWillCall: (
    requestParameters?: [ClientWillCallPutRequest] | undefined,
  ) => UseOpenApiHook<WillCallApi, 'clientWillCallPut'>;
  PutPSECheck: (
    requestParameters?: [ClientPseCheckPutRequest] | undefined,
  ) => UseOpenApiHook<PseCheckApi, 'clientPseCheckPut'>;
  PostTransaction: (
    requestParameters?:
      | [
          ClientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRequest,
        ]
      | undefined,
  ) => UseOpenApiHook<
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatch'
  >;
  PostTransactionPayment: (
    requestParameters?:
      | [
          ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRequest,
        ]
      | undefined,
  ) => UseOpenApiHook<
    TransactionPaymentsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPost'
  >;
  PostPaymentDetails: (
    requestParameters?: [ClientPaymentDetailSiteIdPostRequest] | undefined,
  ) => UseOpenApiHook<PaymentsApi, 'clientPaymentDetailSiteIdPost'>;
  PostCustomerAR: (
    requestParameters?: [CustomersCustomerIdAccountsArPostRequest] | undefined,
  ) => UseOpenApiHook<CustomerAccountsApi, 'customersCustomerIdAccountsArPost'>;
  PostCustomerPD: (
    requestParameters?: [CustomersCustomerIdAccountsPdPostRequest] | undefined,
  ) => UseOpenApiHook<CustomerAccountsApi, 'customersCustomerIdAccountsPdPost'>;
  PostSearchCustomers: (
    requestParameters?: [ClientCustomersSearchPostRequest] | undefined,
  ) => UseOpenApiHook<CustomersApi, 'clientCustomersSearchPost'>;
  DeleteTransaction: (
    requestParameters?:
      | [
          ClientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRequest,
        ]
      | undefined,
  ) => UseOpenApiHook<
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatch'
  >;
  DeleteTransactionPayment: (
    requestParameters?:
      | [
          ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRequest,
        ]
      | undefined,
  ) => UseOpenApiHook<
    TransactionPaymentsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDelete'
  >;
  VoidPayment: (
    requestParameters?: [ClientPaymentVoidSiteIdPostRequest] | undefined,
  ) => UseOpenApiHook<PaymentsApi, 'clientPaymentVoidSiteIdPost'>;
  CloseSession: (
    requestParameters?: [] | undefined,
  ) => UseOpenApiHook<SessionsApi, 'clientCacheSessionsMyClosePost'>;
  UpdatePaymentDeviceAddress: (
    requestParameters?:
      | [ClientCacheSessionsSessionIdPaymentDeviceAddressPutRequest]
      | undefined,
  ) => UseOpenApiHook<
    SessionsApi,
    'clientCacheSessionsSessionIdPaymentDeviceAddressPut'
  >;
}

// useOpenApi implementations
const createSession = (
  requestParameters?: [ClientCacheSessionsPostRequest] | undefined,
) =>
  useOpenApi(
    SessionsApi,
    'clientCacheSessionsPost',
    API_VERSION,
    requestParameters,
  );

const updatePaymentDeviceAddress = (
  requestParameters?:
    | [ClientCacheSessionsSessionIdPaymentDeviceAddressPutRequest]
    | undefined,
) =>
  useOpenApi(
    SessionsApi,
    'clientCacheSessionsSessionIdPaymentDeviceAddressPut',
    API_VERSION,
    requestParameters,
  );

const createTransaction = (
  requestParameters?:
    | [ClientCacheSessionsSessionIdTransactionsPostRequest]
    | undefined,
) =>
  useOpenApi(
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsPost',
    API_VERSION,
    requestParameters,
  );

const getBarcodes = (
  requestParameters?: [ClientBarcodesGetRequest] | undefined,
) =>
  useOpenApi(BarcodesApi, 'clientBarcodesGet', API_VERSION, requestParameters);

const getTaxGroups = (
  requestParameters?: [ClientTaxGroupsGetRequest] | undefined,
) =>
  useOpenApi(
    TaxGroupsApi,
    'clientTaxGroupsGet',
    API_VERSION,
    requestParameters,
  );

const getSettings = (
  requestParameters?: [ClientSettingsGetRequest] | undefined,
) =>
  useOpenApi(SettingsApi, 'clientSettingsGet', API_VERSION, requestParameters);

const getRelationships = (
  requestParameters?: [ClientRelationshipsGetRequest] | undefined,
) =>
  useOpenApi(
    RelationshipsApi,
    'clientRelationshipsGet',
    API_VERSION,
    requestParameters,
  );

const getPaymentTenders = (
  requestParameters?: [ClientPaymentTendersGetRequest] | undefined,
) =>
  useOpenApi(
    PaymentTypesApi,
    'clientPaymentTendersGet',
    API_VERSION,
    requestParameters,
  );

const getPaymentOptions = (
  requestParameters?: [ClientPaymentOptionsGetRequest] | undefined,
) =>
  useOpenApi(
    PaymentOptionsApi,
    'clientPaymentOptionsGet',
    API_VERSION,
    requestParameters,
  );

const getSignatureTypes = (requestParameters?: [] | undefined) =>
  useOpenApi(
    SignatureTypesApi,
    'clientSignaturesTypesGet',
    API_VERSION,
    requestParameters,
  );

const getSites = (requestParameters?: [ClientSitesGetRequest] | undefined) =>
  useOpenApi(SitesApi, 'clientSitesGet', API_VERSION, requestParameters);

const getMySession = (
  requestParameters?: [ClientCacheSessionsMyGetRequest] | undefined,
) =>
  useOpenApi(
    SessionsApi,
    'clientCacheSessionsMyGet',
    API_VERSION,
    requestParameters,
  );

const getCustomer = (
  requestParameters?: [ClientCustomersCustomerIdGetRequest] | undefined,
) =>
  useOpenApi(
    CustomersApi,
    'clientCustomersCustomerIdGet',
    API_VERSION,
    requestParameters,
  );

const getEmployee = (
  requestParameters?: [ClientCustomersEmployeeGetRequest] | undefined,
) =>
  useOpenApi(
    CustomersApi,
    'clientCustomersEmployeeGet',
    API_VERSION,
    requestParameters,
  );

const getEligibility = (
  requestParameters?: [ClientEligibilityGetRequest] | undefined,
) =>
  useOpenApi(
    EligibilityApi,
    'clientEligibilityGet',
    API_VERSION,
    requestParameters,
  );

const getStations = (
  requestParameters?: [ClientStationsSiteIdGetRequest] | undefined,
) =>
  useOpenApi(
    StationsApi,
    'clientStationsSiteIdGet',
    API_VERSION,
    requestParameters,
  );

const getTills = (requestParameters?: [ClientTillsGetRequest] | undefined) =>
  useOpenApi(TillsApi, 'clientTillsGet', API_VERSION, requestParameters);

const patchCustomer = (
  requestParameters?: [ClientCustomersCustomerCodePatchRequest] | undefined,
) =>
  useOpenApi(
    CustomersApi,
    'clientCustomersCustomerCodePatch',
    API_VERSION,
    requestParameters,
  );
const putSignatureImage = (
  requestParameters?: [ClientCacheSignaturesImagesPutRequest] | undefined,
) =>
  useOpenApi(
    SignatureImagesApi,
    'clientCacheSignaturesImagesPut',
    API_VERSION,
    requestParameters,
  );

const putTransaction = (
  requestParameters?:
    | [ClientCacheSessionsSessionIdTransactionsPutRequest]
    | undefined,
) =>
  useOpenApi(
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsPut',
    API_VERSION,
    requestParameters,
  );

const putTransactionPayment = (
  requestParameters?:
    | [ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRequest]
    | undefined,
) =>
  useOpenApi(
    TransactionPaymentsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPut',
    API_VERSION,
    requestParameters,
  );

const putWillCall = (
  requestParameters?: [ClientWillCallPutRequest] | undefined,
) =>
  useOpenApi(WillCallApi, 'clientWillCallPut', API_VERSION, requestParameters);

const putPSECheck = (
  requestParameters?: [ClientPseCheckPutRequest] | undefined,
) =>
  useOpenApi(PseCheckApi, 'clientPseCheckPut', API_VERSION, requestParameters);

const postTransaction = (
  requestParameters?:
    | [
        ClientCacheSessionsSessionIdTransactionsTransactionIdCompletePatchRequest,
      ]
    | undefined,
) =>
  useOpenApi(
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatch',
    API_VERSION,
    requestParameters,
  );

const postTransactionPayment = (
  requestParameters?:
    | [ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRequest]
    | undefined,
) =>
  useOpenApi(
    TransactionPaymentsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPost',
    API_VERSION,
    requestParameters,
  );

const postPaymentDetails = (
  requestParameters?: [ClientPaymentDetailSiteIdPostRequest] | undefined,
) =>
  useOpenApi(
    PaymentsApi,
    'clientPaymentDetailSiteIdPost',
    API_VERSION,
    requestParameters,
  );

const postCustomerAR = (
  requestParameters?: [CustomersCustomerIdAccountsArPostRequest] | undefined,
) =>
  useOpenApi(
    CustomerAccountsApi,
    'customersCustomerIdAccountsArPost',
    API_VERSION,
    requestParameters,
  );

const postCustomerPD = (
  requestParameters?: [CustomersCustomerIdAccountsPdPostRequest] | undefined,
) =>
  useOpenApi(
    CustomerAccountsApi,
    'customersCustomerIdAccountsPdPost',
    API_VERSION,
    requestParameters,
  );

const postSearchCustomers = (
  requestParameters?: [ClientCustomersSearchPostRequest] | undefined,
) =>
  useOpenApi(
    CustomersApi,
    'clientCustomersSearchPost',
    API_VERSION,
    requestParameters,
  );

const deleteTransaction = (
  requestParameters?:
    | [ClientCacheSessionsSessionIdTransactionsTransactionIdCancelPatchRequest]
    | undefined,
) =>
  useOpenApi(
    TransactionsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatch',
    API_VERSION,
    requestParameters,
  );

const deleteTransactionPayment = (
  requestParameters?:
    | [
        ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRequest,
      ]
    | undefined,
) =>
  useOpenApi(
    TransactionPaymentsApi,
    'clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDelete',
    API_VERSION,
    requestParameters,
  );

const voidPayment = (
  requestParameters?: [ClientPaymentVoidSiteIdPostRequest] | undefined,
) =>
  useOpenApi(
    PaymentsApi,
    'clientPaymentVoidSiteIdPost',
    API_VERSION,
    requestParameters,
  );

const closeSession = (requestParameters?: [] | undefined) =>
  useOpenApi(
    SessionsApi,
    'clientCacheSessionsMyClosePost',
    API_VERSION,
    requestParameters,
  );

// Export Context
export const ApiContext = createContext<Context>({
  CreateSession: createSession,
  CreateTransaction: createTransaction,
  GetBarcodes: getBarcodes,
  GetTaxGroups: getTaxGroups,
  GetSettings: getSettings,
  GetRelationships: getRelationships,
  GetPaymentTenders: getPaymentTenders,
  GetPaymentOptions: getPaymentOptions,
  GetSignatureTypes: getSignatureTypes,
  GetSites: getSites,
  GetMySession: getMySession,
  GetCustomer: getCustomer,
  GetEmployee: getEmployee,
  GetEligibility: getEligibility,
  GetStations: getStations,
  GetTills: getTills,
  PatchCustomer: patchCustomer,
  PutSignatureImage: putSignatureImage,
  PutTransaction: putTransaction,
  PutTransactionPayment: putTransactionPayment,
  PutWillCall: putWillCall,
  PutPSECheck: putPSECheck,
  PostTransaction: postTransaction,
  PostTransactionPayment: postTransactionPayment,
  PostPaymentDetails: postPaymentDetails,
  PostCustomerAR: postCustomerAR,
  PostCustomerPD: postCustomerPD,
  PostSearchCustomers: postSearchCustomers,
  DeleteTransaction: deleteTransaction,
  DeleteTransactionPayment: deleteTransactionPayment,
  VoidPayment: voidPayment,
  CloseSession: closeSession,
  UpdatePaymentDeviceAddress: updatePaymentDeviceAddress,
});

// Export Provider
export function ApiProvider(props: {children?: React.ReactNode}): JSX.Element {
  return (
    <ApiContext.Provider
      value={{
        CreateSession: createSession,
        CreateTransaction: createTransaction,
        GetBarcodes: getBarcodes,
        GetTaxGroups: getTaxGroups,
        GetSettings: getSettings,
        GetRelationships: getRelationships,
        GetPaymentTenders: getPaymentTenders,
        GetPaymentOptions: getPaymentOptions,
        GetSignatureTypes: getSignatureTypes,
        GetSites: getSites,
        GetMySession: getMySession,
        GetCustomer: getCustomer,
        GetEmployee: getEmployee,
        GetEligibility: getEligibility,
        GetStations: getStations,
        GetTills: getTills,
        PatchCustomer: patchCustomer,
        PutSignatureImage: putSignatureImage,
        PutTransaction: putTransaction,
        PutTransactionPayment: putTransactionPayment,
        PutWillCall: putWillCall,
        PutPSECheck: putPSECheck,
        PostTransaction: postTransaction,
        PostTransactionPayment: postTransactionPayment,
        PostPaymentDetails: postPaymentDetails,
        PostCustomerAR: postCustomerAR,
        PostCustomerPD: postCustomerPD,
        PostSearchCustomers: postSearchCustomers,
        DeleteTransaction: deleteTransaction,
        DeleteTransactionPayment: deleteTransactionPayment,
        VoidPayment: voidPayment,
        CloseSession: closeSession,
        UpdatePaymentDeviceAddress: updatePaymentDeviceAddress,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
}

export const useApi = (): Context => useContext(ApiContext);
