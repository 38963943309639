/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerResponse,
    CustomerResponseFromJSON,
    CustomerResponseFromJSONTyped,
    CustomerResponseToJSON,
    Prescription,
    PrescriptionFromJSON,
    PrescriptionFromJSONTyped,
    PrescriptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface WillCallResponseBody
 */
export interface WillCallResponseBody {
    /**
     * 
     * @type {Array<CustomerResponse>}
     * @memberof WillCallResponseBody
     */
    customers: Array<CustomerResponse>;
    /**
     * 
     * @type {Array<Prescription>}
     * @memberof WillCallResponseBody
     */
    prescriptions: Array<Prescription>;
}

export function WillCallResponseBodyFromJSON(json: any): WillCallResponseBody {
    return WillCallResponseBodyFromJSONTyped(json, false);
}

export function WillCallResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WillCallResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customers': ((json['customers'] as Array<any>).map(CustomerResponseFromJSON)),
        'prescriptions': ((json['prescriptions'] as Array<any>).map(PrescriptionFromJSON)),
    };
}

export function WillCallResponseBodyToJSON(value?: WillCallResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customers': ((value.customers as Array<any>).map(CustomerResponseToJSON)),
        'prescriptions': ((value.prescriptions as Array<any>).map(PrescriptionToJSON)),
    };
}


