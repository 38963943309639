import {IIdleTimer, withIdleTimer} from 'react-idle-timer';

type IIdleTimerComponentProps = {
  children?: React.ReactNode;
} & IIdleTimer;

const IdleTimerComponent = ({children}: IIdleTimerComponentProps) => {
  return <>{children}</>;
};

export const IdleTimer = withIdleTimer(IdleTimerComponent);
