import {ScrollContainer, Stack} from '@emporos/components';
import {BillBoard} from './';

interface Props {
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
}

export function CashPayment({amount, setAmount, totalDue}: Props): JSX.Element {
  return (
    <>
      <ScrollContainer>
        <Stack style={{flex: 1}}>
          <BillBoard
            value={amount}
            onChange={setAmount}
            totalDue={totalDue}
            showNumberButtons
          />
        </Stack>
      </ScrollContainer>
    </>
  );
}
