/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionExtra
 */
export interface TransactionExtra {
    /**
     * 
     * @type {string}
     * @memberof TransactionExtra
     */
    rowId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionExtra
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionExtra
     */
    dataKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionExtra
     */
    dataValue?: string | null;
}

export function TransactionExtraFromJSON(json: any): TransactionExtra {
    return TransactionExtraFromJSONTyped(json, false);
}

export function TransactionExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionExtra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowId': json['rowId'],
        'transactionId': json['transactionId'],
        'dataKey': !exists(json, 'dataKey') ? undefined : json['dataKey'],
        'dataValue': !exists(json, 'dataValue') ? undefined : json['dataValue'],
    };
}

export function TransactionExtraToJSON(value?: TransactionExtra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowId': value.rowId,
        'transactionId': value.transactionId,
        'dataKey': value.dataKey,
        'dataValue': value.dataValue,
    };
}


