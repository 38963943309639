import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface Props {
  loadingPendingTransactionPaymentId?: string;
  setLoadingPendingTransactionPaymentId: Dispatch<
    SetStateAction<string | undefined>
  >;
}

// the string represents the transactionPaymentId that is currently processing
const CreditCardProcessingContext = createContext<Props>({
  setLoadingPendingTransactionPaymentId: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: SetStateAction<string | undefined>,
  ) => {
    return;
  },
});

export function CreditCardProcessingProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [
    loadingPendingTransactionPaymentId,
    setLoadingPendingTransactionPaymentId,
  ] = useState<string>();

  return (
    <CreditCardProcessingContext.Provider
      value={{
        loadingPendingTransactionPaymentId,
        setLoadingPendingTransactionPaymentId,
      }}
    >
      {children}
    </CreditCardProcessingContext.Provider>
  );
}
export const useCreditCardProcessing = (): Props =>
  useContext(CreditCardProcessingContext);
