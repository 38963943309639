import {useCallback, useState} from 'react';

export enum MultiSelectMode {
  Single,
  Multiple,
}

export type MultiSelectResult<T> = {
  deselect: (...rows: T[]) => void;
  isSelected: (row: T) => boolean;
  mode: MultiSelectMode;
  reset: () => void;
  selectMultiple: (...rows: T[]) => void;
  selectOne: (row: T) => void;
  single: () => T | null;
  size: number;
};

/**
 * A hook that maintains a set of selected items. Can switch between single
 * and multi select modes.
 */
export const useMultiSelect = <T>(): MultiSelectResult<T> => {
  const [mode, setMode] = useState<MultiSelectMode>(MultiSelectMode.Single);
  const [selected, setSelected] = useState(new Set<T>());
  const reset = useCallback(() => {
    setSelected(new Set());
    setMode(MultiSelectMode.Single);
  }, []);

  return {
    mode,
    reset,
    size: selected.size,
    single: useCallback(() => {
      if (selected.size > 1) {
        return null;
      }

      return Array.from(selected)[0] || null;
    }, [selected]),
    selectMultiple: useCallback((...rows: T[]) => {
      setSelected(selection => {
        rows.forEach(row => selection.add(row));
        return new Set(selection);
      });
      setMode(MultiSelectMode.Multiple);
    }, []),
    selectOne: useCallback((row: T) => {
      setSelected(new Set([row]));
      setMode(MultiSelectMode.Single);
    }, []),
    deselect: useCallback(
      (...rows: T[]) => {
        rows.forEach(row => selected.delete(row));
        setSelected(new Set(selected));
        if (selected.size === 0) {
          reset();
        }
      },
      [selected],
    ),
    isSelected: useCallback((row: T): boolean => selected.has(row), [selected]),
  };
};
