import {RouteComponentProps} from '@reach/router';
import {memo, PropsWithChildren} from 'react';
import {
  CustomerSearchProvider,
  ManualRXFormData,
  OtcSearchPage,
} from '../../../../';

export type ItemSearchState = ManualRXFormData;

function OtcSearchComponent(
  props: PropsWithChildren<RouteComponentProps<unknown>>,
): JSX.Element {
  return (
    <CustomerSearchProvider>
      <OtcSearchPage {...props} />
    </CustomerSearchProvider>
  );
}

export const OtcSearchIntegration = memo(OtcSearchComponent);
