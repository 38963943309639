import {
  ButtonShape,
  Icon,
  Icons,
  IconSize,
  Shape,
  useAnimatedPosition,
  useDeviceDetect,
  useMounted,
  usePrevious,
  Vec2,
} from '@emporos/components';
import {
  Dispatch,
  memo,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Draggable, {DraggableEventHandler} from 'react-draggable';
import {ResizableBox, ResizeCallbackData} from 'react-resizable';
import styled from 'styled-components';

const ASPECT_RATIO = 2 / 3;
const DEFAULT_WIDTH_DEVICE = 400;
const DEFAULT_HEIGHT_DEVICE = DEFAULT_WIDTH_DEVICE * ASPECT_RATIO;
const DEFAULT_WIDTH = 600;
const DEFAULT_HEIGHT = DEFAULT_WIDTH * ASPECT_RATIO;
const TRANSITION_DURATION = 300;
const MIN_CONSTRAINTS: [number, number] = [
  DEFAULT_WIDTH / 3,
  DEFAULT_HEIGHT / 3,
];

export type FloatingWrapperProps = PropsWithChildren<{
  icon: keyof typeof Icons;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  originX?: number;
  originY?: number;
  origin?: Vec2;
  openButtonLabel?: string;
  closeButtonLabel?: string;
  visible?: boolean;
}>;

// NOTE: The @media queries are a workaround for when portrait is not supported; they will need to be removed when portrait mode is implemented
const Content = styled.div<{$circle: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  cursor: grab;
  overflow: hidden;
  box-shadow: ${({theme}) => theme.shadows.shadow_16};
  border-radius: ${({$circle}) => ($circle ? '50%' : '12px')};
  opacity: 1;
  transition: border-radius 75ms linear, opacity 150ms linear;
  z-index: 1;

  &.hidden {
    display: none;
  }

  @media only screen and (orientation: portrait) {
    display: none;
  }
`;

const InnerWrapper = styled.div<{$circle?: boolean}>`
  height: 100%;
  width: 100%;
  background-color: ${({theme, $circle}) =>
    $circle ? theme.colors.blue : theme.colors.steel};
  height: 100%;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  transition: background-color ${TRANSITION_DURATION}ms ease-out;
`;

const Button = styled.button`
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 12px 0;
  background-color: ${({theme}) => theme.colors.black_20};
  color: ${({theme}) => theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  /* Fix bug in Safari where buttons on top of translating <video> element
    flicker when the element moves. */
  -webkit-transform: translate3d(0, 0, 0);

  &:active {
    background-color: ${({theme}) => theme.colors.black_50};
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({theme}) => theme.colors.black_50};
    }
  }

  &:focus {
    outline: none;
  }
`;

const ResizeButton = styled(Button)`
  cursor: se-resize;
  bottom: 0;
  right: 0;
`;

const CloseButton = styled(Button)`
  cursor: pointer;
  top: 0;
  left: 0;
`;

const easeInOutQuad = (t: number) =>
  t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

type FloatingWrapperBaseProps = PropsWithChildren<{
  visible: boolean;
  collapsed: boolean;
  position: Vec2;
  dimensions: Vec2;
  icon: keyof typeof Icons;
  openButtonLabel?: string;
  closeButtonLabel?: string;
  open: boolean;
  onCloseClick: () => unknown;
  onOpenClick: () => unknown;
  onDrag: (to: Vec2) => unknown;
  onResize: (dimensions: Vec2) => unknown;
}>;

function FloatingWrapperBase(props: FloatingWrapperBaseProps) {
  const {
    visible,
    collapsed,
    dimensions: [width, height],
    position: [x, y],
    open,
    onDrag,
    onResize,
  } = props;
  const _onDrag: DraggableEventHandler = useCallback(
    (e, data) => {
      onDrag([data.x, data.y]);
    },
    [onDrag],
  );
  const _onResize = useCallback(
    (e: React.SyntheticEvent, data: ResizeCallbackData) =>
      onResize([data.size.width, data.size.height]),
    [onResize],
  );

  const draggableNodeRef = useRef<HTMLDivElement>(null);
  const resizeHandleRef = useRef<HTMLButtonElement>(null);
  const resizeHandleStyle = useMemo(
    () => ({opacity: open && visible ? 1 : 0}),
    [open, visible],
  );
  const resizeHandle = useMemo(
    () => (
      <ResizeButton ref={resizeHandleRef} style={resizeHandleStyle}>
        <Icon icon="Grip" variant="inverted" />
      </ResizeButton>
    ),
    [resizeHandleStyle],
  );
  const draggableOptions = useMemo(
    () => ({
      nodeRef: resizeHandleRef,
    }),
    [],
  );
  const position = useMemo(() => ({x, y}), [x, y]);

  return (
    <Draggable
      bounds="parent"
      onDrag={_onDrag}
      handle=".drag-handle"
      position={position}
      disabled={!open}
      nodeRef={draggableNodeRef}
    >
      <Content
        ref={draggableNodeRef}
        $circle={open ? false : collapsed}
        className={visible ? '' : 'hidden'}
      >
        <ResizableBox
          width={Math.max(80, width)}
          height={Math.max(80, height)}
          minConstraints={MIN_CONSTRAINTS}
          lockAspectRatio
          handle={resizeHandle}
          data-testid="floating-wrapper"
          onResize={_onResize}
          draggableOpts={draggableOptions}
        >
          <InnerWrapper className="drag-handle" $circle={!open}>
            <ButtonShape
              icon={props.icon}
              shape={Shape.Circle}
              onClick={props.onOpenClick}
              style={{
                position: 'relative',
                zIndex: 999,
                display: collapsed ? 'initial' : 'none',
              }}
              aria-label={props.openButtonLabel}
              inverted
            />
            {open && visible && props.children}
          </InnerWrapper>
        </ResizableBox>
        {open && (
          <CloseButton
            onClick={props.onCloseClick}
            aria-label={props.closeButtonLabel}
          >
            <Icon icon="X" size={IconSize.S} variant="inverted" />
          </CloseButton>
        )}
      </Content>
    </Draggable>
  );
}

export const FloatingWrapper = memo((props: FloatingWrapperProps) => {
  const {originX = 0, originY = 0, icon, visible = true, open, setOpen} = props;
  const origin = useMemo(() => [originX, originY] as Vec2, [originX, originY]);
  const mounted = useMounted();
  const {changed: toggled} = usePrevious(open);
  const {changed: originChanged} = usePrevious(origin);
  const [[width, height], setDimensions] = useState<Vec2>([
    DEFAULT_WIDTH,
    DEFAULT_HEIGHT,
  ]);
  const [position, setPosition] = useState<Vec2>([
    (window.innerWidth - width) / 2,
    (window.innerHeight - height) / 2,
  ]);
  const {isTouchDevice} = useDeviceDetect();

  useEffect(() => {
    if (isTouchDevice) {
      setDimensions([DEFAULT_WIDTH_DEVICE, DEFAULT_HEIGHT_DEVICE]);
      setPosition([0, window.innerHeight - DEFAULT_HEIGHT_DEVICE]);
    }
  }, [isTouchDevice]);

  const shouldTriggerAnimation = mounted && toggled;
  const {
    alpha,
    animating,
    position: renderPosition,
    setPosition: setRenderPosition,
  } = useAnimatedPosition(
    open ? origin : position,
    open ? position : origin,
    easeInOutQuad,
    TRANSITION_DURATION,
    shouldTriggerAnimation,
  );

  const onDrag = (to: Vec2) => {
    if (!open) {
      return;
    }

    setPosition(to);
    setRenderPosition(to);
  };
  const onResize = (dimensions: Vec2) => {
    setDimensions(dimensions);
  };

  useEffect(() => {
    if (!open && originChanged) {
      setRenderPosition(origin);
    }
  }, [setRenderPosition, open, origin, originChanged]);

  const renderWidth = animating ? width * alpha : width;
  const renderHeight = animating ? height * alpha : height;

  return (
    <FloatingWrapperBase
      visible={visible}
      position={renderPosition}
      dimensions={[
        open ? renderWidth : width - renderWidth,
        open ? renderHeight : height - renderHeight,
      ]}
      icon={icon}
      openButtonLabel={props.openButtonLabel || 'Open Floating Window'}
      open={open}
      closeButtonLabel={props.closeButtonLabel || 'Close Floating Window'}
      onOpenClick={() => setOpen(true)}
      onCloseClick={() => setOpen(false)}
      onDrag={onDrag}
      onResize={onResize}
      collapsed={open ? false : alpha >= 0.5}
    >
      {props.children}
    </FloatingWrapperBase>
  );
});
