import {memo, useCallback, useEffect, useRef} from 'react';
import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import styled from 'styled-components';
import {BarcodeCaptureSettingsExtended} from './ScannerSettings/BarcodeCaptureSettingsExtended';

const DELAY_BETWEEN_SCANS = 700;

export interface BarcodeScannerProps {
  onScan: (result: SDCBarcode.Barcode) => void;
  onScanError: (error: Error) => void;
  onError: (error: Error) => void;
  // TODO: this should be made non-optional once we add this to the Enterprise
  // settings API
  licenseKey?: string;
  enabledSymbologies: SDCBarcode.Symbology[];
  height?: number | string;
  isScanning?: boolean;
}
const StyledBarcodeScanner = styled.div`
  height: 100%;

  .scandit {
    &.scandit-container {
      border-radius: 12px;
    }
    .scandit-video {
      border-radius: 12px;
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

const videoResolutionHeightDictionary: ReadonlyMap<
  SDCCore.VideoResolution,
  number
> = new Map([
  [SDCCore.VideoResolution.Auto, 0],
  [SDCCore.VideoResolution.HD, 720],
  [SDCCore.VideoResolution.FullHD, 1080],
  [SDCCore.VideoResolution.UHD4K, 2160],
]);

export function isResolutionSupported(
  ctx: SDCCore.DataCaptureContext,
  resolution: SDCCore.VideoResolution,
): boolean {
  const resolutionHeightInPixels =
    videoResolutionHeightDictionary.get(resolution)!;
  const camera = ctx.frameSource as SDCCore.Camera;
  const cameraResolution = camera.currentResolution ?? {
    height: 0,
    width: 0,
  };
  return (
    cameraResolution.height >= resolutionHeightInPixels ||
    cameraResolution.width >= resolutionHeightInPixels
  );
}

export const BarcodeScanner = memo(
  ({
    onScan,
    onScanError,
    onError,
    enabledSymbologies,
    height = '100%',
  }: BarcodeScannerProps): JSX.Element => {
    const dataCaptureView = useRef<HTMLDivElement>(null);
    const barcodeCaptureOverlayRef = useRef<SDCBarcode.BarcodeCaptureOverlay>();
    const viewfinderRef = useRef<SDCCore.Viewfinder>(
      new SDCCore.RectangularViewfinder(
        SDCCore.RectangularViewfinderStyle.Square,
        SDCCore.RectangularViewfinderLineStyle.Light,
      ),
    );

    const didScanListener = useCallback(
      async (
        barcodeCaptureMode: SDCBarcode.BarcodeCapture,
        session: SDCBarcode.BarcodeCaptureSession,
      ): Promise<void> => {
        try {
          // no more didScan callbacks will be invoked after this call.
          await barcodeCaptureMode.setEnabled(false);
          // Hide the viewfinder.
          await barcodeCaptureOverlayRef.current!.setViewfinder(null);
          // turn off the camera
          const barcode: SDCBarcode.Barcode =
            session.newlyRecognizedBarcodes[0];
          onScan(barcode);

          // Delay to avoid multiple scans of the same barcode.
          await new Promise(resolve =>
            setTimeout(resolve, DELAY_BETWEEN_SCANS),
          );

          await barcodeCaptureOverlayRef.current!.setViewfinder(
            viewfinderRef.current,
          );
          await barcodeCaptureMode.setEnabled(true);
        } catch (error) {
          onScanError(error as Error);
        }
      },
      [onScan, onScanError],
    );

    useEffect(() => {
      const init = async (ctx: SDCCore.DataCaptureContext) => {
        const camera: SDCCore.Camera = SDCCore.Camera.default;
        const cameraSettings =
          SDCBarcode.BarcodeCapture.recommendedCameraSettings;
        await camera.applySettings(cameraSettings);
        await ctx.setFrameSource(camera);

        // The barcode capturing process is configured through barcode capture settings,
        // they are then applied to the barcode capture instance that manages barcode recognition.
        const settings: SDCBarcode.BarcodeCaptureSettings =
          new BarcodeCaptureSettingsExtended(enabledSymbologies);

        // Create a new barcode capture mode with the settings from above.
        const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
          ctx,
          settings,
        );
        // Disable the barcode capture mode until the camera is accessed.
        await barcodeCapture.setEnabled(false);

        // Register a listener to get informed whenever a new barcode got recognized.
        barcodeCapture.addListener({
          didScan: didScanListener,
        });

        // To visualize the ongoing barcode capturing process on screen, set up a data capture view that renders the
        // camera preview. The view must be connected to the data capture context.
        const view = await SDCCore.DataCaptureView.forContext(ctx);

        // Connect the data capture view to the HTML element.
        view.connectToElement(dataCaptureView.current!);

        // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
        // the video preview. This is optional, but recommended for better visual feedback.
        barcodeCaptureOverlayRef.current =
          await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
            barcodeCapture,
            view,
            SDCBarcode.BarcodeCaptureOverlayStyle.Frame,
          );

        await barcodeCaptureOverlayRef.current.setViewfinder(
          viewfinderRef.current,
        );

        // Switch the camera on to start streaming frames.
        // The camera is started asynchronously and will take some time to completely turn on.
        await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
        await barcodeCapture.setEnabled(true);

        if (isResolutionSupported(ctx, SDCCore.VideoResolution.UHD4K)) {
          cameraSettings.preferredResolution = SDCCore.VideoResolution.UHD4K;
          await camera.applySettings(cameraSettings);
        } else if (isResolutionSupported(ctx, SDCCore.VideoResolution.FullHD)) {
          cameraSettings.preferredResolution = SDCCore.VideoResolution.FullHD;
          await camera.applySettings(cameraSettings);
        }
      };

      let context: SDCCore.DataCaptureContext;

      SDCCore.DataCaptureContext.create().then(c => {
        context = c;
        init(context).catch(error => onError(error));
      });

      return () => {
        if (context?.frameSource) {
          context.frameSource?.switchToDesiredState(
            SDCCore.FrameSourceState.Off,
          );
          context.removeAllModes(); //This line removes the listener to avoid executing it n*modalOpened
          context.dispose();
        }
      };
    }, [didScanListener, enabledSymbologies, onError]);

    return (
      <Container style={{height: height}}>
        <StyledBarcodeScanner
          ref={dataCaptureView}
          id="data-capture-view"
        ></StyledBarcodeScanner>
      </Container>
    );
  },
);
