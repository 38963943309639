import {TransactionTax, TransactionTaxRequest} from '@emporos/api-enterprise';

export function ConvertTransactionTaxesToRequests(
  taxes: TransactionTax[],
): TransactionTaxRequest[] {
  const requests: TransactionTaxRequest[] = [];
  taxes.forEach(tax => {
    requests.push(ConvertTransactionTaxToRequest(tax));
  });
  return requests;
}

export function ConvertTransactionTaxToRequest(
  tax: TransactionTax,
): TransactionTaxRequest {
  return {
    transactionTaxId: tax.transactionTaxId,
    taxID: tax.taxID,
    serverTransactionID: tax.serverTransactionID ?? 0,
    taxAmount: tax.taxAmount ?? 0,
    taxRateID: tax.taxRateID,
    taxRate: tax.taxRate ?? 0,
    taxableSubTotal: tax.taxableSubTotal ?? 0,
    taxGroupID: tax.taxGroupID,
  };
}
