import {
  Configuration,
  FetchParams,
  OtcItem,
  OtcItemListApiResponse,
  OtcItemsApi,
  Pagination,
  RequestContext,
  ResponseContext,
} from '@emporos/api-enterprise';
import {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {ApiVersion} from '../services/ApiVersion';
import {User} from 'oidc-client';
import {endpointConfig, formatDateForUrl} from '..';
import {ConsoleLogger} from '../utils/console-logger';

const MAX_RETRIES = 3;
const FAILED_PAGES_TO_FATAL = 3;

export interface SequentialOTCApiResponse
  extends OTCRequestTriggers,
    ResultsOTCApiResponse {}

interface OTCRequestTriggers {
  setPage: Dispatch<React.SetStateAction<number>>;
  setSyncPage: Dispatch<React.SetStateAction<number>>;
  onRetry: () => void;
  onSync: () => void;
}
export interface ResultsOTCApiResponse {
  data: OtcItem[];
  pagination: Pagination | undefined;
  syncPagination: Pagination | undefined;
  loading: boolean;
  fatalError: fatalOTCError;
  partialError: boolean;
  syncLoading: boolean;
}

// Indicates if we have a fatalError in process and if so, which process was running
export interface fatalOTCError {
  error: boolean;
  process: 'download' | 'sync';
}

export const useSequentialOTCApi = (
  user: User | null,
  siteId: number,
  version: ApiVersion,
  online: boolean,
  pageSize: number,
  compareDate: Date,
): SequentialOTCApiResponse => {
  const consoleLogger = new ConsoleLogger();
  const [page, setPage] = useState<number>(1);
  const pageRef = useRef<number>(1);
  const [partialError, setPartialError] = useState<boolean>(false);
  const [pagesFailed, setPagesFailed] = useState<number[]>([]);

  const [result, setResult] = useState<OtcItem[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageRetries, setPageRetries] = useState<number>(1);
  const [fatalError, setFatalError] = useState<fatalOTCError>({
    error: false,
    process: 'download',
  });
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [cachedSyncPages, setCachedSyncPages] = useState<number>(0);
  const [syncPage, setSyncPage] = useState<number>(0);
  const [syncPagination, setSyncPagination] = useState<Pagination>();
  const [syncItemCount, setSyncItemCount] = useState<number>(0);

  const tokenPreMiddleware = useCallback(
    async (context: RequestContext): Promise<FetchParams | void> => {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${user?.access_token}`);
      headers.append('Content-Type', `application/json;v=${version}`);
      return {
        url: context.url,
        init: {...context.init, headers: headers},
      };
    },
    [user],
  );

  const offlinePostMiddleware = useCallback(
    async (context: ResponseContext): Promise<Response | void> => {
      const {url, response, init} = context;
      const {method: iMethod} = init;

      const endpointConf = endpointConfig(url, iMethod);
      if (endpointConf?.offline && response.ok) {
        await global.caches
          ?.open('api-offline-otc')
          .then(async cache => cache.put(url, response.clone()));
      }
    },
    [],
  );

  const clientApi = useMemo(() => {
    const {CLIENT_API_URL} = process.env;
    const client = new OtcItemsApi(
      new Configuration({basePath: CLIENT_API_URL}),
    )
      .withPreMiddleware(tokenPreMiddleware)
      .withPostMiddleware(offlinePostMiddleware);
    return {client, CLIENT_API_URL};
  }, []);

  const updateData = async (requestResponse: Response) => {
    const response: OtcItemListApiResponse = await requestResponse.json();
    if (!!response.data) {
      setResult(res => [...res, ...(response.data as OtcItem[])]);
    }
    setPagination(response.meta?.pagination);
    if (!response.meta?.pagination.hasNextPage) {
      setLoading(false);
      setPage(0);
      if (isSyncing || cachedSyncPages > 0) {
        // setting page to 0 indicates conclusion of current process, allowing us to retry/resync by setting to page = 1
        setSyncPage(1);
      }
    }
  };

  const fetchData = async () => {
    const {client, CLIENT_API_URL} = clientApi;

    const url = `${CLIENT_API_URL}/client/otc-items/${siteId}?Page=${page}&PageSize=${pageSize}`;

    const config = endpointConfig(url, 'GET');

    if (config?.preferOffline) {
      const cache = await global.caches?.open('api-offline-otc');
      if (cache) {
        const requestResponse = await cache.match(url);
        if (page === 1) {
          await checkForCachedSyncPages(url);
        }
        if (requestResponse) {
          await updateData(requestResponse);
        } else {
          if (online) {
            const res = await client
              .clientOtcItemsSiteIdGetRaw({
                siteId,
                page,
                pageSize: pageSize,
              })
              .catch(() => setPageRetries(pr => pr + 1));
            if (res?.raw && res?.raw.ok) {
              setPageRetries(1);
              await updateData(res.raw);
            }
          }
        }
      } else {
        if (online) {
          const res = await client
            .clientOtcItemsSiteIdGetRaw({
              siteId,
              page,
              pageSize: pageSize,
            })
            .catch(() => {
              setPageRetries(pr => pr + 1);
            });
          if (res?.raw && res?.raw.ok) {
            setPageRetries(1);
            await updateData(res.raw);
          }
        }
      }
    }
  };

  const fetchDelta = async () => {
    const {client} = clientApi;

    if (online && isSyncing) {
      const res = await client
        .clientOtcItemsSiteIdGetRaw({
          siteId,
          page: syncPage,
          pageSize: pageSize,
          lastDate: compareDate,
        })
        .catch(() => {
          setPageRetries(pr => pr + 1);
        });
      if (res?.raw && res?.raw.ok) {
        setPageRetries(1);
        await updateResultFromSync(res.raw);
      }
    } else {
      applyCachedSyncPages();
    }
  };

  const updateResultFromSync = async (requestResponse: Response) => {
    const response: OtcItemListApiResponse = await requestResponse.json();
    if (!!response.data) {
      let itemCount = 0;
      response.data.forEach(item => {
        const index = result.findIndex(r => r.itemNumber === item.itemNumber);
        if (index === -1 && item.recordStatus != 'Disabled') {
          result.push(item);
          itemCount++;
        } else {
          if (item.recordStatus === 'Disabled') {
            result.splice(index, 1);
            itemCount--;
          } else {
            result[index] = item;
          }
        }
      });
      if (response.meta && response.meta.pagination) {
        const currentPagination = {...response.meta.pagination};
        currentPagination.count = syncItemCount + itemCount; // reassign count to include only the net of newly added items and deleted items
        setSyncPagination(currentPagination as Pagination);
      }
      setSyncItemCount(prevCount => prevCount + itemCount);
    }
    if (!response.meta?.pagination.hasNextPage) {
      setSyncLoading(false);
      setIsSyncing(false);
      // setting page to 0 indicates conclusion of current process, allowing us to retry/resync by setting to page = 1
      setSyncPage(0);
    }
  };

  const applyCachedSyncPages = async () => {
    const {CLIENT_API_URL} = clientApi;

    const syncUrl = `${CLIENT_API_URL}/client/otc-items/${siteId}?Page=${syncPage}&PageSize=${pageSize}&lastDate=${formatDateForUrl(
      compareDate,
    )}`;
    const cache = await global.caches?.open('api-offline-otc');
    if (cache) {
      const requestResponse = await cache.match(syncUrl);
      if (requestResponse) {
        await updateResultFromSync(requestResponse);
      } else {
        // we are only here because we are NOT online but cache indicates there should be more sync pages - ie we
        // experienced a fatalError during sync and now have part but not all of the sync pages cached.
        setFatalError({error: true, process: 'sync'});
        setSyncLoading(false);
      }
    }
  };

  const checkForCachedSyncPages = async (url: string) => {
    const syncUrl = `${url}&lastDate=${formatDateForUrl(compareDate)}`;
    try {
      const cache = await global.caches?.open('api-offline-otc');
      if (!cache) return;
      const cachedSyncResponse = await cache.match(syncUrl);
      if (cachedSyncResponse && page === 1) {
        const response: OtcItemListApiResponse =
          await cachedSyncResponse.json();
        if (response.meta?.pagination.pages) {
          setCachedSyncPages(response.meta?.pagination.pages);
        }
      }
    } catch (error) {
      consoleLogger.logError(
        'useSequentialOTCApi - error checking for cached sync pages:',
        syncUrl,
        error,
      );
    }
  };

  useEffect(() => {
    // do not fire when page = 0 as this indicates process has 'concluded'
    if (page > 0) {
      pageRef.current = page;
      fetchData();
    }
  }, [page, online]);

  useEffect(() => {
    pageRef.current = syncPage;
    // do not fire when syncPage = 0 as this indicates that process has 'concluded'
    if (!loading && syncPage > 0) {
      setSyncLoading(true); // could move this 'up' in the process during first fetchData when cachedSyncPages are found for UI (progress monitor) purposes
      fetchDelta();
    }
  }, [syncPage, online]);

  useEffect(() => {
    if (pageRetries > 1 && pageRetries <= MAX_RETRIES) {
      if (isSyncing) {
        fetchDelta();
      } else {
        fetchData();
      }
    } else if (pageRetries > MAX_RETRIES) {
      setPagesFailed(pf => [...pf, pageRef.current]);
    }
  }, [pageRetries]);

  useEffect(() => {
    const consecutive = consecutivesErrors();
    if (consecutive) {
      if (
        pagesFailed.length > 0 &&
        pagesFailed.length < FAILED_PAGES_TO_FATAL
      ) {
        setPartialError(true);
        setPageRetries(1);
        if (isSyncing) {
          setSyncPage(p => p + 1);
        } else {
          setPage(p => p + 1);
        }
      } else if (pagesFailed.length === FAILED_PAGES_TO_FATAL) {
        setFatalError({
          error: true,
          process: syncPage > 0 ? 'sync' : 'download',
        });
        setLoading(false);
        setSyncLoading(false);
      }
    } else {
      setPagesFailed(pfs => [pfs[pfs.length - 1]]);
    }
  }, [pagesFailed]);

  const consecutivesErrors = (): boolean => {
    if (pagesFailed.length > 1) {
      return (
        pagesFailed[pagesFailed.length - 2] + 1 ===
        pagesFailed[pagesFailed.length - 1]
      );
    }
    return true;
  };

  const onRetry = () => {
    setPartialError(false);
    setPagesFailed([]);
    setResult([]);
    setPagination(undefined);
    setLoading(true);
    setPageRetries(1);
    setFatalError({error: false, process: 'download'});
    setPage(1);
  };

  const onSync = () => {
    setPartialError(false);
    setPagesFailed([]);
    setResult([]); // Intentionally clearing this so that we restore to what original cached download was - that way delta is truly between session start and now
    setPagination(undefined);
    setSyncPagination(undefined);
    setLoading(true);
    setSyncLoading(false);
    setSyncItemCount(0);
    setPageRetries(1);
    setFatalError({error: false, process: 'download'});
    setIsSyncing(true);
    setPage(1);
  };

  return {
    data: result,
    pagination,
    syncPagination,
    loading,
    partialError,
    fatalError,
    syncLoading,
    onRetry,
    onSync,
    setPage,
    setSyncPage,
  };
};
