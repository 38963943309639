import {useEffect, useMemo} from 'react';
import {useTransactionsState} from '.';

export class SyncWarningsHandler {
  private _isSavingSession = false;

  constructor() {
    this._setWindowOnBeforeUnloadListener();
  }

  get isSavingSession(): boolean {
    return this._isSavingSession;
  }

  set isSavingSession(value: boolean) {
    this._isSavingSession = value;
  }

  private _setWindowOnBeforeUnloadListener(): void {
    if (window) window.onbeforeunload = this._beforeUnloadListener.bind(this);
  }

  private _beforeUnloadListener(e: BeforeUnloadEvent): string | undefined {
    if (this.isSavingSession) {
      e.preventDefault();
      return 'Alert';
    }
  }
}

export const SyncWarnings = ({
  children,
  syncWarningsHandler,
}: {
  children: JSX.Element;
  syncWarningsHandler?: SyncWarningsHandler;
}): JSX.Element => {
  const {savingSession} = useTransactionsState();
  const handler = useMemo(
    () => syncWarningsHandler ?? new SyncWarningsHandler(),
    [],
  );

  useEffect(() => {
    handler.isSavingSession = savingSession;
  }, [savingSession]);

  return children;
};
