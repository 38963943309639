import {
  Transaction,
  TransactionPayment,
  PaymentType,
} from '@emporos/api-enterprise';
import {
  AlertMessage,
  Button,
  FooterGroup,
  Gutter,
  Header,
  Icons,
  Modal,
  PaymentCard,
  Stack,
  Variant as BV,
} from '@emporos/components';
import {NavigateFn, RouteComponentProps} from '@reach/router';
import assert from 'assert';
import moment from 'moment';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AlertLogTypes,
  AnalyticType,
  ApiLogTypes,
  displayPaymentNumber,
  getPaymentIconType,
  getPaymentType,
  getSimplePaymentIcon,
  UpdateTransactionFn,
  useAnalyticsProvider,
  useTransaction,
  useLog,
  useSession,
  useSettings,
  useTransactionsState,
  withChildPage,
  WithPageProps,
  useGlobalData,
} from '../../../../../';
import {useApi} from '../../../../../contexts/ApiProvider';
import {
  DeviceStatus,
  HandleTransactionResponse,
  HandleTransactionResponseRef,
  TransactionResponse,
  transformCayanVaultToTransactionResponse,
} from './';

interface IntegrationProps extends RouteComponentProps {
  canVoid: boolean;
}

interface Props {
  payment?: TransactionPayment;
  creditCardPendingPaymentExpirationMinutes: number;
  paymentTenders: PaymentType[];
  onCancel: () => void;
  onVoid?: (
    transactionPayment: TransactionPayment | undefined,
  ) => Promise<void> | undefined;
  onGetDetails: () => Promise<TransactionResponse>;
  transaction: Transaction;
  updateTransaction: UpdateTransactionFn;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  navigate: NavigateFn;
}

enum M {
  None,
  Confirm,
  Error,
}

const PaymentInfoIntegration = withChildPage(
  ({canVoid, navigate}: IntegrationProps) => {
    const api = useApi();
    const {transaction, updateTransaction} = useTransaction();
    const {creditCardPendingPaymentExpirationMinutes} = useSettings();
    const {paymentTendersResult} = useGlobalData();
    const {siteId} = useSession();
    const {track} = useAnalyticsProvider();
    const {selectedPayment, setSelectedPayment} = useTransactionsState();
    const {run: voidPayment} = api.DeleteTransactionPayment();
    const {run: getDetails} = api.PostPaymentDetails();

    const {sessionId, transactionId, payments} = transaction;
    let payment = payments.find(
      ({transactionPaymentId}) => selectedPayment === transactionPaymentId,
    );

    assert(
      paymentTendersResult && paymentTendersResult.data,
      'Missing Payment Tenders',
    );
    assert(navigate, 'Missing navigate');

    useEffect(() => {
      if (!selectedPayment) {
        navigate('../');
      }
    }, [selectedPayment]);

    const onVoid = async (
      transactionPayment: TransactionPayment | undefined,
    ) => {
      payment = transactionPayment;
      if (!transactionPayment) return;
      const {transactionPaymentId} = transactionPayment;

      const selectedPaymentType = getPaymentType(
        transactionPayment.paymentTypeID || 0,
        paymentTendersResult.data || [],
      );
      track(AnalyticType.VoidPaymentType, {
        total: transactionPayment.amount,
        revenueType: selectedPaymentType,
      });
      if (selectedPaymentType === 'Credit Card') {
        const result = await voidPayment({
          sessionId,
          transactionId,
          transactionPaymentId,
        });
        updateTransaction(prevTransaction => ({
          payments: prevTransaction.payments.map(p =>
            p.transactionPaymentId === selectedPayment
              ? {
                  ...result,
                  isSynced: true,
                  isDeleted: true,
                }
              : p,
          ),
        }));
      } else {
        updateTransaction(prevTransaction => ({
          payments: prevTransaction.payments.map(p => ({
            ...p,
            ...(p.transactionPaymentId === selectedPayment && {
              isSynced: false,
              isDeleted: true,
            }),
          })),
        }));
      }
      return onCancel();
    };

    const onGetDetails = async () => {
      if (!payment?.cardToken) throw payment;

      const result = await getDetails({
        siteId: siteId,
        paymentDetailRequest: {siteId, transportKey: payment.cardToken},
      });
      if (!result.data) {
        throw result;
      }
      return transformCayanVaultToTransactionResponse(result.data);
    };

    const onCancel = () => {
      setSelectedPayment('');
      return navigate('../');
    };

    return (
      <PaymentInfo
        payment={payment}
        creditCardPendingPaymentExpirationMinutes={
          creditCardPendingPaymentExpirationMinutes
        }
        paymentTenders={paymentTendersResult.data}
        onCancel={onCancel}
        onVoid={pymt => (canVoid ? onVoid(pymt) : undefined)}
        onGetDetails={onGetDetails}
        transaction={transaction}
        updateTransaction={updateTransaction}
        setSelectedPayment={setSelectedPayment}
        navigate={navigate}
      />
    );
  },
);

export function PaymentInfo({
  payment,
  creditCardPendingPaymentExpirationMinutes,
  paymentTenders,
  onCancel,
  onVoid,
  transaction,
  updateTransaction,
  onGetDetails,
  setSelectedPayment,
  navigate,
}: Props): JSX.Element {
  const {logApi, logAlert} = useLog();
  const [modal, setModal] = useState(M.None);
  const [status, setStatus] = useState(DeviceStatus.Unknown);
  const handleRef = useRef<HandleTransactionResponseRef | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onVoidCancel = useCallback(() => {
    setModal(M.None);
  }, []);

  const onVoidPayment = useCallback(async () => {
    setModal(M.None);
    if (!onVoid) {
      return;
    }
    try {
      logApi(ApiLogTypes.VoidPending);
      await onVoid(payment);
    } catch (err) {
      logAlert(AlertLogTypes.Void, {info: 'Error'}, 'error');
      setModal(M.Error);
    }
  }, [onVoid]);

  const onGetDetailsTransaction = async () => {
    try {
      setStatus(DeviceStatus.CheckStatus);
      const result = await onGetDetails();
      handleRef.current?.handleTransactionResponse(result, payment);
      setErrorMessage(''); // Clear any existing error message on success
    } catch (e: unknown) {
      // explicitly annotate e as unknown
      setStatus(DeviceStatus.PaymentErrorUnknown);
      let errorMessage = 'An error occurred while getting details.';
      // Check if e is an instance of Error
      if (e instanceof Error) {
        errorMessage += ` Error: ${e.message}`;
      }
      // Check if e is an object with a response property
      else if (typeof e === 'object' && e !== null && 'response' in e) {
        const {response} = e as {response?: {status: number; data: string}};
        if (response) {
          errorMessage += ` Status code: ${response.status}. Message: ${response.data}`;
        }
      }
      setErrorMessage(errorMessage);
    }
  };

  const getIcon = (
    recordStatus: string,
    paymentTypeID: number,
  ): keyof typeof Icons => {
    if (recordStatus === 'OverCharged' || recordStatus === 'Pending') {
      return 'Warning';
    }
    return getSimplePaymentIcon(paymentTypeID, paymentTenders);
  };

  const getMessage = (recordStatus: string): string => {
    if (recordStatus === 'OverCharged') {
      return 'This payment was approved for more than the requested amount. Please return this payment and try again.';
    } else if (recordStatus === 'Pending') {
      return 'This payment could not be verified. Please verify the status of the payment.';
    } else {
      return '';
    }
  };

  const getPaymentTypeDesc = (
    paymentTypeId: number,
    paymentTypeDesc: string,
    paymentTenders: PaymentType[],
  ): string => {
    if (!paymentTypeDesc && paymentTenders) {
      const paymentTenderDescription = paymentTenders.find(
        x => x.id === paymentTypeId,
      )?.description;
      return paymentTenderDescription != undefined
        ? paymentTenderDescription
        : ' ';
    } else {
      return paymentTypeDesc;
    }
  };

  return (
    <>
      {payment && (
        <Stack style={{height: '100%'}}>
          <Header title="Payment Info" />

          {['OverCharged', 'Pending'].includes(payment.recordStatus) && (
            <AlertMessage
              icon="Warning"
              message={getMessage(payment.recordStatus)}
              variant="warning"
            />
          )}
          {errorMessage && (
            <AlertMessage
              icon="Warning"
              message={errorMessage}
              variant="error"
            />
          )}
          <Stack
            style={{
              flex: 1,
            }}
            justify="center"
            align="center"
            gutter={Gutter.L}
          >
            <PaymentCard
              icon={getIcon(payment.recordStatus, payment.paymentTypeID || 0)}
              color={
                payment.recordStatus !== 'OverCharged'
                  ? getPaymentIconType(
                      payment.paymentTypeID || 0,
                      paymentTenders,
                    )
                  : 'warning'
              }
              title={
                `${
                  getPaymentType(payment.paymentTypeID || 0, paymentTenders) ==
                  'UDP'
                    ? getPaymentTypeDesc(
                        payment.paymentTypeID || 0,
                        payment.paymentTypeDesc || '',
                        paymentTenders,
                      )
                    : getPaymentType(payment.paymentTypeID || 0, paymentTenders)
                }: $${payment.amount.toFixed(2)}` + ' '
              }
              subtitle={displayPaymentNumber(payment, paymentTenders)}
              dateTime={moment(payment.createdOn).format('MM/DD/YYYY - hh:mmA')}
            />
          </Stack>
          <FooterGroup>
            <Button
              flex
              variant={BV.Secondary}
              disabled={['OverCharged', 'Pending'].includes(
                payment.recordStatus,
              )}
              onClick={onCancel}
            >
              Back
            </Button>
            <Button
              flex
              variant={
                payment.recordStatus === 'Pending' ? BV.Primary : BV.Danger
              }
              disabled={
                !onVoid || !['Active', 'Pending'].includes(payment.recordStatus)
              }
              onClick={() =>
                payment.recordStatus === 'Pending'
                  ? onGetDetailsTransaction()
                  : setModal(M.Confirm)
              }
            >
              {payment.recordStatus === 'Pending'
                ? 'Check Status'
                : 'Return Payment'}
            </Button>
          </FooterGroup>
        </Stack>
      )}

      <Modal
        visible={modal === M.Confirm}
        icon="Warning"
        color="error"
        onCancel={onVoidCancel}
        onContinue={onVoidPayment}
        buttonText="Return"
        title="Return Payment"
        subtitle="This cannot be undone. Are you sure you want to return this payment?"
      />
      <Modal
        visible={modal === M.Error}
        icon="Warning"
        color="error"
        onCancel={onVoidCancel}
        onContinue={onVoidPayment}
        buttonText="Retry"
        title="Return Payment Failed"
        subtitle="Return payment has failed. Reversal was not able to be processed."
      />
      <Modal
        visible={status === DeviceStatus.PaymentErrorUnknown}
        icon="Warning"
        color="error"
        onContinue={onVoidPayment}
        buttonText="Okay"
        title="Payment Failed"
        subtitle="Payment has failed. Please try again."
      />

      <HandleTransactionResponse
        onCancel={onCancel}
        onContinue={onCancel}
        transaction={transaction}
        creditCardPendingPaymentExpirationMinutes={
          creditCardPendingPaymentExpirationMinutes
        }
        updateTransaction={updateTransaction}
        paymentTenders={paymentTenders}
        status={status}
        setStatus={setStatus}
        ref={handleRef}
        setSelectedPayment={setSelectedPayment}
        navigate={navigate}
      />
    </>
  );
}

export function PaymentInfoPage(
  props: PropsWithChildren<WithPageProps<IntegrationProps>>,
): JSX.Element {
  return <PaymentInfoIntegration {...props} />;
}
