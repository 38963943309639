import * as SDCBarcode from 'scandit-web-datacapture-barcode';

export const UPCA_WITH_CHECK_DIGIT_LENGTH = 12;

export const formatBarcode = (scanResult: SDCBarcode.Barcode): string => {
  if (scanResult.data) {
    const needsToRemoveCheckDigit =
      [SDCBarcode.Symbology.EAN13UPCA, SDCBarcode.Symbology.UPCE].includes(
        scanResult.symbology,
      ) && scanResult.data.length === UPCA_WITH_CHECK_DIGIT_LENGTH;

    return needsToRemoveCheckDigit
      ? scanResult.data.substring(0, scanResult.data.length - 1)
      : scanResult.data;
  }
  return '';
};
