import {Illustration, IllustrationSize, Stack} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import {memo, useEffect} from 'react';
import {useTransactionsState} from '../../';

const HomeComponent = (_: RouteComponentProps): JSX.Element => {
  const {setCurrentTransactionId} = useTransactionsState();

  useEffect(() => setCurrentTransactionId(''), []);

  return (
    <Stack
      align="center"
      justify="center"
      style={{height: 'calc(calc(var(--vh, 1vh) * 100 - 72px)'}}
    >
      <Illustration
        illustration="LargeNothingHere"
        size={IllustrationSize.Large}
      />
    </Stack>
  );
};

export const Home = memo(HomeComponent);
