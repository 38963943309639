import {trimEnd, trimStart} from 'lodash';
import {CompleteBarcodeComponent, Symbology} from '../..';
import {
  BarcodeParsedResult,
  DELIMITER,
  PREFIX,
  RX_NUMBER,
  payorPlanNames,
} from '../IPMSStrategy';
import {BaseStrategy} from '../BaseStrategy';
export class EpicStrategy extends BaseStrategy {
  private DEFAULT_DELIMITER = '^';
  private StandardAztecEPIC = 'StandardAztecEPIC';
  validSymbologies = [Symbology.Aztec];

  validate(
    components: CompleteBarcodeComponent[],
    symbology: Symbology | null,
  ): boolean {
    return (
      components.length > 0 &&
      symbology !== null &&
      this.validSymbologies.includes(symbology)
    );
  }

  getDelimiter(
    components: CompleteBarcodeComponent[],
    rawBarcode: string,
  ): string {
    const delimiter = components.find(
      c => c.componentName.toLowerCase() === DELIMITER,
    )?.componentMask;

    return delimiter !== undefined && rawBarcode.includes(delimiter)
      ? delimiter.trim()
      : this.DEFAULT_DELIMITER;
  }

  generateRegexPattern(
    componentsCopy: CompleteBarcodeComponent[],
    componentNames: string[],
    delimiter: string | undefined,
  ): string {
    let regexPattern = '';

    componentsCopy.forEach(component => {
      if (
        component.componentIndex > 0 &&
        component.componentName &&
        component.componentMask
      ) {
        const name = component.componentName.toLowerCase();
        componentNames.push(name);

        const mask = trimEnd(trimStart(component.componentMask, '^'), '$');

        if (
          name === PREFIX ||
          (name === RX_NUMBER &&
            component.barcodeName === this.StandardAztecEPIC)
        ) {
          regexPattern = regexPattern + '(' + mask + ')';
        } else if (payorPlanNames.has(name)) {
          regexPattern = regexPattern + '(\\' + delimiter + mask + ')?';
        } else {
          regexPattern = regexPattern + '(\\' + delimiter + mask + ')';
        }
      }
    });

    // Used to remove invalid regex patterns from epic barcode components
    regexPattern = regexPattern.replace(/(\(\?\i)\)|(\(\?\-\i)\)/g, '');

    return regexPattern;
  }

  getComponentsValues(
    componentValues: string[],
    matches: RegExpMatchArray,
    delimiter: string,
  ): void {
    for (let index = 1; index < matches.length; index++) {
      const match = matches[index];
      if (match) {
        componentValues.push(match.replace(delimiter, ''));
      }
    }
  }

  reduceComponents(
    parsedResult: BarcodeParsedResult,
    componentNames: string[],
    componentValues: string[],
  ): BarcodeParsedResult {
    if (componentNames.indexOf('partial') === -1) parsedResult.partial = '';

    return componentNames.reduce((accumulator, name, index) => {
      const value =
        name && payorPlanNames.has(name) ? '' : componentValues[index];
      switch (name) {
        case 'rxnumber':
          accumulator.rx = value;
          return accumulator;
        case 'fillid':
          accumulator.fill = value;
          return accumulator;
        case 'partial':
          accumulator.partial = value;
          return accumulator;
        case 'ncpdpidnumber':
          accumulator.site = value;
          return accumulator;
        default:
          return accumulator;
      }
    }, parsedResult);
  }
}
