import {Transaction} from '@emporos/api-enterprise';
import {ITEM_TYPE_IDS} from '../../../../../constants';

// Define interface for ID check indicators
export interface IdCheckIndicators {
  isNplex: boolean;
  isControlledSubstance: boolean;
  isControlledOTC: boolean;
  isRequiredAge: boolean;
  age?: number;
}

// Function to map ID check indicators
export const mapIdCheckIndicators = ({
  items,
}: Transaction): IdCheckIndicators => {
  return {
    isNplex: items.some(
      ({itemTypeID, rx}) => !rx && itemTypeID === ITEM_TYPE_IDS.METH_FREE,
    ),
    isControlledSubstance: items.some(
      ({proofIDIndicator, rx}) => rx && proofIDIndicator,
    ),
    isControlledOTC: items.some(
      ({proofIDIndicator, rx}) => !rx && proofIDIndicator,
    ),
    isRequiredAge: items.some(
      ({requiredAge, rx}) => !rx && (requiredAge || 0) > 0,
    ),
    age:
      [...items]
        .map(({requiredAge}) => requiredAge || 0)
        .sort((a, b) => b - a)
        .shift() || 0,
  };
};
