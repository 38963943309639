export const DeviceBusy = 'Device is currently busy.';
export const DeviceUnreachable = 'Unable to reach device.';
export const DeviceNotReady = 'Make sure device is on the idle screen.';
export const UnableToCreateOrder = 'Unable to create an order at this time.';

export const InvalidConfiguration = 'There is an invalid device configuration.';
export const VantivUnknownFailure = 'There was a failure connecting to Vantiv.';

export const UnableToReturnOrder = 'Unable to return the transaction.';

export const UnableToMakeVaultPayment =
  'There is an error trying to pay with this saved card.';

export const UnableToCancelTransaction =
  'We were denied canceling the transaction by the payment processor.';

export const CayanProxyAuthFailure =
  'There was a failure validating session with Emporos.';

// this is used for any place where we don’t yet have proper error handling.
// usually because the error is coming from another system that has undefined
// error handling
export const TODO_CayanUnknownFailure =
  'There was a failure performing this action.';
