/**
 * The <VerificationModal /> provides a UX flow where a customer is requied to
 * enter their birthday to confirm it is them.
 */
import React, {PropsWithoutRef, memo, useState} from 'react';
import {isSame, isValid} from '@emporos/pos/src/utils/dates';
import {NormalizedCustomer} from '@emporos/pos/src/utils/customer';
import {yupResolver} from '@hookform/resolvers/yup';
import moment from 'moment';
import {Controller, useForm} from 'react-hook-form';
import {ObjectSchema, AnyObject} from 'yup';
import * as yup from 'yup';
import {Modal, TextInput} from './';

interface CustomerDOB {
  dob: string;
}

interface Props {
  customerInfo: NormalizedCustomer;
  visible: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

const dobSchema = (): ObjectSchema<AnyObject, AnyObject> => {
  const dob = yup
    .string()
    .test(
      'dob',
      'Invalid dob',
      _dateOfBirth => !!_dateOfBirth && isValid(_dateOfBirth),
    );

  return yup.object().shape({
    dob,
  });
};

export const VerificationModal = memo(
  ({
    customerInfo,
    visible,
    onCancel,
    onContinue,
  }: Props & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const [dobError, setDobError] = useState(false);

    const schema = dobSchema();
    const {register, watch, control, formState} = useForm<CustomerDOB>({
      mode: 'onChange',
      resolver: yupResolver(schema),
    });
    const birthdayInput = watch('dob');

    return (
      <Modal
        buttonText="Verify"
        data-testid="Verification__Modal"
        disableSubmit={
          !formState.isDirty || !formState.isValid || !!formState.errors.dob
        }
        icon="IdCard"
        color="primary"
        subtitle={`Please verify DOB for ${customerInfo.firstName} ${customerInfo.lastName}.`}
        title="Verification needed to continue."
        visible={visible}
        onCancel={() => {
          setDobError(false);
          onCancel && onCancel();
        }}
        onContinue={() => {
          setDobError(false);
          if (
            isSame(
              birthdayInput,
              moment.utc(customerInfo.dob).format('MM/DD/YYYY'),
            )
          ) {
            onContinue();
          } else {
            setDobError(true);
          }
        }}
      >
        <Controller
          name="dob"
          control={control}
          defaultValue=""
          render={({field}) => (
            <TextInput
              {...register('dob')}
              style={{margin: '24px 0 0 0'}}
              autoFocus
              label="DOB"
              mask="99/99/9999"
              name="dob"
              data-testid="dob"
              isError={dobError}
              onChange={e => {
                if (dobError) {
                  setDobError(false);
                }
                field.onChange(e.target.value);
              }}
              value={field.value}
              inputMode="numeric"
            />
          )}
        />
      </Modal>
    );
  },
);
