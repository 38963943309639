import {Table} from 'dexie';
import {TransactionPaymentConsolidate} from '../api';
import {HiloDb} from './dbcontext';
import {ITransactionPaymentsDatabaseAccess} from './IDatabaseAccess';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';

// instantiate with DIFactory.getTransactionDatabaseAccess
export class TransactionPaymentLocaldb
  implements ITransactionPaymentsDatabaseAccess
{
  db!: HiloDb;
  table!: Table<TransactionPaymentConsolidate>;
  private _sessionId!: string;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();

  initialize = (sessionId: string): void => {
    this._sessionId = sessionId;
    this.db = new HiloDb(this._sessionId);
    this.table = this.db.TransactionPaymetLocaldb;
  };

  async getAll(
    transactionId: string,
  ): Promise<TransactionPaymentConsolidate[]> {
    return this.table
      .where({sessionId: this._sessionId, transactionId: transactionId})
      .toArray();
  }

  async get(
    transactionPaymentId: string,
  ): Promise<TransactionPaymentConsolidate> {
    const transactionPaymentresult = await this.table.get(transactionPaymentId);

    if (transactionPaymentresult) {
      return transactionPaymentresult;
    } else {
      return {} as TransactionPaymentConsolidate;
    }
  }

  async add(
    transactionPayment: TransactionPaymentConsolidate,
  ): Promise<TransactionPaymentConsolidate> {
    await this.table.put(transactionPayment);

    return transactionPayment;
  }

  async update(
    transactionPayment: TransactionPaymentConsolidate,
  ): Promise<TransactionPaymentConsolidate> {
    await this.table.put(transactionPayment);

    return transactionPayment;
  }

  async delete(transactionPaymentId: string): Promise<void> {
    return this.table.delete(transactionPaymentId);
  }

  async deleteAll(): Promise<void> {
    this._consoleLogger.styledLog(
      'Delete All Transaction Payments',
      ConsoleLoggerVariant.PURPLE,
    );
    return this.table.clear();
  }
}
