import {Customer} from '@emporos/api-enterprise';
import {useCallback, useState} from 'react';
import {
  activeTransactionCustomersCodes,
  AnalyticType,
  Transaction,
  OfflineTransaction,
  OfflineSynced,
  useAlertState,
  useAnalyticsProvider,
  useTransaction,
  useSession,
} from '../';

function transactionHasOtherCustomer(
  customer: Customer,
  transaction: Transaction,
) {
  return (
    transaction &&
    transaction.customer &&
    transaction.customer.id !== customer.id
  );
}

function customerIsActive(customer: Customer, activeCustomers: string[]) {
  return (
    Boolean(activeCustomers?.length) &&
    activeCustomers.some(customerCode => customerCode === customer.code)
  );
}

type UsePendingTransactionHook = {
  transaction: Transaction;
  transactionChanged: boolean;
  commit(changes?: Partial<OfflineTransaction>): void;
  tryAttachCustomer(customer: Customer | null): void;
};

export function usePendingTransaction(): UsePendingTransactionHook {
  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();

  const {transaction, updateTransaction} = useTransaction();
  const {pendingTransactions} = useSession();
  const [pendingTransaction, setPendingTransaction] = useState({
    ...transaction,
  });
  const tryAttachCustomer = useCallback(
    (customer: Customer | null) => {
      if (!customer) {
        return;
      }

      if (customer && customer.id === transaction.customerId) {
        setPendingTransaction({
          ...transaction,
          customer,
          customerId: customer.id,
        });
        return;
      }

      if (transactionHasOtherCustomer(customer, transaction)) {
        notification({
          title: "Customer Doesn't Match",
          description:
            'This prescription doesn’t match the customer attached to the sale.',
          type: 'error',
          icon: 'X',
        });
        track(AnalyticType.UserError, {
          message:
            'User scanned prescription that does not match the customer attached to the sale.',
        });
        return;
      }

      if (
        customerIsActive(
          customer,
          activeTransactionCustomersCodes(pendingTransactions),
        )
      ) {
        notification({
          title: 'Customer Already Active',
          description: 'This customer is already attached to a transaction.',
          type: 'warning',
          icon: 'Warning',
        });
        track(AnalyticType.UserError, {
          message: 'User attempted to add an active customer to another sale.',
        });
        return;
      }

      setPendingTransaction({
        ...transaction,
        customer,
        customerId: customer.id,
      });
    },
    [transaction],
  );

  const transactionChanged =
    transaction.customer?.id !== pendingTransaction.customer?.id;

  const commit = useCallback(
    (changes?: Partial<Transaction>) => {
      const nextTransaction = {
        ...pendingTransaction,
        ...changes,
        dataVersion: transaction.dataVersion,
      };
      updateTransaction(prevTransaction => ({
        ...nextTransaction,
        isSynced: false,
        items: prevTransaction.items
          .filter(item => (item as OfflineSynced).isDeleted)
          .concat(nextTransaction.items),
        payments: prevTransaction.payments,
      }));
      setPendingTransaction(nextTransaction);
    },
    [transaction, pendingTransaction, updateTransaction],
  );

  return {
    transaction: pendingTransaction,
    transactionChanged,
    commit,
    tryAttachCustomer,
  };
}
