import assert from 'assert';
import {
  createSignal,
  Signal,
  StringMatcherPattern,
  StringMatch,
  StringMatcher,
} from '../utils';

// Duration to wait for a new character input from keyboard wedge firmware.
export const SCAN_KEY_DEBOUNCE_MS = 75;
// Data attribute used to identify the hidden input that collects keyboard wedge input.
export const HIDDEN_INPUT_DATA_ATTR = 'data-keyboard-barcode-reader';
export const HIDDEN_INPUT_DATA_ATTR_SELECTOR = `[${HIDDEN_INPUT_DATA_ATTR}]`;

export enum Symbology {
  PDF417 = 'PDF417',
  MicroPDF417 = 'MicroPDF417',
  Code128 = 'Code128',
  Code39 = 'Code39',
  EAN13UPCA = 'EAN13UPCA',
  EAN8 = 'EAN8',
  // Honeywell readers seem to upgrade UPC-E to UPC-A
  UPCE = 'UPCE',
  QR = 'QR',
  // Honeywell readers seem to upgrade MicroQR to QR
  MicroQR = 'MicroQR',
  Aztec = 'Aztec',
}

/**
 * A barcode prefix.
 */
export type Prefix = StringMatcherPattern & {
  /**
   * An optional callback that calculates a symbology from the prefix resolved
   * by the scanner service. Used to attach data to the scan result.
   */
  getSymbology?: (prefix: string) => Symbology | null;
};

export type Suffix = StringMatcherPattern;

/**
 * A handheld scanner scan result.
 */
export type ScanResult = {
  /**
   * The raw barcode data, stripped of any prefix that may have preceded it in
   * a scan.
   */
  raw: string;

  /**
   * The prefix that triggered the scan.
   */
  prefix: string | null;

  /**
   * The suffix, if any, that terminated the scan.
   */
  suffix: string | null;

  /**
   * A symbology, if any, associated with the barcode, derived from the prefix
   * type.
   */
  symbology: Symbology | null;
};

/**
 * A default set of barcode prefixes.
 */
export const prefixes = {
  /**
   * Unique, three character identifiers associated with a barcode symbology.
   * AIM codes are often prefixed to the beginning of a barcode result by
   * barcode scanners.
   */
  AIM_CODE: {
    pattern: /^\][a-zA-Z]\d$/,
    length: 3,
    getSymbology: aimCodeToBarcodeSymbology,
  } as Prefix,
};

/**
 * A default set of barcode suffixes.
 */
export const suffixes = {
  CARRIAGE_RETURN: {
    pattern: /^Enter$/,
    length: 5,
    getSymbology: aimCodeToBarcodeSymbology,
  } as Prefix,
};

type KeyboardScannerServiceOptions = {
  prefixes?: Prefix[];
  suffixes?: Suffix[];
};

/**
 * An object used to detect barcode scans via keyboard wedge.
 */
export interface KeyboardScannerReader {
  /**
   * A signal dispatched when a prefix is detected and a scan begins.
   */
  scanStarted: Signal;

  /**
   * A signal dispatched when a scan is complete.
   */
  scanFinished: Signal<ScanResult>;

  /**
   * Start listening for scans.
   */
  start: () => void;

  /**
   * Stop listening for scans.
   */
  stop: () => void;
}

export const barcodeSymbologyByAimCode: Record<string, Symbology> = {
  ']C0': Symbology.Code128,
  ']C1': Symbology.Code128,
  ']C2': Symbology.Code128,
  ']C4': Symbology.Code128,
  ']E0': Symbology.EAN13UPCA,
  ']E1': Symbology.EAN13UPCA,
  ']E2': Symbology.EAN13UPCA,
  ']E3': Symbology.EAN13UPCA,
  ']E4': Symbology.EAN8,
  ']L0': Symbology.PDF417,
  ']L1': Symbology.PDF417,
  ']L2': Symbology.PDF417,
  ']L3': Symbology.PDF417,
  ']L4': Symbology.PDF417,
  ']L5': Symbology.PDF417,
  ']Q0': Symbology.QR,
  ']Q1': Symbology.QR,
  ']Q2': Symbology.QR,
  ']Q3': Symbology.QR,
  ']Q4': Symbology.QR,
  ']Q5': Symbology.QR,
  ']Q6': Symbology.QR,
  ']z0': Symbology.Aztec,
  ']z1': Symbology.Aztec,
  ']z2': Symbology.Aztec,
  ']z3': Symbology.Aztec,
  ']z4': Symbology.Aztec,
  ']z5': Symbology.Aztec,
  ']z6': Symbology.Aztec,
  ']z7': Symbology.Aztec,
  ']z8': Symbology.Aztec,
  ']z9': Symbology.Aztec,
  ']zA': Symbology.Aztec,
  ']zB': Symbology.Aztec,
  ']zC': Symbology.Aztec,
};

export function aimCodeToBarcodeSymbology(aimCode: string): Symbology | null {
  return aimCode in barcodeSymbologyByAimCode
    ? barcodeSymbologyByAimCode[aimCode]
    : null;
}

export enum KeyboardScannerServiceStatus {
  Idle = 'idle',
  Listening = 'listening',
  Reading = 'reading',
  Finished = 'finished',
}

export type KeyboardScannerServiceState =
  // Idle: start() has not been called, or stop() has been called from the
  // listening or finished state.
  | {
      status: KeyboardScannerServiceStatus.Idle;
    }
  // Listening: start() has been called and the service is listening for scan
  // prefixes.
  | {
      status: KeyboardScannerServiceStatus.Listening;
      matcher: StringMatcher<Prefix>;
    }
  // Reading: a prefix has been detected and the service is listening for scan
  // data in the form of keyboard events.
  | {
      status: KeyboardScannerServiceStatus.Reading;
      matcher: StringMatcher<Suffix>;
      prefix: StringMatch<Prefix>;
      raw: string;
      timer: number | NodeJS.Timeout;
    }
  // Finished: the scan has finished, determined after a set amount of time has
  // passed after the final keyboard event.
  | {
      status: KeyboardScannerServiceStatus.Finished;
      prefix: StringMatch<Prefix>;
      raw: string;
      suffix: StringMatch<Suffix> | null;
      timer: number | NodeJS.Timeout;
    };

function getHiddenInput(): HTMLInputElement {
  const hiddenInput = document.querySelector(HIDDEN_INPUT_DATA_ATTR_SELECTOR);

  if (!hiddenInput) {
    throw new Error('Hidden input not found in document.');
  }

  return hiddenInput as HTMLInputElement;
}
export class KeyboardScannerService implements KeyboardScannerReader {
  private prefixes: Prefix[] = [prefixes.AIM_CODE];
  private suffixes: Suffix[] = [suffixes.CARRIAGE_RETURN];
  private state: KeyboardScannerServiceState = {
    status: KeyboardScannerServiceStatus.Idle,
  };

  readonly scanStarted = createSignal();
  readonly scanFinished = createSignal<ScanResult>();

  constructor(options: KeyboardScannerServiceOptions = {}) {
    this.prefixes = options.prefixes || this.prefixes;
    this.suffixes = options.suffixes || this.suffixes;
  }

  private onPrefix = (event: KeyboardEvent) => {
    assert(this.state.status === KeyboardScannerServiceStatus.Listening);

    const {key} = event;
    const {done, value} = this.state.matcher.next(key);

    if (!done) {
      return;
    }

    if (value) {
      // Found a prefix!
      this.setState({
        status: KeyboardScannerServiceStatus.Reading,
        prefix: value,
        matcher: new StringMatcher({
          patterns: this.suffixes,
        }),
        raw: '',
        timer: -1,
      });
      // Prevent the last character of the prefix from being entered into the
      // hidden input since we insert the input synchronously.
      event.preventDefault();
    } else {
      // Reset to initial listening state.
      this.start();
    }
  };

  private onRead = (event: KeyboardEvent) => {
    const hiddenInput = event.target as HTMLInputElement;

    assert(this.state.status === KeyboardScannerServiceStatus.Reading);

    const {key} = event;
    const {done, value} = this.state.matcher.next(key);

    let raw = hiddenInput.value;

    if (done && value) {
      raw = raw.replace(new RegExp(`${value.match}$`), '');
    }

    this.setState(
      done && value
        ? // A suffix was identified so we can finish the scan.
          {
            ...this.state,
            raw,
            status: KeyboardScannerServiceStatus.Finished,
            suffix: value || null,
          }
        : // Store updated barcode value in state.
          {
            ...this.state,
            raw,
          },
    );
  };

  private setState(state: KeyboardScannerServiceState) {
    const {status: from} = this.state;
    const {status: to} = state;

    // Validate state transitions.
    switch (to) {
      case KeyboardScannerServiceStatus.Idle:
        assert(from === KeyboardScannerServiceStatus.Listening);
        break;
      case KeyboardScannerServiceStatus.Listening:
        assert(
          from === KeyboardScannerServiceStatus.Idle ||
            from === KeyboardScannerServiceStatus.Listening ||
            from === KeyboardScannerServiceStatus.Finished,
        );
        break;
      case KeyboardScannerServiceStatus.Reading:
        assert(
          from === KeyboardScannerServiceStatus.Listening ||
            from === KeyboardScannerServiceStatus.Reading,
        );
        break;
      case KeyboardScannerServiceStatus.Finished:
        assert(from === KeyboardScannerServiceStatus.Reading);
        break;
      default:
        throw new Error(
          `[KeyboardScannerService] Failed to transition to state ${to} – not a valid state`,
        );
    }

    this.state = state;

    // Perform side-effects.
    switch (this.state.status) {
      case KeyboardScannerServiceStatus.Idle:
        // Since we can transition to the idle state from listening, make sure
        // to stop listening for prefix events.
        document.removeEventListener('keypress', this.onPrefix);
        break;
      case KeyboardScannerServiceStatus.Listening:
        document.addEventListener('keypress', this.onPrefix);
        break;
      case KeyboardScannerServiceStatus.Reading:
        document.removeEventListener('keypress', this.onPrefix);

        // Start a timer used to determine the end of a scan.
        this.setupReadTimer();

        // Only create the hidden input and dispatch the scanStarted signal if
        // transitioning from reading->listening, since listening->listening is
        // a valid transition.
        if (from === KeyboardScannerServiceStatus.Listening) {
          this.setupHiddenInput();
          this.scanStarted.dispatch();
        }
        break;
      case KeyboardScannerServiceStatus.Finished:
        clearTimeout(this.state.timer as number);
        this.teardownHiddenInput();
        this.scanFinished.dispatch({
          prefix: this.state.prefix.match,
          raw: this.state.raw,
          suffix: this.state.suffix?.match || null,
          symbology:
            this.state.prefix.pattern.getSymbology?.(this.state.prefix.match) ||
            null,
        });
        // Transition back to the listening state.
        this.start();
        break;
    }
  }

  private setupReadTimer() {
    assert(this.state.status === KeyboardScannerServiceStatus.Reading);

    clearTimeout(this.state.timer as number);

    this.state.timer = setTimeout(() => {
      assert(this.state.status === KeyboardScannerServiceStatus.Reading);

      this.setState({
        ...this.state,
        raw: getHiddenInput().value,
        status: KeyboardScannerServiceStatus.Finished,
        suffix: null,
      });
    }, SCAN_KEY_DEBOUNCE_MS);
  }

  private setupHiddenInput() {
    const hiddenInput = document.createElement('input');

    hiddenInput.type = 'text';
    hiddenInput.style.opacity = '0';
    hiddenInput.style.pointerEvents = 'none';
    hiddenInput.style.position = 'fixed';
    hiddenInput.setAttribute(HIDDEN_INPUT_DATA_ATTR, 'true');
    hiddenInput.addEventListener('keydown', this.onRead);

    document.body.appendChild(hiddenInput);

    // We use a 0 second timeout to avoid immediately focusing during the
    // keypress event of the final prefix character.
    hiddenInput.focus();
  }

  private teardownHiddenInput() {
    document.body.removeChild(getHiddenInput());
  }

  start(): void {
    this.setState({
      status: KeyboardScannerServiceStatus.Listening,
      matcher: new StringMatcher({
        patterns: this.prefixes,
      }),
    });
  }

  stop(): void {
    this.setState({
      status: KeyboardScannerServiceStatus.Idle,
    });
  }
}
