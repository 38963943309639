import {
  Transaction,
  TransactionPayment,
  PaymentType,
} from '@emporos/api-enterprise';
import {Modal} from '@emporos/components';
import {NavigateFn} from '@reach/router';
import moment from 'moment';
import {
  Dispatch,
  forwardRef,
  memo,
  SetStateAction,
  useImperativeHandle,
  useState,
} from 'react';
import {
  getPaymentTypeIdByPaymentType,
  mapTransactionPayment,
  UpdateTransactionFn,
} from '../../../../../';
import {
  DeviceStatus,
  HandleTransactionResponseRef,
  TransactionResponse,
} from './';

interface Props {
  paymentTenders: PaymentType[];
  status: DeviceStatus;
  setStatus: Dispatch<SetStateAction<DeviceStatus>>;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  transaction: Transaction;
  creditCardPendingPaymentExpirationMinutes: number;
  updateTransaction: UpdateTransactionFn;
  onCancel: () => void;
  onContinue: () => void;
  navigate: NavigateFn;
}

const HandleTransactionResponseComponent = forwardRef<
  HandleTransactionResponseRef,
  Props
>(function HandleTransactionResponseFn(
  {
    paymentTenders,
    status,
    creditCardPendingPaymentExpirationMinutes,
    setStatus,
    setSelectedPayment,
    transaction,
    updateTransaction,
    onCancel,
    onContinue,
    navigate,
  }: Props,
  ref,
): JSX.Element {
  const [partialAmount, setPartialAmount] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useImperativeHandle(ref, () => ({
    handleTransactionResponse,
  }));

  const handleOnCancel = () => {
    onCancel();
    setErrorMessage(undefined);
  };

  const handleOnContinue = () => {
    onContinue();
    setErrorMessage(undefined);
  };

  const handleTransactionResponse = (
    result: TransactionResponse,
    pendingPayment?: TransactionPayment,
  ) => {
    switch (result.status) {
      case 'APPROVED': {
        setStatus(DeviceStatus.PaymentSuccess);
        const updates = resultToPartialTransactionPayment(result, {
          recordStatus: 'Active',
        });
        if (pendingPayment) {
          updatePayment(pendingPayment.transactionPaymentId, updates);
        } else {
          addPayment(updates);
        }
        break;
      }
      case 'PARTIAL APPROVED': {
        setStatus(DeviceStatus.PartialPaymentSuccess);
        const updates = resultToPartialTransactionPayment(result, {
          recordStatus: 'Active',
        });
        if (pendingPayment) {
          updatePayment(pendingPayment.transactionPaymentId, updates);
        } else {
          addPayment(updates);
        }
        if (updates.amount) {
          setPartialAmount(updates.amount);
        }
        break;
      }
      case 'OVERCHARGED': {
        setStatus(DeviceStatus.PaymentWarning);
        const updates = resultToPartialTransactionPayment(result, {
          recordStatus: 'OverCharged',
        });
        if (pendingPayment) {
          updatePayment(pendingPayment.transactionPaymentId, updates);
          setSelectedPayment(pendingPayment.transactionPaymentId);
        } else {
          const newPayment = addPayment(updates);
          setSelectedPayment(newPayment.transactionPaymentId);
        }
        return navigate('payment-info');
      }
      case 'FAILED':
        {
          if (!pendingPayment) break;
          if (
            (result.errorMessage as string)?.toLowerCase().includes('timeout')
          ) {
            if (
              moment(pendingPayment.createdOn)
                .add(creditCardPendingPaymentExpirationMinutes, 'minutes')
                .isAfter(moment())
            ) {
              setStatus(DeviceStatus.PaymentProcessing);
              return navigate(
                '/sales/transactions/payments/customer-payment/payment-info',
                {
                  replace: true,
                },
              );
            } else {
              setStatus(DeviceStatus.Timeout);
            }
          } else {
            setStatus(DeviceStatus.PaymentError);
          }
          deletePendingPayment(pendingPayment.transactionPaymentId);
        }
        break;
      default:
        if (/declined|duplicate/gi.test(result.status)) {
          setStatus(DeviceStatus.PaymentDeclined);
        } else if (/cancelled/gi.test(result.status)) {
          setStatus(DeviceStatus.UserCancelled);
        } else if (/expired/gi.test(result.status)) {
          setStatus(DeviceStatus.PaymentExpired);
        } else {
          setStatus(DeviceStatus.PaymentError);
        }
        if (pendingPayment) {
          deletePendingPayment(pendingPayment.transactionPaymentId);
        }
        break;
    }

    if (result.errorMessage) setErrorMessage(result.errorMessage);
    else setErrorMessage(undefined);
  };

  const resultToPartialTransactionPayment = (
    result: TransactionResponse,
    updates?: Partial<TransactionPayment>,
  ): Partial<TransactionPayment> => ({
    paymentNumber: result.accountNumber.substr(-4),
    amount: Number(result.amountApproved),
    gatewayPaymentID: result.transactionId,
    paymentTypeID: getPaymentTypeIdByPaymentType(
      result.paymentType,
      paymentTenders,
    ),
    jsonPaymentProcessorResponse: result.rawResponse,
    ...updates,
  });

  const addPayment = (updates: Partial<TransactionPayment>) => {
    const newPayment = mapTransactionPayment(
      transaction.transactionId,
      0,
      '',
      0,
      updates,
    );
    updateTransaction(prevTransaction => ({
      payments: prevTransaction.payments.concat(newPayment),
    }));
    return newPayment;
  };

  const updatePayment = (
    transactionPaymentId: string,
    updates: Partial<TransactionPayment>,
  ) => {
    updateTransaction(prevTransaction => ({
      payments: prevTransaction.payments.map(p => ({
        ...p,
        ...(p.transactionPaymentId === transactionPaymentId && {
          ...updates,
          isSynced: false,
        }),
      })),
    }));
  };

  const deletePendingPayment = (transactionPaymentId: string) =>
    updateTransaction(prevTransaction => ({
      payments: prevTransaction.payments.map(p => ({
        ...p,
        ...(p.transactionPaymentId === transactionPaymentId && {
          isSynced: false,
          isDeleted: true,
        }),
      })),
    }));

  return (
    <>
      <Modal
        visible={status === DeviceStatus.Connecting}
        data-testid="Modal__Connecting"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Connecting to Payment Device"
        subtitle="Establishing connection with the device. Please hold tight."
        errorMessage={errorMessage}
        buttonText="Cancel"
        onContinue={handleOnCancel}
      />
      <Modal
        visible={status === DeviceStatus.CheckStatus}
        data-testid="Modal__CheckStatus"
        icon="Spinner"
        color="primary"
        iconSpinning={true}
        title="Checking Payment Status"
        subtitle="Establishing connection with the gateway to verify status of the payment."
        errorMessage={errorMessage}
        buttonText="Cancel"
        onContinue={handleOnCancel}
      />
      <Modal
        visible={status === DeviceStatus.CancelTransactionFailed}
        data-testid="Modal__CancelTransactionFailed"
        icon="Spinner"
        color="warning"
        iconSpinning={true}
        title="Unable to cancel transaction at this stage."
        subtitle="Please finish payment on device."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__PaymentSuccess"
        visible={status === DeviceStatus.PaymentSuccess}
        icon="Checkmark"
        color="success"
        onContinue={handleOnContinue}
        buttonText="Okay"
        title="Payment Successful"
        subtitle="The card payment has been processed. Please remove card and give back to customer."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__PartialPaymentSuccess"
        visible={status === DeviceStatus.PartialPaymentSuccess}
        icon="Checkmark"
        color="success"
        onContinue={handleOnContinue}
        buttonText="Okay"
        title="Partial Payment Successful"
        subtitle={`A partial payment has been processed. $${partialAmount} was successfully applied.`}
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__PaymentOvercharged"
        visible={status === DeviceStatus.PaymentWarning}
        icon="Warning"
        color="warning"
        onContinue={handleOnContinue}
        buttonText="Okay"
        title="Payment Overcharged"
        subtitle="Payment was approved, but there was an overpayment. Please void the payment and try again."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__PaymentDeclined"
        visible={status === DeviceStatus.PaymentDeclined}
        icon="Warning"
        color="warning"
        onContinue={handleOnCancel}
        buttonText="Okay"
        title="Payment Declined"
        subtitle="Customer’s card has been declined. Please use another card or try again."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__PaymentExpired"
        visible={status === DeviceStatus.PaymentExpired}
        icon="Warning"
        color="warning"
        onContinue={handleOnCancel}
        buttonText="Okay"
        title="Expired Card"
        subtitle="Customer’s card has expired. Please use another card or try again."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__PaymentError"
        visible={status === DeviceStatus.PaymentError}
        icon="Warning"
        color="warning"
        onContinue={handleOnCancel}
        buttonText="Okay"
        title="Payment Error"
        subtitle="Transaction could not be processed. Please use another card or try again."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__PaymentProcessing"
        visible={status === DeviceStatus.PaymentProcessing}
        icon="Warning"
        color="warning"
        onContinue={() => setStatus(DeviceStatus.Unknown)}
        buttonText="Okay"
        title="Transaction is Processing"
        subtitle="Please wait for transaction to finalize and “Check Status” of the payment."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__AppCancelled"
        visible={status === DeviceStatus.AppCancelled}
        icon="Warning"
        color="warning"
        onContinue={handleOnCancel}
        buttonText="Okay"
        title="Payment Was Cancelled"
        subtitle="Payment request was cancelled."
        errorMessage={errorMessage}
      />
      <Modal
        data-testid="Modal__UserCancelled"
        visible={status === DeviceStatus.UserCancelled}
        icon="Warning"
        color="warning"
        onContinue={handleOnCancel}
        buttonText="Okay"
        title="Payment Was Cancelled"
        subtitle="Transaction was cancelled from payment device. Please try transaction again."
        errorMessage={errorMessage}
      />
    </>
  );
});

export const HandleTransactionResponse = memo(
  HandleTransactionResponseComponent,
);
