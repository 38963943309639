import {
  Transaction,
  PaymentType,
  Setting,
  TransactionPayment,
} from '@emporos/api-enterprise';
import {AcceptedPaymentTypes, getPaymentType} from '../';
import {ITEM_TYPE_IDS} from '../../../../../constants';

interface Acknowledgements {
  rxMessage?: string;
  arMessage?: string;
  pdMessage?: string;
  ccMessage?: string;
  sudafedMessage?: string;
  noSafetyCapMessage?: string;
}
function calculateAmount(
  paymentType: AcceptedPaymentTypes,
  payments: Transaction['payments'],
  paymentTenders: PaymentType[],
) {
  return (
    '$' +
    payments
      .filter(
        p =>
          p.paymentTypeID &&
          getPaymentType(p.paymentTypeID, paymentTenders) === paymentType,
      )
      .reduce((acc, p) => acc + p.amount, 0)
      .toFixed(2)
  );
}

const getPaymentMessage = (
  payments: TransactionPayment[],
  settingsResult: Setting[],
  paymentTenders: PaymentType[],
  paymentType: 'AR' | 'PD' | 'Credit Card',
) => {
  return (
    (payments.some(
      ({paymentTypeID}) =>
        paymentTypeID &&
        getPaymentType(paymentTypeID, paymentTenders) === paymentType,
    ) &&
      (
        settingsResult.find(
          s =>
            s.name ===
            (paymentType !== 'Credit Card'
              ? `${paymentType}Message`
              : 'CCMessage'),
        )?.value || ''
      ).replace(
        '[tender_amount]',
        calculateAmount(paymentType, payments, paymentTenders),
      )) ||
    undefined
  );
};

export const mapAcknowledgement = (
  {items, payments}: Transaction,
  settingsResult: Setting[],
  paymentTenders: PaymentType[],
): Acknowledgements => {
  return {
    rxMessage:
      (items.some(({rx}) => rx) &&
        settingsResult.find(({name}) => name === 'RXMessage')?.value) ||
      undefined,
    arMessage: getPaymentMessage(
      payments,
      settingsResult,
      paymentTenders,
      'AR',
    ),
    pdMessage: getPaymentMessage(
      payments,
      settingsResult,
      paymentTenders,
      'PD',
    ),
    ccMessage: getPaymentMessage(
      payments,
      settingsResult,
      paymentTenders,
      'Credit Card',
    ),
    sudafedMessage:
      (items.some(({itemTypeID}) => itemTypeID === ITEM_TYPE_IDS.METH_FREE) &&
        (
          settingsResult.find(s => s.name === 'SudafedMessage')?.value || ''
        ).replace('~True', '')) ||
      undefined,
    noSafetyCapMessage:
      (settingsResult.find(s => s.name === 'NoSafetyCapEnabled')?.value ===
        '1' &&
        items.some(
          ({noSafetyCapIndicator, rx}) => rx && noSafetyCapIndicator,
        ) &&
        settingsResult.find(s => s.name === 'NoSafetyCapMessage')?.value) ||
      undefined,
  };
};
