import {
  Size as BS,
  ButtonSize as BSS,
  Button,
  ButtonShape,
  Variant as BV,
  FooterGroup,
  Gutter,
  Header,
  Row,
  SegmentSlider,
  Stack,
  Text,
  TextVariant as TV,
} from '@emporos/components';
import Signature, {SignatureHandle} from '@emporos/components/src/Signature';
import {NavigateFn} from '@reach/router';
import {memo, useEffect, useRef, useState} from 'react';

const SIGNATURE_INDEX = 0;
const TOUCHLESS_INDEX = 1;
interface Props {
  onBack(): void;
  onAccept(
    imageData: string,
    pointData: SignaturePad.Point[][],
    isTouchless: boolean,
  ): void;
  navigate: NavigateFn;
}

function AcknowledgementsComponent({
  onBack,
  onAccept,
  navigate,
}: Props): JSX.Element {
  const [active, setActive] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const signatureRef = useRef<SignatureHandle>(null);

  useEffect(() => {
    if (active === SIGNATURE_INDEX) {
      signatureRef.current?.on();
    } else {
      signatureRef.current?.clear();
      signatureRef.current?.off();
      setIsEmpty(true);
    }
  }, [active]);

  // reset signature listener necessary for landscape/portrait switch
  // switching orientation clears the canvas but does not recent reference and state, thus the need for manual listener
  function resetSignature() {
    signatureRef.current?.clear();
    setIsEmpty(true);
  }

  useEffect(() => {
    window.addEventListener('resize', resetSignature);

    // cleanup this component
    return () => {
      window.addEventListener('resize', resetSignature);
    };
  }, []);

  return (
    <>
      <Stack>
        <Header title="Customer Signature">
          <ButtonShape
            size={BSS.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={() => navigate('./customer-info')}
          />
        </Header>

        <Stack
          gutter={Gutter.XXL}
          style={{flex: 1, marginTop: 20, overflow: 'auto'}}
        >
          <Stack
            gutter={Gutter.None}
            style={{position: 'relative', minHeight: 350}}
            align="center"
          >
            <Row style={{position: 'absolute', top: 24}}>
              <SegmentSlider
                onSelectIndex={setActive}
                active={active}
                items={['Signature', 'Touchless']}
              />
            </Row>
            {active === TOUCHLESS_INDEX && (
              <Row style={{position: 'absolute', top: '50%'}}>
                <Text variant={TV.Touchless}>Touchless Signature</Text>
              </Row>
            )}

            <Signature
              ref={signatureRef}
              onChange={() =>
                signatureRef.current &&
                setIsEmpty(signatureRef.current.isEmpty())
              }
            />

            <Button
              disabled={active === TOUCHLESS_INDEX || isEmpty}
              size={BS.Small}
              variant={BV.Secondary}
              style={{position: 'absolute', bottom: 24}}
              onClick={() => {
                signatureRef.current?.clear();
                setIsEmpty(true);
              }}
            >
              Clear Signature
            </Button>
          </Stack>

          <Text variant={TV.MainLight} align="center">
            By my signature, I accept all acknowledgements
          </Text>

          <Button
            variant={BV.Link}
            onClick={() => navigate('./info')}
            style={{marginTop: 0}}
          >
            View All Acknowledgements
          </Button>
        </Stack>

        <FooterGroup>
          <Button variant={BV.Secondary} flex onClick={onBack}>
            Back
          </Button>
          <Button
            disabled={active === SIGNATURE_INDEX && isEmpty}
            onClick={() => {
              onAccept(
                signatureRef.current?.getImage() || '',
                signatureRef.current?.getPoints() || [],
                active === TOUCHLESS_INDEX,
              );
            }}
            flex
            data-testid="accept-acknowledgement-button"
          >
            Accept
          </Button>
        </FooterGroup>
      </Stack>
    </>
  );
}

export const Acknowledgements = memo(AcknowledgementsComponent);
