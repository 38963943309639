import {Transaction, TransactionRequest} from '@emporos/api-enterprise';
import {ConvertTransactionExtrasToRequests} from './TransactionExtraConverter';
import {ConvertTransactionIdentificationToRequest} from './TransactionIdentificationConverter';
import {ConvertTransactionItemsToRequests} from './TransactionItemConverter';
import {ConvertTransactionSignaturesToRequests} from './TransactionSignatureConverter';
import {ConvertTransactionTaxesToRequests} from './TransactionTaxConverter';

export function ConvertTransactionsToRequests(
  transactions: Transaction[],
): TransactionRequest[] {
  const requests: TransactionRequest[] = [];
  transactions.forEach(transaction => {
    requests.push(ConvertTransactionToRequest(transaction));
  });
  return requests;
}

export function ConvertTransactionToRequest(
  transaction: Transaction,
): TransactionRequest {
  return {
    transactionId: transaction.transactionId,
    sessionId: transaction.sessionId,
    customerId: transaction.customerId,
    saleDate: transaction.saleDate,
    subTotal: transaction.subTotal,
    taxableSubTotal: transaction.taxableSubTotal,
    totalCost: transaction.totalCost,
    discount: transaction.discount,
    totalTax: transaction.totalTax,
    totalSale: transaction.totalSale,
    source: transaction.source,
    qhpRxQty: transaction.qhpRxQty,
    qhpRxAmount: transaction.qhpRxAmount,
    qhpOtherQty: transaction.qhpOtherQty,
    qhpOtherAmount: transaction.qhpOtherAmount,
    qhpCount: transaction.qhpCount,
    qhpAmount: transaction.qhpAmount,
    qhpPartial: transaction.qhpPartial,
    pseApprovedTransID: transaction.pseApprovedTransID,
    consumerVerifyID: transaction.consumerVerifyID,
    deliveryMethods: transaction.deliveryMethods,
    station: transaction.station,
    employeeID: transaction.employeeID,
    taxExemptIndicator: transaction.taxExemptIndicator,
    xFileIndicator: transaction.xFileIndicator,
    xFileOriginatingTransactionID: transaction.xFileOriginatingTransactionID,
    xFileTransactionID: transaction.xFileTransactionID,
    taxExemptNumber: transaction.taxExemptNumber,
    orderID: transaction.orderID,
    reference: transaction.reference,
    sourceID: transaction.sourceID,
    onHoldAtServerIndicator: transaction.onHoldAtServerIndicator,
    saleSiteID: transaction.saleSiteID,
    salesAuditDate: transaction.salesAuditDate,
    salesAuditIndicator: transaction.salesAuditIndicator,
    dispensingID: transaction.dispensingID,
    signatureStatus: transaction.signatureStatus,
    rxSigType: transaction.rxSigType,
    sudafedSigType: transaction.sudafedSigType,
    safetyCapsSigType: transaction.safetyCapsSigType,
    pmsStatus: transaction.pmsStatus,
    ageSource: transaction.ageSource,
    age: transaction.age,
    onDeliveryBypassCounselPrompt: transaction.onDeliveryBypassCounselPrompt,
    onDeliveryBypassHIPAAPrompt: transaction.onDeliveryBypassHIPAAPrompt,
    emailReceipt: transaction.emailReceipt,
    poNumber: transaction.poNumber,
    pseCheckResult: transaction.pseCheckResult,
    roomNumber: transaction.roomNumber,
    notes: transaction.notes,
    dataVersion: transaction.dataVersion,
    items: ConvertTransactionItemsToRequests(transaction.items),
    signatures: ConvertTransactionSignaturesToRequests(transaction.signatures),
    extras: ConvertTransactionExtrasToRequests(transaction.extras),
    taxes: ConvertTransactionTaxesToRequests(transaction.taxes),
    identification: ConvertTransactionIdentificationToRequest(
      transaction.identification,
    ),
  };
}
