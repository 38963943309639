import assert from 'assert';

const DECIMALS_ROUNDING = 2;

// bankers rounding
export const round = (amount: number, decimal = DECIMALS_ROUNDING): number => {
  const d = decimal;
  const m = Math.pow(10, d);
  const n = +(d ? amount * m : amount).toFixed(8);
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8;
  const evenKey = i % 2 == 0 ? i : i + 1;
  const r = f > 0.5 - e && f < 0.5 + e ? evenKey : Math.round(n);
  return d ? r / m : r;
};

export const discount = (amount: number, percent: number): number => {
  assert(percent > 0 && percent <= 1, 'Percent should be smaller than 1');
  return round(amount * (1 - percent));
};
