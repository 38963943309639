import {memo} from 'react';
import {
  DividerLine,
  Gutter,
  Row,
  Stack,
  Text,
  TextVariant as Variant,
} from './';

export interface PriceFooterProps {
  size?: 'default' | 'small';
  totals: Totals;
}

interface Totals {
  prescriptionCopay: number;
  overTheCounter: number;
  salesTax: number;
  qhpAmount: number;
  totalDue?: number;
  transactionTotal?: number;
}

export const PriceFooter = memo(
  ({
    totals,
    size = 'default',
    ...props
  }: PriceFooterProps &
    React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const {
      overTheCounter,
      prescriptionCopay,
      qhpAmount,
      salesTax,
      totalDue,
      transactionTotal,
    } = totals;

    return (
      <>
        <DividerLine />
        {size === 'default' ? (
          <Row
            align="end"
            gutter={Gutter.None}
            style={{marginTop: 16}}
            {...props}
          >
            <Stack
              gutter={Gutter.XS}
              style={{
                flex: '50%',
                paddingRight: 24,
              }}
            >
              <Row justify="space-between" style={{marginTop: 16}}>
                <Text variant={Variant.Main}>OTC</Text>
                <Text variant={Variant.Main} data-testid="otc">
                  ${overTheCounter.toFixed(2)}
                </Text>
              </Row>
              <Row justify="space-between">
                <Text variant={Variant.Main}>Rx Copay</Text>
                <Text variant={Variant.Main} data-testid="copay">
                  ${prescriptionCopay.toFixed(2)}
                </Text>
              </Row>
              <Row justify="space-between">
                <Text variant={Variant.Main}>Sales Tax</Text>
                <Text variant={Variant.Main} data-testid="sales-taxes">
                  ${salesTax.toFixed(2)}
                </Text>
              </Row>
            </Stack>
            <Stack
              style={{
                flex: '50%',
                paddingLeft: 24,
                borderLeft: '1px solid #dedfe1',
              }}
            >
              <Row justify="space-between">
                <Text variant={Variant.Main}>QHP Total</Text>
                <Text variant={Variant.Main} data-testid="qhp">
                  ${qhpAmount.toFixed(2)}
                </Text>
              </Row>
              <DividerLine />
              <Row justify="space-between" align="end" style={{marginTop: 12}}>
                <Text variant={Variant.Main}>
                  {totalDue ? 'Total Due' : 'Total'}
                </Text>
                <Text variant={Variant.T2} data-testid="total-due">
                  ${totalDue?.toFixed(2) || transactionTotal?.toFixed(2)}
                </Text>
              </Row>
            </Stack>
          </Row>
        ) : (
          <Stack gutter={Gutter.XS}>
            <Row justify="space-between" style={{marginTop: 16}}>
              <Text variant={Variant.Main}>OTC</Text>
              <Text variant={Variant.Main}>${overTheCounter.toFixed(2)}</Text>
            </Row>
            <Row justify="space-between">
              <Text variant={Variant.Main}>Rx Copay</Text>
              <Text variant={Variant.Main}>
                ${prescriptionCopay.toFixed(2)}
              </Text>
            </Row>
            <Row justify="space-between" style={{marginBottom: 8}}>
              <Text variant={Variant.Main}>Sales Tax</Text>
              <Text variant={Variant.Main}>${salesTax.toFixed(2)}</Text>
            </Row>
            <DividerLine />
            <Row
              justify="space-between"
              style={{marginTop: 16, marginBottom: 8}}
            >
              <Text variant={Variant.Main}>QHP Total</Text>
              <Text variant={Variant.Main}>${qhpAmount.toFixed(2)}</Text>
            </Row>
            <DividerLine />
            <Row justify="space-between" style={{marginTop: 16}}>
              <Text variant={Variant.Main}>Total Due</Text>
              <Text variant={Variant.Main}>${totalDue?.toFixed(2)}</Text>
            </Row>
          </Stack>
        )}
      </>
    );
  },
);
