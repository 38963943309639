import {useEffect, useRef} from 'react';

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  const previous = ref.current;

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return {value: previous, changed: value !== previous};
}
