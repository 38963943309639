import React, {PropsWithoutRef, memo} from 'react';
import styled from 'styled-components';

export interface ToggleProps {
  active: boolean;
  onToggle(toggle: boolean): void;
  'data-testid'?: string;
}

const Container = styled.div<{active: boolean}>`
  background: ${({active, theme}) =>
    active ? theme.colors.blue : theme.colors.gray_300};
  width: 51px;
  height: 31px;
  border-radius: 40px;
  padding: 2px;
  position: relative;
  cursor: pointer;
`;

const Knob = styled.button<{active: boolean}>`
  border: 0;
  background: ${({theme}) => theme.colors.white};
  width: 27px;
  height: 27px;
  border-radius: 50%;
  box-shadow: ${({theme}) => theme.shadows.shadow_4};
  position: absolute;
  left: 2px;
  transform: ${({active}) => (active ? 'translateX(75%)' : 'unset')};
  transition: transform 250ms ease-out;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Toggle = memo(
  ({
    active,
    onToggle,
    'data-testid': dataTestId,
  }: PropsWithoutRef<JSX.IntrinsicElements['div']> & ToggleProps) => {
    return (
      <Container
        active={active}
        onClick={_ => onToggle(!active)}
        data-testid={dataTestId || 'Toggle'}
      >
        <Knob active={active} type="button" />
      </Container>
    );
  },
);
