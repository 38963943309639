import {createContext, useContext} from 'react';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';

export interface ConsoleLoggerProviderContext {
  log: (...args: unknown[]) => void;
  styledLog: (
    title: string,
    variant: ConsoleLoggerVariant,
    ...args: unknown[]
  ) => void;
  logWarning: (...args: unknown[]) => void;
  logError: (...args: unknown[]) => void;
}

/* istanbul ignore next */
const consoleLoggerProviderContext =
  createContext<ConsoleLoggerProviderContext>({
    log: () => null,
    styledLog: () => null,
    logWarning: () => null,
    logError: () => null,
  });

export type ConsoleLoggerProviderProps = {
  children: React.ReactNode;
  consoleLoggerInstance?: ConsoleLogger;
};

export function ConsoleLoggingProvider(
  props: ConsoleLoggerProviderProps,
): JSX.Element {
  const consoleLogger =
    (props.consoleLoggerInstance as ConsoleLogger) || new ConsoleLogger();

  const log = (...args: unknown[]) => {
    consoleLogger.log(...args);
  };

  const styledLog = (
    title: string,
    variant: ConsoleLoggerVariant,
    ...args: unknown[]
  ) => {
    consoleLogger.styledLog(title, variant, ...args);
  };

  const logWarning = (...args: unknown[]) => {
    consoleLogger.logWarning(...args);
  };

  const logError = (...args: unknown[]) => {
    consoleLogger.logError(...args);
  };

  return (
    <consoleLoggerProviderContext.Provider
      value={{log, styledLog, logWarning, logError}}
    >
      {props.children}
    </consoleLoggerProviderContext.Provider>
  );
}

/* istanbul ignore next */
export const useConsoleLogger = (): ConsoleLoggerProviderContext =>
  useContext(consoleLoggerProviderContext);
