import {startCase, toLower, toUpper} from 'lodash';

const PHONE_NUMBER_PATTERN = /^(1|)?(\d{3})(\d{3})(\d{4})$/;

export const normalize = (s: string): string =>
  s.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const normalizeLower = (s: string): string => normalize(s).toLowerCase();

export const formatNamePart = (namePart: string): string =>
  startCase(toLower(namePart));

export const formatName = (firstName = '', lastName = ''): string => {
  return (formatNamePart(firstName) + ' ' + formatNamePart(lastName)).trim();
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const digits = ('' + phoneNumberString).replace(/\D/g, '');
  const matches = digits.match(PHONE_NUMBER_PATTERN);

  if (matches) {
    const intlCode = matches[1] ? '+1 ' : '';
    return [intlCode, '(', matches[2], ') ', matches[3], '-', matches[4]].join(
      '',
    );
  }

  return phoneNumberString;
};

// formatMedication will change 500MG to 500 Mg. This formats to 500mg
const convertPrescription = (match: string): string => {
  return toLower(match.split(' ').join(''));
};

// formatMedication will change IV to Iv. This formats back to IV
const upperCaseAbbreviations = (match: string): string => {
  return toUpper(match);
};

// Transforms medication (OTC or RX) descriptions to start case - excludes milligram abbreviation
export const formatMedication = (medication: string): string =>
  formatNamePart(medication)
    .replace(/(\d+\w [A-Za-z]{2})/, convertPrescription)
    .replace(/\b[a-zA-Z]{2}\b/i, upperCaseAbbreviations);
