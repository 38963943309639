import {TransactionSignature} from '@emporos/api-enterprise';

export const reduceSignatures = (
  signatures: TransactionSignature[],
): TransactionSignature[] => {
  const reducedSignatures = (signatures || [])
    .sort((a, b) => {
      /**
       * disabling eslint rule because we are comparing dates
       * and while JS allows a Date to be subtracted from another,
       * TS does not, so we are casting the dates to any.
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (a.createdOn as any) - (b.createdOn as any);
    })
    .reduce((acc, signature) => {
      acc[signature.signatureTypeId] = signature;
      return acc;
    }, {} as {[key: string]: TransactionSignature});

  return Object.values(reducedSignatures);
};
