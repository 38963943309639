import {Transaction, Session} from '@emporos/api-enterprise';
import assert from 'assert';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {TransactionConsolidate} from '../api';
import {generateSessionKey} from '../utils/session';
import {
  useAuthentication,
  useTransactionsConfig,
  useNetworkAvailable,
  useGlobalData,
} from './';
import {sessionLocaldb} from '../localDb/sessionLocaldb';
import {TransactionService} from '../services/TransactionService';

export type {Session, Transaction};

export type SessionUpdates =
  | Partial<Session>
  | ((prevSession: Session) => Partial<Session>);

export type TransactionUpdates =
  | Partial<Transaction>
  | ((prevTransaction: Transaction) => Partial<Transaction>);
export interface TransactionContextProps {
  currentTransactionIndex?: number;
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  currentTransactionId: string;
  setCurrentTransactionId: Dispatch<SetStateAction<string>>;
  selectedPayment: string;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  savingSession: boolean;
}

const noop = () => undefined;

export const TransactionsContext = createContext<TransactionContextProps>({
  session: null as unknown as Session,
  setSession: noop,
  currentTransactionId: '',
  setCurrentTransactionId: noop,
  selectedPayment: '',
  setSelectedPayment: noop,
  savingSession: false,
});

export function TransactionsStateProvider(props: {
  children?: React.ReactNode;
}): JSX.Element {
  const {session, setSession, loading} = useTransactionsConfig();
  const [currentTransactionId, setCurrentTransactionId] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [savingSession, setsavingSession] = useState(false);
  const [CompletingTransaction, setCompletingTransaction] = useState(false);
  const {user} = useAuthentication();
  const {paymentTendersResult} = useGlobalData();
  const sessionKey = generateSessionKey(user);
  const online = useNetworkAvailable();

  const token = user ? user.access_token : '';
  const sessionId = session ? session.sessionId : '';
  const localSession = new sessionLocaldb(
    new TransactionService(paymentTendersResult?.data ?? []),
    sessionKey,
    token,
    sessionId,
    currentTransactionId,
  );

  assert(
    session !== null,
    'Internal Error: rendered session app tree without active session',
  );

  useEffect(() => {
    if (!savingSession && session && currentTransactionId && !loading) {
      const transaction = session.transactions.find(
        i => i.transactionId === currentTransactionId,
      );
      if (!transaction) return;

      setsavingSession(true);
      const isCompleted =
        (transaction as TransactionConsolidate).isCompleted ?? false;
      setCompletingTransaction(isCompleted);
      localSession?.saveSession(session, online, isCompleted).then(() => {
        setsavingSession(false);

        if (CompletingTransaction) {
          setCompletingTransaction(false);
        }
      });
    }
  }, [session]);

  useEffect(() => {
    if (!savingSession && session && online && !loading) {
      setsavingSession(true);
      localSession?.syncTransactionsOffline(session).then(() => {
        setsavingSession(false);
      });
    }
  }, [online]);

  return (
    <TransactionsContext.Provider
      value={{
        session,
        setSession: setSession as Dispatch<SetStateAction<Session>>,
        currentTransactionId,
        setCurrentTransactionId,
        selectedPayment,
        setSelectedPayment,
        savingSession,
      }}
    >
      {props.children}
    </TransactionsContext.Provider>
  );
}

export const useTransactionsState = (): TransactionContextProps =>
  useContext(TransactionsContext);
