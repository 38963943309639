/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionPaymentStatusEnum {
    Approved = 'Approved',
    PartialApproval = 'PartialApproval',
    Removed = 'Removed',
    Pending = 'Pending',
    Error = 'Error'
}

export function TransactionPaymentStatusEnumFromJSON(json: any): TransactionPaymentStatusEnum {
    return TransactionPaymentStatusEnumFromJSONTyped(json, false);
}

export function TransactionPaymentStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPaymentStatusEnum {
    return json as TransactionPaymentStatusEnum;
}

export function TransactionPaymentStatusEnumToJSON(value?: TransactionPaymentStatusEnum | null): any {
    return value as any;
}

