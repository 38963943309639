import {useEffect, useState} from 'react';

export interface DeviceSettings {
  isTouchDevice: boolean;
}

/*
    https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
    https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Using_media_queries
    https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
*/
export const useDeviceDetect = (): DeviceSettings => {
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);
  useEffect(() => {
    if (!window.matchMedia) return;
    setIsTouchDevice(window.matchMedia('(pointer:coarse)').matches);
  }, []);

  return {isTouchDevice};
};
