import React, {FormEvent, memo, PropsWithoutRef} from 'react';
import {animated, useTransition} from 'react-spring';
import styled from 'styled-components';
import {
  Button,
  ColorType,
  Gutter,
  Icons,
  IndicatorCircle,
  mapColor,
  Row,
  Stack,
  Text,
  TextVariant as TV,
  Variant,
} from './';

export interface ModalProps {
  buttonText?: string;
  cancelText?: string;
  color: ColorType;
  disableSubmit?: boolean;
  icon: keyof typeof Icons;
  iconSpinning?: boolean;
  subtitle?: string;
  errorMessage?: string;
  title: string;
  visible: boolean;
  onCancel?: () => void;
  onContinue?: () => void;
  // this is only for the example in storybook
  $noMask?: boolean;
}

const StyledModalMask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  background-color: ${({theme}) => theme.colors.black_20};
  margin: 0;
  padding: 0;
`;

const StyledModal = styled.div<{color?: ColorType}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  width: 472px;
  height: auto;
  padding: 36px 24px 24px 24px;
  border-radius: 16px;
  background: ${({theme}) => theme.colors.white};
  box-shadow: ${({theme}) => theme.shadows.shadow_16};

  &::before {
    background: ${({theme, color}) => mapColor(theme, color)};
    border-radius: 16px 16px 0px 0px;
    content: '';
    height: 10px;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0;
  }
`;

const AnimatedMask = animated(StyledModalMask);
const AnimatedModal = animated(StyledModal);

export const Modal = memo(
  ({
    buttonText = 'Continue',
    cancelText = 'Cancel',
    color,
    disableSubmit,
    icon,
    iconSpinning,
    subtitle,
    errorMessage,
    title,
    visible,
    onCancel,
    onContinue,
    children,
    ...props
  }: ModalProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const fade = useTransition(visible, {
      from: {opacity: 0},
      enter: {opacity: 1},
      leave: {opacity: 0},
    });

    const transition = useTransition(visible, {
      from: {height: 'auto', opacity: 0, transform: 'translate(-50%, -40%)'},
      enter: {height: 'auto', opacity: 1, transform: 'translate(-50%, -50%)'},
      leave: {height: 'auto', opacity: 0, transform: 'translate(-50%, -40%)'},
    });

    function handleSubmit(event: FormEvent) {
      event.preventDefault();
      if (onContinue) {
        onContinue();
      }
    }

    return (
      <>
        {!props.$noMask &&
          fade(
            (style, item, _t, i) =>
              item && <AnimatedMask key={i} style={style} />,
          )}
        {transition(
          (style, item, _t, i) =>
            item && (
              <AnimatedModal
                key={i}
                style={style}
                role="dialog"
                color={color}
                {...props}
              >
                <Stack
                  justify="center"
                  gutter={Gutter.XL}
                  as="form"
                  onSubmit={handleSubmit}
                >
                  <Row justify="center">
                    <IndicatorCircle
                      variant={color}
                      icon={icon}
                      size="large"
                      loading={iconSpinning}
                    />
                  </Row>
                  <Row justify="center">
                    <Stack
                      align="center"
                      gutter={Gutter.XXS}
                      style={{padding: '0 20px'}}
                    >
                      <Text
                        align="center"
                        variant={TV.Main}
                        data-testid="modal-title"
                      >
                        {title}
                      </Text>
                      {subtitle && (
                        <Text
                          align="center"
                          variant={TV.SubMainLight}
                          data-testid="modal-subtitle"
                        >
                          {subtitle}
                        </Text>
                      )}
                      {errorMessage && (
                        <Text
                          align="center"
                          variant={TV.SubMain}
                          data-testid="modal-errorMessage"
                        >
                          {errorMessage}
                        </Text>
                      )}
                    </Stack>
                    {children}
                  </Row>
                  {onContinue && (
                    <Row justify="center" gutter={Gutter.XL}>
                      {onCancel && (
                        <Button
                          type="button"
                          data-testid="modal-cancel-button"
                          flex
                          variant={Variant.Secondary}
                          onClick={onCancel}
                        >
                          {cancelText}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        data-testid="modal-submit-button"
                        flex
                        color={color !== 'primary' ? color : undefined}
                        disabled={Boolean(disableSubmit)}
                        style={{maxWidth: '200px'}}
                      >
                        {buttonText}
                      </Button>
                    </Row>
                  )}
                </Stack>
              </AnimatedModal>
            ),
        )}
      </>
    );
  },
);
