import assert from 'assert';
import {memo, useCallback, useEffect, useRef} from 'react';

interface Props {
  transactionId: string;
  getPaymentResult: () => void;
}

const hostedPaymentURL =
  'https://certtransaction.hostedpayments.com/?TransactionSetupID=';

const FISPayment = ({getPaymentResult, transactionId}: Props): JSX.Element => {
  assert(
    transactionId !== null,
    'Cannot display payment portal without transaction id.',
  );
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const opts: AddEventListenerOptions & EventListenerOptions = {
      passive: true,
    };
    window.addEventListener('blur', handleBlur, opts);
    return () => {
      window.removeEventListener('blur', handleBlur, opts);
    };
  });

  const handleBlur = useCallback(() => {
    const activeTextarea = document.activeElement?.tagName.toLowerCase();
    const iframe = ref.current?.tagName.toLowerCase();
    if (activeTextarea === iframe) {
      getPaymentResult();
    }
  }, [getPaymentResult, ref]);

  return (
    <iframe
      height={'100%'}
      width={'100%'}
      ref={ref}
      src={`${hostedPaymentURL}${transactionId}`}
      style={{border: 'none'}}
      title="FIS Hosted Payment Portal"
    ></iframe>
  );
};

export const FISHostedPayment = memo(FISPayment);
