import {User} from 'oidc-client';
import {createContext, useContext} from 'react';
import {AuthClaim} from '../';
import '../vendor/segment-4.13.2';
import {
  ApiLogTypes,
  TransactionLogTypes,
  NetworkLogTypes,
  UserLogTypes,
  useLog,
} from './';

export enum AnalyticType {
  AddPaymentType = 'add_customer_payment',
  ApiError = 'api_error',
  CustomerSearch = 'customer_search',
  ItemDiscount = 'item_discount',
  PriceChange = 'price_change',
  ReceiptType = 'transaction_receipt',
  SignatureType = 'signature_type',
  ScanningError = 'scanning_error',
  TimeOffline = 'time_offline',
  // Amplitude specific event type
  OrderCompleted = 'Order Completed',
  UserError = 'user_error',
  VoidPaymentType = 'void_customer_payment',
}

interface TrackingEventApiError {
  body?: string;
  error: string;
  headers?: HeadersInit;
  method?: string;
  url: string;
}
interface TrackingEventCompleteTransaction {
  firstFillPickupPercent?: number;
  products: {
    name: string;
    price: number;
    productId: string;
    category: string;
    quantity: number;
  }[];
  saleDuration: number;
  total: number;
}
interface TrackingEventCustomerPayment {
  total: number;
  revenueType: string;
}
interface TrackingEventCustomerSearchPage {
  page: string;
}
interface TrackingEventDuration {
  time: number;
}
interface TrackingEventPriceChange {
  discount?: number;
  item: {name: string; productId: string};
  price: number;
  oldPrice: number;
  type?: string;
}
interface TrackingEventType {
  type?: string;
  receiptType?: string;
}

interface TrackingEventError {
  message: string;
}

export type TrackingEvent =
  | TrackingEventApiError
  | TrackingEventCompleteTransaction
  | TrackingEventCustomerPayment
  | TrackingEventCustomerSearchPage
  | TrackingEventDuration
  | TrackingEventPriceChange
  | TrackingEventType
  | TrackingEventError;

export interface AnalyticProviderContext {
  identify: (userId: string, user: User) => void;
  reset: () => void;
  track: (type: AnalyticType, values: TrackingEvent) => void;
}

export const analyticProviderContext = createContext<AnalyticProviderContext>({
  identify: () => null,
  reset: () => null,
  track: () => null,
});

export type AnalyticProviderProps = {children: React.ReactNode};

export function AnalyticsProvider(props: AnalyticProviderProps): JSX.Element {
  const {logApi, logNetwork, logUserSelection} = useLog();
  const {NODE_ENV} = process.env;
  const identify = (userId: string, user: User) => {
    if (String(NODE_ENV) !== 'test') {
      analytics.identify(userId, {
        ...user.profile,
        // Setting session ID for amplitude integration as needed from docs
        // https://segment.com/docs/connections/destinations/catalog/amplitude/#getting-started
        integrations: {
          Amplitude: {
            session_id: +new Date(),
          },
        },
      });
      // Might need to be updated once session API is integrated
      analytics.group(user.profile[AuthClaim.SiteId]);
      logUserSelection(UserLogTypes.UserIdentified);
    }
  };

  const reset = () => {
    if (String(NODE_ENV) !== 'test') {
      analytics.reset();
    }
  };

  const track = (type: AnalyticType, values: TrackingEvent) => {
    if (String(NODE_ENV) !== 'test') {
      analytics.track(type, values);
      switch (type) {
        case AnalyticType.ApiError:
          logApi(ApiLogTypes.ApiError, values, 'error');
          break;
        case AnalyticType.CustomerSearch:
          logUserSelection(TransactionLogTypes.CustomerAdded, values);
          break;
        case AnalyticType.TimeOffline:
          logNetwork(NetworkLogTypes.NetworkLostConnection, values);
          break;
        case AnalyticType.ScanningError:
          break;
        default:
          logUserSelection(type, values);
          break;
      }
    }
  };

  return (
    <analyticProviderContext.Provider value={{identify, reset, track}}>
      {props.children}
    </analyticProviderContext.Provider>
  );
}

export const useAnalyticsProvider = (): AnalyticProviderContext =>
  useContext(analyticProviderContext);
