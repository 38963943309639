import {
  BarcodeCaptureSettings,
  Symbology,
} from 'scandit-web-datacapture-barcode';

export const DEFAULT_SYMBOLOGIES = [
  Symbology.EAN13UPCA,
  Symbology.EAN8,
  Symbology.UPCE,
  Symbology.Code39,
  Symbology.QR,
  Symbology.Code128,
  Symbology.MicroQR,
  Symbology.Aztec,
];

export enum Extensions {
  FULL_ASCII = 'full_ascii',
  RELAXED_SHARP_QUIET_ZONE_CHECK = 'relaxed_sharp_quiet_zone_check',
  RETURN_AS_UPCA = 'return_as_upca',
  REMOVE_LEADING_ZERO = 'remove_leading_zero',
  REMOVE_LEADING_UPCA_ZERO = 'remove_leading_upca_zero',
  STRIP_LEADING_FNC1 = 'strip_leading_fnc1',
  DIRECT_PART_MARKING_MODE = 'direct_part_marking_mode',
  STRICT = 'strict',
}

export class BarcodeCaptureSettingsExtended extends BarcodeCaptureSettings {
  constructor(enabledSymbologies: Symbology[]) {
    super();
    const activeSymbs =
      enabledSymbologies.length > 0 ? enabledSymbologies : DEFAULT_SYMBOLOGIES;

    this.ConfigureDefaultSymbologiesSettings(activeSymbs);
    this.ConfigureUPCSymbologies(activeSymbs);
    this.ConfigureCode39Symbology(activeSymbs);
    this.enableSymbologies(activeSymbs);
  }

  private ConfigureDefaultSymbologiesSettings = (symbologies: Symbology[]) => {
    symbologies.forEach(sym => {
      const symbSettings = this.settingsForSymbology(sym);
      symbSettings.setExtensionEnabled(Extensions.STRICT, true);
    });
  };

  private ConfigureUPCSymbologies = (symbologies: Symbology[]) => {
    if (symbologies.includes(Symbology.EAN13UPCA)) {
      const upcaSettings = this.settingsForSymbology(Symbology.EAN13UPCA);
      upcaSettings.setExtensionEnabled(
        Extensions.REMOVE_LEADING_UPCA_ZERO,
        true,
      );
    }

    if (symbologies.includes(Symbology.UPCE)) {
      const upceSettings = this.settingsForSymbology(Symbology.UPCE);
      upceSettings.setExtensionEnabled(Extensions.RETURN_AS_UPCA, true);
      upceSettings.setExtensionEnabled(
        Extensions.REMOVE_LEADING_UPCA_ZERO,
        true,
      );
    }
  };

  private ConfigureCode39Symbology = (symbologies: Symbology[]) => {
    if (symbologies.includes(Symbology.Code39)) {
      const code39Settings = this.settingsForSymbology(Symbology.Code39);
      code39Settings.setExtensionEnabled(Extensions.FULL_ASCII, true);
    }
  };
}
