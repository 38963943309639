import {Transaction, Session} from '@emporos/api-enterprise';
import {TransactionConsolidate} from '../api';
import {ITransactionService} from '../services/ITransactionService';
import {ITransactionDatabaseAccess} from './IDatabaseAccess';
import {mapTransactionConsolidate} from '../utils';
import {TransactionLocaldb} from './TransactionsLocaldb';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';
const devLogging = String(process.env.NODE_ENV).trim() === 'development';

export class sessionLocaldb {
  private _key!: string;
  private _token!: string | undefined;
  private _transactionDb!: ITransactionDatabaseAccess;
  private _sessionId!: string;
  private _transactionService!: ITransactionService;
  private _currentTransactionId!: string;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();

  constructor(
    transactionService: ITransactionService,
    key: string,
    token: string | undefined,
    sessionId: string,
    currentTransactionId: string,
  ) {
    try {
      this._key = key;
      this._token = token;
      this._sessionId = sessionId;
      this._currentTransactionId = currentTransactionId;
      const transactiondb = new TransactionLocaldb();
      transactiondb.initialize(this._sessionId);
      this._transactionDb = transactiondb;

      transactionService.initialize(
        this._sessionId,
        this._token?.toString() || '',
      );
      this._transactionService = transactionService;
    } catch (error) {
      this._consoleLogger.logError(error);
    }
  }

  async saveSession(
    value: Session | null,
    isOnline: boolean,
    CompleteTransaction?: boolean,
  ): Promise<void> {
    try {
      if (value === null) {
        if (devLogging) {
          window.localStorage.removeItem(this._key);
        }
      } else {
        const valueToStore = value instanceof Function ? value(value) : value;

        if (
          this._currentTransactionId == '0' ||
          this._currentTransactionId == ''
        ) {
          //New transaction has been added or new session loaded from cloud.
          for (let i = 0; i < valueToStore.transactions.length; i++) {
            const existingObjectDb = await this._transactionDb.get(
              valueToStore.transactions[i].transactionId,
            );

            if (existingObjectDb.serverTransactionID > 0) {
            } else {
              valueToStore.transactions[i].isSynced = isOnline;
              await this._transactionDb.add(valueToStore.transactions[i]);
            }
          }
        }

        if (this._currentTransactionId !== '0') {
          const existingObjectDb = await this._transactionDb.get(
            this._currentTransactionId,
          );
          let sessionObject = valueToStore.transactions.find(
            (n: {transactionId: string}) =>
              n.transactionId == this._currentTransactionId,
          ) as TransactionConsolidate;

          if (existingObjectDb.serverTransactionID > 0) {
            existingObjectDb.isDeleted = sessionObject.isDeleted;
            //maps items
            existingObjectDb.items = sessionObject.items;
            //maps payments
            existingObjectDb.payments = sessionObject.payments;
            //maps taxes
            existingObjectDb.taxes = sessionObject.taxes;
            //maps signature
            existingObjectDb.signatures = sessionObject.signatures;

            //preserve data version before map
            if (sessionObject.signatureImage) {
              const signatureImageDataVersion =
                existingObjectDb.signatureImage?.dataVersion;
              existingObjectDb.signatureImage = sessionObject.signatureImage;
              // eslint-disable-next-line
              sessionObject!.signatureImage!.dataVersion =
                signatureImageDataVersion;
            }
            //maps notes
            existingObjectDb.notes = sessionObject.notes;
            //maps customer
            if (sessionObject.customer?.id) {
              sessionObject.customerId = sessionObject.customer.id;
              existingObjectDb.customerId = sessionObject.customer.id;
            }
            existingObjectDb.customer = sessionObject.customer;

            const updatedDbObject = mapTransactionConsolidate(
              existingObjectDb,
              sessionObject,
            );

            updatedDbObject.isCompleted = CompleteTransaction;
            updatedDbObject.isSynced = isOnline;

            await this._transactionDb.update(updatedDbObject);

            this._consoleLogger.styledLog(
              'sessionLocalDb - Transaction DB Updated:',
              ConsoleLoggerVariant.PURPLE,
              updatedDbObject,
            );

            try {
              if (isOnline) {
                await this._transactionService.syncTransactions(
                  updatedDbObject.transactionId,
                );
              }
              if (updatedDbObject.serverTransactionID > 0) {
                sessionObject = updatedDbObject as unknown as Transaction;

                if (updatedDbObject.isDeleted) {
                  if (isOnline) {
                    await this._transactionDb.delete(
                      updatedDbObject.transactionId,
                    );

                    this._consoleLogger.styledLog(
                      'sessionLocalDb - Deleted Transaction from Local DB:',
                      ConsoleLoggerVariant.PURPLE,
                      updatedDbObject,
                    );
                  } else {
                    await this._transactionDb.update(updatedDbObject);

                    this._consoleLogger.styledLog(
                      'sessionLocalDb - Updated Transaction from Local DB - will delete upon returning online and syncing:',
                      ConsoleLoggerVariant.PURPLE,
                      updatedDbObject,
                    );
                  }
                }
                for (let i = 0; i < valueToStore.transactions.length; i++) {
                  if (
                    valueToStore.transactions[i].transactionId ==
                    updatedDbObject.transactionId
                  ) {
                    valueToStore.transactions[i].serverTransactionID =
                      updatedDbObject.serverTransactionID;
                    valueToStore.transactions[i].status =
                      updatedDbObject.status;
                    valueToStore.transactions[i].isSynced = isOnline;
                  }
                }
              }
            } catch (error) {
              this._consoleLogger.logError(error);
            }
          } else {
            sessionObject.isSynced = isOnline;
            await this._transactionDb.add(sessionObject);
            if (isOnline) {
              await this._transactionService.syncTransactions(
                sessionObject.transactionId,
              );
            }
          }
        }
      }
    } catch (err) {
      this._consoleLogger.logError(err);
    }
  }

  async syncTransactionsOffline(value: Session | null): Promise<void> {
    try {
      const valueToStore = value instanceof Function ? value(value) : value;

      const transactions = await (
        await this._transactionDb.getAll()
      ).filter(n => n.isSynced == false);

      this._consoleLogger.styledLog(
        'sessionLocalDb - Saving Offline Session:',
        ConsoleLoggerVariant.PURPLE,
        transactions,
      );

      for (let i = 0; i < transactions.length; i++) {
        const syncedTransaction =
          await this._transactionService.syncTransactionsOffline(
            transactions[i].transactionId,
          );

        for (let j = 0; j < valueToStore.transactions.length; j++) {
          if (
            valueToStore.transactions[j].transactionId ==
            transactions[i].transactionId
          ) {
            valueToStore.transactions[j].serverTransactionID =
              syncedTransaction.serverTransactionID;
            valueToStore.transactions[j].status = syncedTransaction.status;
            valueToStore.transactions[j].isSynced = true;
            valueToStore.transactions[j].payments = syncedTransaction.payments;
          }
        }
      }
    } catch (error) {
      this._consoleLogger.logError(
        'Error syncing offline transactions:',
        error,
      );
    }
  }
}
