/**
 * Authentication claims for a user. Used to read OIDC user profile
 * information.
 */
export enum AuthClaim {
  Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  UserId = 'UserId',
  SiteId = 'SiteId',
}

/**
 * LocalStorage key where mock user is stored for integration tests.
 */
export const TEST_USER_KEY = 'emporos/TEST_USER';
