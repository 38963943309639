import styled from 'styled-components';

export enum Gutter {
  None = 'None',
  XXS = 'XXS',
  XS = 'XS',
  S = 'S',
  M = 'M', // Medium is default (16px)
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}
const gutter = ({gutter: g}: {gutter?: Gutter}) => {
  switch (g) {
    case Gutter.None:
      return '0';
    case Gutter.XXS:
      return '0.25rem'; // 4px
    case Gutter.XS:
      return '0.5rem'; // 8px
    case Gutter.S:
      return '0.75rem'; // 12px
    case Gutter.L:
      return '1.25rem'; // 20px
    case Gutter.XL:
      return '1.5rem'; // 24px
    case Gutter.XXL:
      return '2.25rem'; // 36px
    case Gutter.M:
    default:
      return '1rem'; // 16px
  }
};

export type Justify =
  | 'start'
  | 'center'
  | 'end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

export type Align = 'start' | 'center' | 'end' | 'stretch';

const mapFlex = (value?: Justify | Align) => {
  switch (value) {
    case 'start':
      return 'flex-start';
    case 'end':
      return 'flex-end';
    default:
      return value;
  }
};

export interface StackProps {
  align?: Align;
  justify?: Justify;
  gutter?: Gutter;
}

export const Stack = styled.div<StackProps>`
  --space: ${gutter};
  display: flex;
  flex-direction: column;
  justify-content: ${({justify = 'start'}) => mapFlex(justify)};
  align-items: ${({align}) => mapFlex(align)};

  > * + * {
    margin-top: var(--space);
  }
`;

export interface RowProps {
  align?: Align;
  justify?: Justify;
  gutter?: Gutter;
  noWrap?: boolean;
}

export const Row = styled.div<RowProps>`
  --rowspace: ${gutter};
  display: flex;
  flex-wrap: ${({noWrap}) => (noWrap ? 'nowrap' : 'wrap')};
  justify-content: ${({justify = 'start'}) => mapFlex(justify)};
  align-items: ${({align}) => mapFlex(align)};

  > * + * {
    margin-left: var(--rowspace);
  }
`;
