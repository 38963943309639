export type SignalListener<T> = (data: T) => unknown;
export type SignalUnlistenFunction = () => unknown;

/**
 * An object that dispatches strongly typed messages to subscribers.
 */
export interface Signal<T = void> {
  /**
   * Register a function as a listener that will be invoked for each call of
   * dispatch(data).
   */
  listen: (cb: SignalListener<T>) => void;

  /**
   * Un-register a registered listener function.
   */
  unlisten: (cb: SignalListener<T>) => void;

  /**
   * Dispatch a message to all registered listeners.
   */
  dispatch: (data: T) => void;
}

export const createSignal = <T = void>(): Signal<T> => {
  const listeners: SignalListener<T>[] = [];

  function listen(listener: SignalListener<T>) {
    if (!listeners.includes(listener)) {
      listeners.push(listener);
    }
  }

  function unlisten(listener: SignalListener<T>) {
    const index = listeners.indexOf(listener);

    if (index === -1) {
      throw new Error(
        '[Signal] Cannot remove listener – function is not a listener',
      );
    }

    const head = listeners.pop();

    if (!head || listener === head) {
      return;
    }

    listeners[index] = head;
  }

  function dispatch(data: T) {
    for (const listener of listeners) {
      listener(data);
    }
  }

  return {
    listen,
    unlisten,
    dispatch,
  };
};
