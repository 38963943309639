import React, {memo, PropsWithoutRef} from 'react';
import {Modal} from './';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const PendingChangesModal = memo(
  ({
    visible,
    onCancel,
    onContinue,
  }: Props & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    return (
      <Modal
        data-testid="pending-changes-modal"
        visible={visible}
        icon="Warning"
        color="warning"
        onCancel={onCancel}
        onContinue={onContinue}
        title="Unsaved Changes"
        subtitle="You will lose any unsaved changes. Would you like to continue?"
      />
    );
  },
);
