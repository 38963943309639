import React, {memo, PropsWithoutRef, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {
  Button,
  Variant as BV,
  Gutter,
  Icon,
  NumberInput,
  Row,
  SmallSidebarWrapper,
  Stack,
  Text,
  toCurrency,
  TextVariant as Variant,
} from './';

export interface NumberpadProps {
  title: string;
  number: string;
  type?: 'default' | 'price' | 'percent' | 'quantity';
  setNumber: (n: string) => void;
  defaultValue?: string | number;
  onClear?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const formatInput = (value: string, type?: string): string | undefined => {
  if (value.match(/([a-zA-Z])|(\W)/)) {
    const percentValue =
      type && type === 'percent' ? Number(value.replace('%', '')) : undefined;
    return !!percentValue ? `${Math.trunc(percentValue)}` : undefined;
  }
  return value;
};

interface PanelProps {
  open?: boolean;
}

const StyledNumberpad = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1px;
  background: ${({theme}): string => theme.colors.gray_200};
  border-bottom: ${({theme}): string => `1px solid ${theme.colors.gray_200}`};
`;
const hoverStyles = css`
  background: ${({
    theme,
  }): string => `linear-gradient(${theme.colors.blue_20}, ${theme.colors.blue_20}),
                      linear-gradient(${theme.colors.white}, ${theme.colors.white})`};
  border-color: ${({theme}): string => theme.colors.blue};
  color: ${({theme}): string => theme.colors.blue};
`;
const NumberButton = styled.button`
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 2px solid transparent;
  background-color: ${({theme}): string => theme.colors.gray_100};
  height: 80px;
  justify-content: center;
  align-items: center;
  color: ${({theme}): string => theme.colors.blue};
  font-family: ${({theme}): string => theme.typography.Title1.fontFamily};
  font-weight: ${({theme}): number => theme.typography.Title1.fontWeight};
  font-size: ${({theme}): string => theme.typography.Title1.fontSize};
  cursor: pointer;

  &:active {
    ${hoverStyles}
  }
  @media (hover: hover) {
    &:hover {
      ${hoverStyles}
    }
  }
  &:focus {
    outline: none;
  }
`;
const StyledCancel = styled(Button)`
  width: 108px;
  border: ${({theme}) => `1px solid ${theme.colors.gray_200}`};
  @media (max-width: 1034px) {
    width: 90px;
  }
`;

const StyledSave = styled(Button)`
  width: 108px;
  @media (max-width: 1034px) {
    width: 90px;
  }
`;

const MiniPage = styled.div<PanelProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 289px;
  padding: 36px 0;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 30px 0 0 30px;
  box-shadow: ${({theme}) => theme.shadows.shadow_8};
  position: absolute;
  top: 0;
  right: ${({open}) => (open ? '0' : '-16px')};
  transform: ${({open}) => (!open ? 'translateX(100%)' : 'unset')};
  transition: transform 250ms ease-out;

  @media (max-width: 1034px) {
    width: 265px;
  }
`;

export const Numberpad = memo(
  ({
    title,
    number,
    type = 'default',
    setNumber,
    defaultValue,
    onClear,
    onConfirm,
    onCancel,
  }: NumberpadProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const [initialNumber] = useState(number);
    const numberSet: Array<string> = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '',
      '0',
    ];
    const maxLength: number = type === 'default' ? 5 : 9;

    return (
      <SmallSidebarWrapper
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minWidth: 0,
        }}
      >
        <Stack gutter={Gutter.None}>
          <Text
            variant={Variant.T3}
            align="center"
            style={{color: '#778095', padding: 12}}
          >
            {title}
          </Text>

          <NumberInput
            value={
              type === 'price'
                ? `$${toCurrency(number)}`
                : type === 'percent'
                ? `${Number(number.replace('%', ''))}%`
                : number
            }
            onChange={e => {
              const value = e.target.value;
              if (type === 'price') {
                setNumber(`${toCurrency(value, '.')}`);
              } else {
                const formattedValue = formatInput(value, type);
                if (!formattedValue) {
                  return;
                }
                setNumber(formattedValue);
              }
            }}
            style={{borderRadius: 0}}
            maxLength={maxLength}
            defaultValue={defaultValue}
            onClear={onClear}
          />

          <StyledNumberpad data-testid="numberpad">
            {numberSet.map(num => (
              <NumberButton
                onClick={(): void => {
                  if (type === 'price') {
                    setNumber(
                      toCurrency((number + num).slice(0, maxLength), '.'),
                    );
                  } else {
                    setNumber((number + num).slice(0, maxLength));
                  }
                }}
                key={num}
                type="button"
              >
                {num}
              </NumberButton>
            ))}
            <NumberButton
              onClick={(): void => {
                if (type === 'price') {
                  setNumber(toCurrency(number.slice(0, -1)));
                } else {
                  setNumber(number.slice(0, -1));
                }
              }}
              data-testid="numpad-clear"
              type="button"
            >
              <Icon icon="Backspace" variant="primary" />
            </NumberButton>
          </StyledNumberpad>
        </Stack>
        {onConfirm && onCancel && (
          <Row
            justify="space-around"
            style={{padding: '12px 12px 16px'}}
            gutter={Gutter.None}
            noWrap
          >
            <StyledCancel
              variant={BV.Secondary}
              onClick={onCancel}
              data-testid="numpad-cancel"
            >
              Cancel
            </StyledCancel>
            <StyledSave
              onClick={onConfirm}
              data-testid="numpad-ok"
              disabled={!number || initialNumber === number}
            >
              Save
            </StyledSave>
          </Row>
        )}
      </SmallSidebarWrapper>
    );
  },
);

export function NumberPanel({
  title,
  number,
  type = 'default',
  setNumber,
  defaultValue,
  onClear,
  onConfirm,
  onCancel,
  open,
}: NumberpadProps &
  PanelProps &
  PropsWithoutRef<JSX.IntrinsicElements['div']>) {
  const [initialNumber, setInitialNumber] = useState(number);

  useEffect(() => {
    open && setInitialNumber(number);
  }, [open]); // eslint-disable-line
  // number excluded from dependency array intentionally

  const numberSet: Array<string> = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '',
    '0',
  ];
  const maxLength: number = type === 'default' ? 5 : 9;

  return (
    <MiniPage open={open} data-testid={`${title}-numberpanel`}>
      <Stack gutter={Gutter.XL}>
        <Text variant={Variant.T2} align="center">
          {title}
        </Text>

        <NumberInput
          value={
            type === 'price'
              ? `$${toCurrency(number)}`
              : type === 'percent'
              ? `${Number(number.replace('%', ''))}%`
              : number
          }
          onChange={e => {
            const value = e.target.value;
            if (type === 'price') {
              setNumber(`${toCurrency(value, '.')}`);
            } else {
              const formattedValue = formatInput(value, type);
              if (!formattedValue) {
                return;
              }
              setNumber(formattedValue);
            }
          }}
          style={{borderRadius: 0}}
          maxLength={maxLength}
          defaultValue={defaultValue}
          onClear={onClear}
        />

        <StyledNumberpad data-testid="numberpad" style={{marginTop: 0}}>
          {numberSet.map(num => (
            <NumberButton
              onClick={(): void => {
                if (type === 'price') {
                  setNumber(
                    toCurrency((number + num).slice(0, maxLength), '.'),
                  );
                } else {
                  setNumber((number + num).slice(0, maxLength));
                }
              }}
              key={num}
              type="button"
              style={{backgroundColor: 'white'}}
              data-testid={`${title}-${num}`}
            >
              {num}
            </NumberButton>
          ))}
          <NumberButton
            onClick={(): void => {
              if (type === 'price') {
                setNumber(toCurrency(number.slice(0, -1)));
              } else {
                setNumber(number.slice(0, -1));
              }
            }}
            data-testid={`${title}-delete`}
            type="button"
            style={{backgroundColor: 'white'}}
          >
            <Icon icon="Backspace" variant="primary" />
          </NumberButton>
        </StyledNumberpad>
      </Stack>

      {onConfirm && onCancel && (
        <Row
          justify="space-around"
          style={{padding: '0px 16px', marginTop: 'auto'}}
          gutter={Gutter.None}
          noWrap
        >
          <StyledCancel
            variant={BV.Secondary}
            onClick={onCancel}
            data-testid={`${title}-cancel`}
            style={{border: 'none'}}
          >
            Cancel
          </StyledCancel>
          <StyledSave
            onClick={onConfirm}
            data-testid={`${title}-save`}
            disabled={!number || initialNumber === number}
          >
            Save
          </StyledSave>
        </Row>
      )}
    </MiniPage>
  );
}
