import {Transaction, Setting} from '@emporos/api-enterprise';

// Constants
export const COUNSELING_LINE1 = 'CounselingLine1';
export const HIPAA_LINE1 = 'HIPAALine1';
export const RELATIONSHIP = 'relationship';
export const COUNSELING_ENABLED = 'CounselingEnabled';
export const HIPAA_ENABLED = 'HIPAAEnabled';
export const RX_RELATIONSHIP_PROMPT_ENABLED = 'RxRelationshipPromptEnabled';
export const HIPAA_RELATIONSHIP_PROMPT_ENABLED =
  'HIPAARelationshipPromptEnabled';

export const COUNSEL_LATER = 'counsel_later';
export const NO_COUNSELING = 'no_counseling';

export const YES_NUM = 0;
export const NO_NUM = 1;
export const YES = 'yes';
export const NO = 'no';
export const YES_INDEX = 0;
export const NO_INDEX = 1;

// Interface for Compliance Indicators
export interface ComplianceIndicators {
  showCounsel: boolean;
  showCounselRequired: boolean;
  showHipaa: boolean;
  showRelationship: boolean;
}

// Function to map Compliance Indicators
export const mapComplianceIndicators = (
  {items, customer}: Transaction,
  settings: Setting[],
): ComplianceIndicators => {
  // Check if showCounsel should be true
  let showCounsel =
    !!settings &&
    settings.some(({name, value}) => {
      return name === COUNSELING_LINE1 && value;
    }) &&
    items.some(({rx}) => !!rx);

  // Check if showCounselRequired should be true
  let showCounselRequired = items.some(
    ({counselRequiredIndicator, rx}) => rx && counselRequiredIndicator,
  );

  // Get site setting for COUNSELING_ENABLED
  const showCounselingEnabled =
    !!settings &&
    settings.some(({name, value}) => {
      return name === COUNSELING_ENABLED && value === '1';
    });

  // If COUNSELING_ENABLED is not true, set showCounsel and showCounselRequired to false
  if (!showCounselingEnabled) {
    showCounsel = false;
    showCounselRequired = false;
  }

  // Check if showHipaa should be true
  const showHipaa =
    !!customer &&
    customer.showHIPAA &&
    items.some(({rx}) => rx) &&
    !!settings &&
    settings.some(({name, value}) => {
      return name === HIPAA_ENABLED && value === '1';
    }) &&
    settings.some(({name, value}) => {
      return name === HIPAA_LINE1 && value;
    });

  // Check if showRelationship should be true
  const showRelationship =
    (!!settings &&
      (settings.some(
        ({name, value}) =>
          name === HIPAA_RELATIONSHIP_PROMPT_ENABLED && value === '1',
      ) ||
        settings.some(
          ({name, value}) =>
            name === RX_RELATIONSHIP_PROMPT_ENABLED && value === '1',
        )) &&
      items.some(({rx}) => rx)) ||
    items.some(
      ({captureRelationshipIndicator, rx}) =>
        rx && captureRelationshipIndicator,
    );

  // Return the Compliance Indicators object
  return {
    showCounsel,
    showCounselRequired,
    showHipaa,
    showRelationship,
  };
};
