// https://docs.tsysmerchant.com/knowledge-base/faqs/what-are-the-genius-status-call-possible-responses
import {CEDScreen} from './CayanTypes';

export const IdleScreen = '00' as CEDScreen;
export const ValidatingTransportKey = '01' as CEDScreen;
export const MainPaymentCollection = '02' as CEDScreen;
export const CustomPaymentCollection = '03' as CEDScreen;
export const LookingUpCardBIN = '04' as CEDScreen;
export const WaitingForAmountConfirmation = '05' as CEDScreen;
export const WaitingForPINEntry = '06' as CEDScreen;
export const ProcessingPayment = '07' as CEDScreen;
export const WaitingForSignature = '08' as CEDScreen;
export const ProcessingSignatureCapture = '09' as CEDScreen;
export const TransactionResult = '10' as CEDScreen;
export const CancelConfirmation = '11' as CEDScreen;
export const RunAsCreditConfirmation = '12' as CEDScreen;
export const SKUDisplay = '13' as CEDScreen;
export const CashbackSelectionScreen = '14 as CEDScreen';
export const CashbackCustomScreen = '15' as CEDScreen;
export const TipSelectionScreen = '16' as CEDScreen;
export const TipCustomScreen = '17' as CEDScreen;
export const DonationSelectionScreen = '18' as CEDScreen;
export const DonationCustomScreen = '19' as CEDScreen;
export const ConfirmationScreen = '20' as CEDScreen;
export const ErrorScreen = '24' as CEDScreen;
export const SKUAmountConfirmationScreen = '26' as CEDScreen;
export const PANEntryScreen = '27' as CEDScreen;
export const ExpirationEntryScreen = '28' as CEDScreen;
export const CVVEntryScreen = '29' as CEDScreen;
export const ZipEntryScreen = '30' as CEDScreen;
export const AgreementScreen = '31' as CEDScreen;
export const AgreementSignatureScreen = '32' as CEDScreen;
export const EMVApplicationSelectionScreen = '33' as CEDScreen;
export const GetCustomerInputScreen = '35' as CEDScreen;
export const GiftCardCaptureScreen = '36' as CEDScreen;
export const NetworkDetailsScreen = '38' as CEDScreen;
export const NetworkConfigurationScreen = '39' as CEDScreen;
