import App from './App';
import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {ContainerProvider} from 'brandi-react';
import {DIFactory} from './DIFactory';

// DI
const container = DIFactory.buildDefaultContainer();

ReactDOM.render(
  <StrictMode>
    <ContainerProvider container={container}>
      <App />
    </ContainerProvider>
  </StrictMode>,
  document.getElementById('root'),
);
