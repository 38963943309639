import {TransactionStatusEnum} from '@emporos/api-enterprise';
import {RowSidebarStatus, TabBar} from '@emporos/components';
import {
  RouteComponentProps,
  Router,
  useLocation,
  useNavigate,
} from '@reach/router';
import {Fragment, memo, useState} from 'react';
import {
  CompletedSidebar,
  Transaction,
  TransactionConsolidate,
  NavigationLogTypes,
  PaymentsSidebarIntegration as PaymentsSidebar,
  SalesSidebar,
  SettingsSidebar,
  useSessionData,
  useLog,
  useTransactionsState,
} from '../';

const COMPLETED_TAB = 1;
export const fullHeightStack = {minHeight: '100%'};
export const behindTabBarStackingContext = {
  position: 'relative' as const,
  zIndex: 0,
  flex: 1,
  overflowY: 'auto' as const,
};

function indexToUrl(index: number): string {
  switch (index) {
    case 0:
      return '/sales';
    case 1:
      return '/sales/completed';
    case 2:
      return '/sales/settings';
    default:
      return '';
  }
}

export const transactionSynced = (
  transaction: Transaction | TransactionConsolidate,
): RowSidebarStatus => {
  if ((transaction as TransactionConsolidate).isSynced === false) {
    return 'unsynced';
  } else if (transaction.status === TransactionStatusEnum.Complete) {
    return 'synced';
  } else if (
    transaction.status == TransactionStatusEnum.Accepted ||
    transaction.status == TransactionStatusEnum.Error
  ) {
    return 'error';
  } else {
    return 'synced';
  }
};

//Todo manage all the sync process status in a external process

interface NavigationProps extends RouteComponentProps {
  onNavigationRequest(index: number): void;
  loading: boolean;
  pathname: string;
}

function Navigation({loading, pathname, onNavigationRequest}: NavigationProps) {
  let active: number;
  if (/settings/.test(pathname)) {
    active = 2;
  } else {
    active = /completed/.test(pathname) ? 1 : 0;
  }

  return (
    <div
      style={{
        position: 'absolute' as const,
        bottom: 36,
        zIndex: 1,
        left: 40,
      }}
    >
      <TabBar
        active={active}
        onSelect={onNavigationRequest}
        items={[
          {
            icon: 'CashRegister',
          },
          {
            icon: 'CheckmarkClipboard',
            disabled: loading,
          },
          {icon: 'Cog', disabled: loading},
        ]}
      />
    </div>
  );
}

function SidebarComponent() {
  const navigate = useNavigate();
  const [requestedUrl, setRequestedUrl] = useState<null | string>(null);
  const {logUserSelection} = useLog();
  const {setCurrentTransactionId} = useTransactionsState();
  const {pathname} = useLocation();
  const {loading} = useSessionData();

  function onNavigationRequest(index: number) {
    setRequestedUrl(indexToUrl(index));
    logUserSelection(NavigationLogTypes.UserNavigationRequested, {
      url: indexToUrl(index),
    });
    if (index === COMPLETED_TAB) {
      setCurrentTransactionId('');
    }
  }

  function onCancelNavigation() {
    logUserSelection(NavigationLogTypes.UserNavigationCancelled, {
      url: requestedUrl,
    });
    setRequestedUrl(null);
  }

  function onConfirmNavigation(url?: string) {
    if (typeof url === 'string') {
      logUserSelection(NavigationLogTypes.UserNavigating, {url});
      setRequestedUrl(null);
      return navigate(url);
    }
    if (requestedUrl) {
      logUserSelection(NavigationLogTypes.UserNavigating, {
        url: requestedUrl,
      });
      setRequestedUrl(null);
      return navigate(requestedUrl);
    }
  }

  return (
    <>
      <Router primary={false} component={Fragment}>
        <CompletedSidebar
          requestedUrl={requestedUrl}
          onCancelNavigation={onCancelNavigation}
          onConfirmNavigation={onConfirmNavigation}
          path="completed/*"
        />
        <SettingsSidebar
          requestedUrl={requestedUrl}
          onCancelNavigation={onCancelNavigation}
          onConfirmNavigation={onConfirmNavigation}
          path="settings/*"
        />
        <SalesSidebar
          default
          requestedUrl={requestedUrl}
          onConfirmNavigation={onConfirmNavigation}
        />
        <PaymentsSidebar path="transactions/payments/*" />
      </Router>
      {!/payments/.test(pathname) && (
        <Navigation
          default
          onNavigationRequest={onNavigationRequest}
          loading={loading}
          pathname={pathname}
        />
      )}
    </>
  );
}

export const Sidebar = memo(SidebarComponent);
