import {Row, Stack, TextInput} from '@emporos/components';
import {BillBoard} from './';

interface Props {
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
  paymentNumber: string;
  setPaymentNumber: (v: string) => void;
}

export function CheckPayment({
  amount,
  setAmount,
  totalDue,
  paymentNumber,
  setPaymentNumber,
}: Props): JSX.Element {
  return (
    <Stack style={{flex: 1}}>
      <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
      <Row style={{marginTop: 36}}>
        <TextInput
          data-testid="check-number"
          label="Check Number"
          type="number"
          value={paymentNumber}
          onChange={e => setPaymentNumber(e.target.value)}
        />
      </Row>
    </Stack>
  );
}
