import {
  TransactionExtra,
  TransactionExtraRequest,
} from '@emporos/api-enterprise';

export function ConvertTransactionExtrasToRequests(
  extras: TransactionExtra[],
): TransactionExtraRequest[] {
  const requests: TransactionExtraRequest[] = [];
  extras.forEach(extra => {
    requests.push(ConvertTransactionExtraToRequest(extra));
  });
  return requests;
}

export function ConvertTransactionExtraToRequest(
  extra: TransactionExtra,
): TransactionExtraRequest {
  return {
    rowId: extra.rowId,
    dataKey: extra.dataKey ?? '',
    dataValue: extra.dataValue ?? '',
  };
}
