import {
  Gutter,
  Illustration,
  Stack,
  Text,
  TextVariant as TV,
} from '@emporos/components';

export function Paid(): JSX.Element {
  return (
    <Stack
      justify="center"
      align="center"
      gutter={Gutter.None}
      style={{flex: 1}}
    >
      <Illustration illustration="PaymentComplete" />
      <Text variant={TV.T2} align="center">
        All Paid!
      </Text>
    </Stack>
  );
}
