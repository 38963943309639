import {darken} from 'polished';
import {DefaultTheme} from 'styled-components';

export const Colors = [
  'primary',
  'success',
  'warning',
  'error',
  'purple',
  'aqua',
  'brown',
  'indigo',
  'gray',
  'black',
  'inverted',
] as const;
export type ColorType = (typeof Colors)[number];

export const mapColor = (
  theme: DefaultTheme,
  type?: string,
  dark?: boolean,
) => {
  switch (type) {
    case 'success':
      return dark
        ? `${darken(0.2, `${theme.colors.success}`)}`
        : theme.colors.success;
    case 'warning':
      return dark
        ? `${darken(0.2, `${theme.colors.warning}`)}`
        : theme.colors.warning;
    case 'error':
      return dark
        ? `${darken(0.25, `${theme.colors.error}`)}`
        : theme.colors.error;
    case 'purple':
      return dark
        ? `${darken(0.2, `${theme.colors.purple}`)}`
        : theme.colors.purple;
    case 'aqua':
      return dark
        ? `${darken(0.25, `${theme.colors.aqua}`)}`
        : theme.colors.aqua;
    case 'brown':
      return dark
        ? `${darken(0.25, `${theme.colors.brown}`)}`
        : theme.colors.brown;
    case 'indigo':
      return dark
        ? `${darken(0.15, `${theme.colors.indigo}`)}`
        : theme.colors.indigo;
    case 'gray':
      return dark
        ? `${darken(0.2, `${theme.colors.steel}`)}`
        : theme.colors.steel;
    case 'black':
      return dark ? theme.colors.steel : theme.colors.black;
    case 'inverted':
      return dark ? theme.colors.gray_100 : theme.colors.white;
    case 'primary':
    default:
      return dark ? theme.colors.indigo : theme.colors.blue;
  }
};
