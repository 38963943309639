/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PseCheckResultEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function PseCheckResultEnumFromJSON(json: any): PseCheckResultEnum {
    return PseCheckResultEnumFromJSONTyped(json, false);
}

export function PseCheckResultEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PseCheckResultEnum {
    return json as PseCheckResultEnum;
}

export function PseCheckResultEnumToJSON(value?: PseCheckResultEnum | null): any {
    return value as any;
}

