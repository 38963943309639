import React, {CSSProperties, memo, PropsWithoutRef, ReactNode} from 'react';
import ReactSelect, {components, IndicatorProps} from 'react-select';
import styled from 'styled-components';
import {Icons, Text, TextVariant as Variant} from './';

export interface GenericOption {
  value: string;
  label: string;
}

export interface SelectCustomProps<T extends object> {
  disabled?: boolean;
  label?: string;
  options: Array<T>;
  value: T | null;
  onChange: (value: T) => void;
  size?: SelectCustomSize;
  isError?: boolean;
  renderOption?: (value: T) => ReactNode;
  getOptionValue?: (value: T) => string;
  style?: CSSProperties;
  placeholder?: string;
}

export enum SelectCustomSize {
  Standard,
  Small,
}

const Container = styled.div<
  PropsWithoutRef<JSX.IntrinsicElements['div']> & {
    size?: SelectCustomSize;
    isError?: boolean;
    disabled?: boolean;
  }
>`
  flex: 1;
  position: relative;
  font-family: ${({theme}) => theme.typography.Main.fontFamily};
  font-weight: ${({theme}) => theme.typography.Main.fontWeight};
  line-height: ${({theme}) => theme.typography.Main.lineHeight};
  ${({disabled}) =>
    disabled && {
      cursor: 'not-allowed',
    }}

  .react-select-label {
    position: absolute;
    font-family: ${({theme}) => theme.typography.Main.fontFamily};
    font-weight: ${({theme}) => theme.typography.Main.fontWeight};
    line-height: ${({theme}) => theme.typography.Main.lineHeight};
    font-size: ${({size}) =>
      size === SelectCustomSize.Small ? '0.666em' : '15px'};
    top: ${({size}) => (size === SelectCustomSize.Small ? '-12px' : '-14px')};
    left: 16px;
    margin-left: -6px;
    padding: 2px 6px;
    background-color: ${({theme}) => theme.colors.white};
    color: ${({theme, isError}) =>
      isError ? theme.colors.error : theme.colors.blue};
  }

  .react-select__control {
    box-shadow: none;
    height: ${({size}) => (size === SelectCustomSize.Small ? '48px' : '80px')};
    border: 1px solid
      ${({theme, isError}) =>
        isError ? theme.colors.error : theme.colors.gray_200};
    background: ${({theme, disabled}) =>
      disabled ? theme.colors.gray_100 : theme.colors.white};
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 16px;
    cursor: pointer;

    &:hover {
      border: 1px solid
        ${({theme, isError}) =>
          isError ? theme.colors.error : theme.colors.black_20};
    }
  }

  .react-select__placeholder {
    color: ${({theme}) => theme.colors.black};
  }

  .react-select__value-container {
    height: ${({size}) => (size === SelectCustomSize.Small ? '48px' : '80px')};
    padding: 0;
  }

  .react-select__dropdown-indicator,
  .react-select__dropdown-indicator:hover {
    padding: 12px;
    color: ${({theme}) => theme.colors.black};
  }

  .react-select {
    &__menu {
      box-shadow: ${({theme}) => theme.shadows.shadow_4};
      border: ${({theme}) => `1px solid ${theme.colors.gray_100}`};
      border-radius: 8px;
      margin-right: 8px;
      margin-left: 8px;
      width: calc(100% - 16px);
      * {
        background-color: transparent;
      }
    }
    &__menu-list {
      padding: 0;
    }
    &__option {
      cursor: pointer;
      &:hover,
      &:active {
        background-color: ${({theme}) => theme.colors.gray_100};
      }
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }
`;

const DropdownIndicator = (props: IndicatorProps<any, any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.ChevronDown width={16} height={16} />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = () => null;

function SelectCustomComponent<T extends object>({
  label,
  options,
  onChange,
  size,
  isError,
  value,
  disabled,
  renderOption,
  getOptionValue,
  placeholder = 'Select',
  ...props
}: SelectCustomProps<T>) {
  return (
    <Container size={size} isError={isError} disabled={disabled} {...props}>
      <ReactSelect
        value={value}
        getOptionValue={getOptionValue}
        onChange={val => val && onChange(val)}
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder={<Text variant={Variant.Main}>{placeholder}</Text>}
        isDisabled={disabled}
        isSearchable={false}
        isMulti={false}
        options={options}
        formatOptionLabel={renderOption}
        components={{DropdownIndicator, IndicatorSeparator}}
      />
      {label && <span className="react-select-label">{label}</span>}
    </Container>
  );
}

export const SelectCustom = memo(
  SelectCustomComponent,
) as typeof SelectCustomComponent;
