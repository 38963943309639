import Dexie, {Table} from 'dexie';
import {TransactionConsolidate, TransactionPaymentConsolidate} from '../api';
import {applyEncryptionMiddleware, clearAllTables} from 'dexie-encrypted';
import {CryptoSettings} from 'dexie-encrypted/dist/types';

export class HiloDb extends Dexie {
  public TransactionLocaldb!: Table<TransactionConsolidate>;
  public TransactionPaymetLocaldb!: Table<TransactionPaymentConsolidate>;

  constructor(sessionId: string) {
    super('HiloDb');

    const key = new Uint8Array(32);
    key[0] = sessionId.toString().charCodeAt(0);

    this.version(1).stores({
      TransactionLocaldb:
        'transactionId, sessionId, recordStatus, status, serverTransactionID, isSynced, isDeleted',
      TransactionPaymetLocaldb:
        'transactionPaymentId,transactionId,transactionPaymentStatus,recordStatus',
    });

    applyEncryptionMiddleware(
      this, //db
      key,
      {
        TransactionLocaldb: 'NON_INDEXED_FIELDS',
        TransactionPaymetLocaldb: 'NON_INDEXED_FIELDS',
      } as CryptoSettings<typeof this>,
      clearAllTables,
    );
  }
}

export const deleteDatabase = async (sessionId: string): Promise<void> => {
  const db = new HiloDb(sessionId);
  await db.delete();
};

export const getDatabase = async (sessionId: string): Promise<HiloDb> => {
  const db = new HiloDb(sessionId);
  await db.open();
  return db;
};
