import {
  Gutter,
  IndicatorCircle,
  RemovePadding,
  Row,
  RowSidebar,
  Stack,
  Text,
  TextVariant as Variant,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import {memo, useEffect} from 'react';
import {
  behindTabBarStackingContext,
  fullHeightStack,
  TransactionLogTypes,
  transactionSynced,
  useLog,
  useSession,
  useTransactionsState,
} from '../../../';

function CompletedSidebarComponent({
  requestedUrl,
  onConfirmNavigation,
}: RouteComponentProps & {
  requestedUrl: string | null;
  onCancelNavigation(): void;
  onConfirmNavigation(): void;
}) {
  const {logUserSelection} = useLog();
  const {currentTransactionId, setCurrentTransactionId} =
    useTransactionsState();
  const {completedTransactions: transactions} = useSession();

  // autoconfirm navigation
  useEffect(() => {
    if (requestedUrl) {
      onConfirmNavigation();
    }
  }, [requestedUrl, onConfirmNavigation]);

  return transactions.length > 0 ? (
    <Stack gutter={Gutter.XL} style={fullHeightStack}>
      <Row align="center">
        <Text variant={Variant.T1}>Completed</Text>
      </Row>

      <RemovePadding style={behindTabBarStackingContext}>
        {transactions.map(iv => (
          <RowSidebar
            key={iv.transactionId}
            title={
              iv.customer
                ? `${iv.customer.firstName} ${iv.customer.lastName}`
                : 'General Sale'
            }
            // TODO: make this dynamic when we have different types of transactions
            icon="Bed"
            iconColor="indigo"
            badgeText={iv.roomNumber || ''}
            selected={iv.transactionId === currentTransactionId}
            onClick={() => {
              setCurrentTransactionId(iv.transactionId);
              logUserSelection(TransactionLogTypes.Selection, {
                item: iv.transactionId,
              });
            }}
            synced={transactionSynced(iv)}
          />
        ))}
        <div style={{height: 96}}>
          {/* TODO: just here to fix the layout */}
        </div>
      </RemovePadding>
    </Stack>
  ) : (
    <>
      <Row align="center">
        <Text variant={Variant.T1}>Completed</Text>
      </Row>
      <Stack align="center" gutter={Gutter.XL}>
        <IndicatorCircle icon="Checkmark" variant="gray" size="large" />
        <Text variant={Variant.Main} align="center">
          No completed transactions
        </Text>
      </Stack>
      <div style={{height: 70}}>{/* TODO: just here to fix the layout */}</div>
    </>
  );
}

export const CompletedSidebar = memo(CompletedSidebarComponent);
