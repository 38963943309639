import {CayanConfig, VantivConfig} from '../';
// These are the names from Emporos’ C# implementation and are only used for
// common terminology at this point of development.
const GatewayUsername = 'Test Emporos';
const GatewayId = 'CNCWEVH2';
const GatewayPassword = 'JENTY-A51NA-5LYWV-3ZAAL-QGRBW';

const sharedConfig = {
  appName: 'Emporos Testing',
  siteId: '1500000000',
};

export const cayanTestConfig: CayanConfig = {
  gatewayUsername: GatewayUsername,
  gatewayId: GatewayId,
  gatewayPassword: GatewayPassword,
  gatewayTerminal: '192.168.0.13', // gatewayIp
  processorLaneId: '9999',
  useSecureProtocol: true,
  accessToken: 'not-an-access-token',
  fetchTimeoutSeconds: 5,

  ...sharedConfig,
};

// https://emporos.visualstudio.com/Emporos/_git/Emporos?path=%2Fsrc%2FEmporos.AYS%2FEmporos.MedsToBeds.Core.Test%2FServices%2FCreditCard%2FVantiv%2FVantivCreditCardScannerTests.cs&version=GBmaster
// [enterprise]/src/Emporos.AYS/Emporos.MedsToBeds.Core.Test/Services/CreditCard/Vantiv/VantivCreditCardScannerTests.cs
//  in new PaymentSettingsViewModel construction
export const vantivTestConfig: VantivConfig = {
  gatewayUsername: '1055121',
  gatewayId: '3928907',
  gatewayPassword:
    'B45B9FAE81AF8E6D02635D59F55108E114A6106CDB186CA25D1194BD67035DB051950101',
  gatewayTerminal: '0001', // gatewayTerminalId
  partnerId: '5238', //  || '9420'
  processorLaneId: 9999,
  // triPosCloudTransactionApi = "https://triposcert.vantiv.com/api/v1"

  ...sharedConfig,
};

export const requestTransactionTestData = {
  clerkId: '123',
  orderId: '456',
  amount: 10,
  taxAmount: 0,
  qhpSubtotal: 0,
};
