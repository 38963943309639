const getDigitsFromValue = (value = '') =>
  value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const removeLeadingZeros = (number: string) =>
  number.replace(/^0+([0-9]+)/, '$1');

const addDecimalToNumber = (number: string, separator: string) => {
  const centsStartingPosition = number.length - 2;
  const dollars = removeLeadingZeros(
    number.substring(0, centsStartingPosition),
  );
  const cents = number.substring(centsStartingPosition);
  return dollars + separator + cents;
};

export const toCurrency = (value: string | number, separator = '.'): string => {
  const digits = getDigitsFromValue(
    typeof value === 'number' ? value.toFixed(2) : value,
  );
  const digitsWithPadding = digits.padStart(3, '0');
  return addDecimalToNumber(digitsWithPadding, separator);
};
