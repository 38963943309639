import {
  TransactionsApi,
  Transaction,
  TransactionPayment,
  SignatureImage,
  Configuration,
  HTTPHeaders,
  TransactionStatusEnum,
  CustomerAccountsApi,
  SignatureImagesApi,
  TransactionPaymentsApi,
  SignatureImageRequest,
  TransactionPaymentRequest,
  CustomersCustomerIdAccountsArPostRequest,
  PaymentType,
  TransactionPaymentStatusEnum,
} from '@emporos/api-enterprise';
import {ConvertTransactionToRequest} from '@emporos/api-enterprise/src/converters/TransactionConverter';
import {TransactionConsolidate} from '@emporos/pos/src';
import {OfflineTransactionPayment, TransactionPaymentConsolidate} from '../api';
import {
  ITransactionDatabaseAccess,
  ITransactionPaymentsDatabaseAccess,
} from '../localDb/IDatabaseAccess';
import {TransactionPaymentLocaldb} from '../localDb/TransactionPaymentLocaldb';
import {TransactionLocaldb} from '../localDb/TransactionsLocaldb';
import {ApiVersion} from './ApiVersion';
import {ITransactionService} from './ITransactionService';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';

const {CLIENT_API_URL} = process.env;

export class TransactionService implements ITransactionService {
  private _sessionId!: string;
  private _token!: string;
  private _version: ApiVersion = '1.5';
  private _headers!: HTTPHeaders;
  private _client!: Configuration;
  private _customerApi!: CustomerAccountsApi;
  private _transactionApi!: TransactionsApi;
  private _signaturaApi!: SignatureImagesApi;
  private _transactionPaymentsApi!: TransactionPaymentsApi;
  private _transactionDb!: ITransactionDatabaseAccess;
  private _transactionPaymentDb!: ITransactionPaymentsDatabaseAccess;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();
  private _paymentTenders: PaymentType[] = [];

  constructor(paymentTenders: PaymentType[]) {
    this._paymentTenders = paymentTenders;
  }

  initialize = (sessionId: string, token: string): void => {
    this._sessionId = sessionId;
    this._token = token;
    this._headers = {
      'Content-Type': `application/json;v=${this._version}`,
      Authorization: `Bearer ${this._token}`,
    };
    this._client = new Configuration({
      basePath: CLIENT_API_URL,
      headers: this._headers,
    });
    this._customerApi = new CustomerAccountsApi(this._client);
    this._transactionApi = new TransactionsApi(this._client);
    this._signaturaApi = new SignatureImagesApi(this._client);
    this._transactionPaymentsApi = new TransactionPaymentsApi(this._client);
    const transactiondb = new TransactionLocaldb();
    transactiondb.initialize(this._sessionId);
    this._transactionDb = transactiondb;

    const transactionPaymentDb = new TransactionPaymentLocaldb();
    transactionPaymentDb.initialize(this._sessionId);
    this._transactionPaymentDb = transactionPaymentDb;
  };

  getPaymentTenderDescriptor = (payment: TransactionPayment): string => {
    const tender = this._paymentTenders.find(
      tender => tender.id == payment.paymentTypeID,
    );
    return tender?.name || '';
  };

  syncTransactions = async (_currentTransactionId: string): Promise<void> => {
    const transaction = await this._transactionDb.get(_currentTransactionId);

    this._consoleLogger.styledLog(
      'TransactionService - Online Sync Start:',
      ConsoleLoggerVariant.PURPLE,
      transaction,
    );

    switch (transaction.status) {
      case TransactionStatusEnum.Active: {
        if (transaction.serverTransactionID == 0) {
          //Action new transaction
          await this.createTransaction(transaction as Transaction);
          return;
        }
        if (
          transaction.serverTransactionID > 0 &&
          (transaction as TransactionConsolidate).isDeleted
        ) {
          //Action Delete transaction
          await this.deleteTransaction(transaction as Transaction);
          return;
        }
        if (
          transaction.serverTransactionID > 0 &&
          !(transaction as TransactionConsolidate).isDeleted
        ) {
          if (
            transaction.serverTransactionID > 0 &&
            transaction.payments.length > 0
          ) {
            //check payments
            for (let payment of transaction.payments) {
              const paymentDb = await this._transactionPaymentDb.get(
                payment.transactionPaymentId,
              );

              if (
                (paymentDb.transactionPaymentStatus == null ||
                  paymentDb.transactionPaymentStatus == undefined) &&
                (payment.transactionPaymentStatus == null ||
                  payment.transactionPaymentStatus == undefined)
              ) {
                //Action new payment
                const paymentresponse = await this.postTransactionPayment(
                  payment,
                );
                payment = paymentresponse;
                await this._transactionPaymentDb.add(paymentresponse);
              } else {
                //Action update payment
                if (
                  (payment as TransactionPaymentConsolidate).isDeleted == true
                ) {
                  //Action update payment Delete only for non CC payments as CC Delete action happend in real time only
                  if (!paymentDb.isDeleted && !payment.gatewayPaymentID) {
                    const paymentresponse = await this.deleteTransactionPayment(
                      payment,
                    );
                    payment = paymentresponse;
                    (
                      paymentresponse as TransactionPaymentConsolidate
                    ).isDeleted = true;
                    await this._transactionPaymentDb.update(
                      paymentresponse as TransactionPaymentConsolidate,
                    );
                  }
                }
                //update payment from pending to active in recordStatus\
                if (
                  (paymentDb.recordStatus == undefined ||
                    paymentDb.recordStatus == 'Pending') &&
                  payment.recordStatus == 'Active'
                ) {
                  //update paymentStatus to approved when this condition is met
                  payment.transactionPaymentStatus =
                    TransactionPaymentStatusEnum.Approved;
                  const paymentresponse = await this.putTransactionPayment(
                    payment,
                  );
                  payment = paymentresponse;
                  await this._transactionPaymentDb.update(paymentresponse);
                }
              }
            }
            await this._transactionDb.update(transaction);
          }
        }

        if (
          transaction.signatureImage?.signatureImageId &&
          !transaction.isCompleted
        ) {
          //Action Signature
          try {
            await this.putSignatureImage(
              transaction.signatureImage,
              transaction,
            );
          } catch (error) {
            this._consoleLogger.logError(
              'TransactionService - Error Putting Signature Image:',
              error,
            );
          }
        }

        if (transaction.isCompleted) {
          //Action complete Transaction
          /**
           * before we complete the transaction with PATCH, call PUT to ensure
           * that any updates (like adding an emailReceipt value) get to the
           * database before the transaction is completed.
           */
          await this.putTransaction(transaction).then(newTransaction => {
            return this.patchTransaction(newTransaction);
          });
          return;
        } else {
          //Action Update Transaction
          await this.putTransaction(transaction);
          return;
        }
      }
      case TransactionStatusEnum.Complete:
        return;
      case TransactionStatusEnum.Error:
        alert(
          'Transaction ' +
            transaction.transactionId.toString() +
            ' has an error. Please contact support to verify its status.',
        );
      case TransactionStatusEnum.Accepted:
        transaction.isCompleted = true;
        transaction.status = TransactionStatusEnum.Complete;
        await this._transactionDb.update(transaction);
        await this.patchTransaction(transaction);
        return;

      case TransactionStatusEnum.Deleted:
        if (
          transaction.serverTransactionID > 0 &&
          (transaction as TransactionConsolidate).isDeleted
        ) {
          //Action Delete transaction
          await this.deleteTransaction(transaction as Transaction);
          return;
        }
      default:
        throw new Error('Invalid transaction status');
    }
  };

  createTransaction = async (
    transaction: Transaction,
  ): Promise<Transaction> => {
    const response =
      await this._transactionApi.clientCacheSessionsSessionIdTransactionsPost({
        sessionId: this._sessionId,
        transactionRequest: ConvertTransactionToRequest(transaction),
      });

    (response as TransactionConsolidate).isSynced = true;
    (response as TransactionConsolidate).isDeleted = false;
    (response as TransactionConsolidate).isCompleted = false;

    await this._transactionDb.update(response as TransactionConsolidate);

    return response;
  };

  putTransaction = async (
    transaction: TransactionConsolidate,
  ): Promise<Transaction> => {
    const response =
      await this._transactionApi.clientCacheSessionsSessionIdTransactionsPut({
        sessionId: this._sessionId,
        transactionRequest: ConvertTransactionToRequest(transaction),
      });

    (response as TransactionConsolidate).isSynced = transaction.isSynced;
    (response as TransactionConsolidate).isDeleted = transaction.isDeleted;
    (response as TransactionConsolidate).isCompleted = transaction.isCompleted;

    await this._transactionDb.update(response as TransactionConsolidate);

    //Todo update back database and UI
    return response;
  };

  patchTransaction = async (
    transaction: TransactionConsolidate,
  ): Promise<Transaction> => {
    const response =
      await this._transactionApi.clientCacheSessionsSessionIdTransactionsTransactionIdCompletePatch(
        {
          sessionId: this._sessionId,
          transactionId: transaction.transactionId,
        },
      );

    (response as TransactionConsolidate).isSynced = transaction.isSynced;
    (response as TransactionConsolidate).isDeleted = transaction.isDeleted;
    (response as TransactionConsolidate).isCompleted = transaction.isCompleted;

    await this._transactionDb.update(response as TransactionConsolidate);

    //Todo update back database and UI
    return response;
  };

  postTransaction = async (transaction: Transaction): Promise<Transaction> => {
    return transaction;
  };

  putTransactionPayment = async (
    payment: TransactionPayment,
  ): Promise<TransactionPayment> => {
    const response =
      await this._transactionPaymentsApi.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPut(
        {
          sessionId: this._sessionId,
          transactionId: payment.transactionId,
          transactionPaymentRequest: payment as TransactionPaymentRequest,
        },
      );

    return response;
  };

  postTransactionPayment = async (
    payment: TransactionPayment,
  ): Promise<TransactionPayment> => {
    const response =
      await this._transactionPaymentsApi.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPost(
        {
          sessionId: this._sessionId,
          transactionId: payment.transactionId,
          transactionPaymentRequest: payment as TransactionPaymentRequest,
        },
      );

    return response;
  };

  postTransactionPaymentOffline = async (
    payment: TransactionPayment,
    accountRequest?: CustomersCustomerIdAccountsArPostRequest,
  ): Promise<TransactionPayment> => {
    // TODO - we may be able to follow same logic here for PD account by checking for that paymentTypeID - if we get either, we need to make an api call to generate new account
    if (
      this.getPaymentTenderDescriptor(payment) === 'AR' &&
      payment.paymentNumber === '0' &&
      accountRequest
    ) {
      const response =
        await this._customerApi.customersCustomerIdAccountsArPost(
          accountRequest,
        );
      payment.paymentNumber = response.data?.accountNumber;
    }
    const response =
      await this._transactionPaymentsApi.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPost(
        {
          sessionId: this._sessionId,
          transactionId: payment.transactionId,
          transactionPaymentRequest: payment as TransactionPaymentRequest,
        },
      );

    return response;
  };

  putSignatureImage = async (
    signatureImage: SignatureImage,
    transaction: TransactionConsolidate,
  ): Promise<SignatureImage> => {
    const response = await this._signaturaApi.clientCacheSignaturesImagesPut({
      signatureImageRequest: signatureImage as SignatureImageRequest,
    });

    transaction.signatureImage = response;

    await this._transactionDb.update(transaction);

    return response;
  };

  deleteTransaction = async (transaction: Transaction): Promise<void> => {
    await this._transactionApi.clientCacheSessionsSessionIdTransactionsTransactionIdCancelPatch(
      {
        sessionId: this._sessionId,
        transactionId: transaction.transactionId,
      },
    );

    await this._transactionDb.delete(transaction.transactionId);
  };

  deleteTransactionPayment = async (
    transactionPayment: TransactionPayment,
  ): Promise<TransactionPayment> => {
    const response =
      await this._transactionPaymentsApi.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDelete(
        {
          sessionId: this._sessionId,
          transactionId: transactionPayment.transactionId,
          transactionPaymentId: transactionPayment.transactionPaymentId,
        },
      );

    return response;
  };

  syncTransactionsOffline = async (
    _currentTransactionId: string,
  ): Promise<Transaction> => {
    let transaction = await this._transactionDb.get(_currentTransactionId);

    this._consoleLogger.styledLog(
      'TransactionService - Offline Sync Start:',
      ConsoleLoggerVariant.PURPLE,
      transaction,
    );

    transaction.isSynced = true;

    switch (transaction.status) {
      case TransactionStatusEnum.Active: {
        if (
          transaction.serverTransactionID > 0 &&
          (transaction as TransactionConsolidate).isDeleted
        ) {
          await this.deleteTransaction(transaction as Transaction);
          return transaction;
        }
        if (
          transaction.serverTransactionID > 0 &&
          !(transaction as TransactionConsolidate).isDeleted
        ) {
          if (
            transaction.serverTransactionID > 0 &&
            transaction.payments.length > 0
          ) {
            //check payments
            for (let payment of transaction.payments) {
              const paymentDb = await this._transactionPaymentDb.get(
                payment.transactionPaymentId,
              );

              if (
                (paymentDb.transactionPaymentStatus == null ||
                  paymentDb.transactionPaymentStatus == undefined) &&
                (payment.transactionPaymentStatus == null ||
                  payment.transactionPaymentStatus == undefined)
              ) {
                //Action new payment
                const paymentresponse =
                  (payment as OfflineTransactionPayment).customerId &&
                  (payment as OfflineTransactionPayment).customerId > 0
                    ? // non-zero customerId indicates payment by AR account and we may need to create new account
                      await this.postTransactionPaymentOffline(payment, {
                        customerId: (payment as OfflineTransactionPayment)
                          .customerId,
                        siteId: transaction.saleSiteID,
                        stationId: transaction.station,
                      })
                    : await this.postTransactionPaymentOffline(payment);
                payment = paymentresponse;
                await this._transactionPaymentDb.add(paymentresponse);
              } else {
                //Action update payment Delete
                if (
                  (payment as TransactionPaymentConsolidate).isDeleted == true
                ) {
                  if (!paymentDb.isDeleted) {
                    const paymentresponse = await this.deleteTransactionPayment(
                      payment,
                    );
                    payment = paymentresponse;
                    (
                      paymentresponse as TransactionPaymentConsolidate
                    ).isDeleted = true;
                    await this._transactionPaymentDb.update(
                      paymentresponse as TransactionPaymentConsolidate,
                    );
                  }
                }
                //update payment from pending to active in recordStatus
                if (
                  paymentDb.recordStatus == 'Pending' &&
                  payment.recordStatus == 'Active'
                ) {
                  const paymentresponse = await this.putTransactionPayment(
                    payment,
                  );
                  payment = paymentresponse;
                  await this._transactionPaymentDb.update(paymentresponse);
                }
              }
            }
            await this._transactionDb.update(transaction);
          }
        }

        if (transaction.signatureImage?.signatureImageId) {
          //Action Signature
          try {
            await this.putSignatureImage(
              transaction.signatureImage,
              transaction,
            );
          } catch (error) {
            this._consoleLogger.logError(
              'TransactionService - Error Putting Signature Image:',
              error,
            );
          }
        }

        if (transaction.isCompleted) {
          //Action complete Transaction
          //Re fetch the transaction to get the latest changes
          transaction = await this._transactionDb.get(_currentTransactionId);
          await this.putTransaction(transaction)
            .then(newTransaction => {
              return this.patchTransaction(newTransaction);
            })
            .then(completed => {
              transaction = completed;
            });
          return transaction;
        } else {
          //Action Update Transaction
          await this.putTransaction(transaction);
          return transaction;
        }
      }
      case TransactionStatusEnum.Complete:
        return transaction;
      case TransactionStatusEnum.Error:
        alert(
          'Transaction ' +
            transaction.transactionId.toString() +
            ' has an error. Please contact support to verify its status.',
        );
      case TransactionStatusEnum.Accepted:
        transaction.isCompleted = true;
        transaction.status = TransactionStatusEnum.Complete;

        await this.putTransaction(transaction)
          .then(newTransaction => {
            return this.patchTransaction(newTransaction);
          })
          .then(completed => {
            transaction = completed;
          });
        return transaction;
      //return await this.putTransaction(transaction);
      case TransactionStatusEnum.Deleted:
        if (
          transaction.serverTransactionID > 0 &&
          (transaction as TransactionConsolidate).isDeleted
        ) {
          //Action Delete transaction
          await this.deleteTransaction(transaction as Transaction);
          return transaction;
        }
        return transaction;
      default:
        throw new Error('Invalid transaction status');
    }
  };
}
