import {
  Customer,
  Customer as TransactionCustomer,
  CustomerAccount as TransactionCustomerAccount,
  Transaction,
  Phone,
} from '@emporos/api-enterprise';
import {CustomerProps} from '@emporos/components';
import assert from 'assert';
import moment from 'moment';
import {formatNamePart, formatPhoneNumber} from './';

export type NormalizedCustomer = {
  id: number;
  code?: string | null;
  mrn?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dob?: Date | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  email?: string | null;
  phones: Array<Phone>;
};

export const formatCustomerName = (customer: TransactionCustomer): string =>
  formatNamePart(`${customer?.firstName || ''} ${customer?.lastName || ''}`);

export const formatCustomerAddress = ({
  address1,
  address2,
  city,
  state,
  zipCode,
}: NormalizedCustomer): string => {
  const formattedAddress2 = address2 ? ` ${formatNamePart(address2)}` : '';
  const formattedZipCode = zipCode ? ` ${zipCode}` : '';
  return [
    `${formatNamePart(address1 || '')}${formattedAddress2}`,
    formatNamePart(city || ''),
    `${state}${formattedZipCode}`,
  ].join(', ');
};

export const buildCustomerCardProps = (
  normalizedCustomer?: NormalizedCustomer,
): CustomerProps => {
  const props: CustomerProps = {
    firstName: '',
    lastName: '',
  };
  if (normalizedCustomer) {
    const {dob, firstName, lastName, mrn, phones} = normalizedCustomer;

    props.address = formatCustomerAddress(normalizedCustomer);
    props.firstName = formatNamePart(firstName || '');
    props.lastName = formatNamePart(lastName || '');

    // We conditionally add the following props so we don't add keys with
    // undefined values for commonly missing data.
    //   e.g. { dateOfBirth: undefined, mrn: undefined }.

    if (dob) {
      props.dateOfBirth = moment
        .utc(normalizedCustomer.dob)
        .format('MM/DD/YYYY');
    }

    if (phones.length && phones[0].number) {
      props.phone = formatPhoneNumber(phones[0].number);
    }

    if (mrn) {
      props.mrn = mrn;
    }
  }

  return props;
};

export const normalizeCustomer = (
  customer: TransactionCustomer | Customer,
): NormalizedCustomer => {
  return customer;
};

export const activeTransactionCustomersCodes = (
  pendingTransactions: Array<Transaction>,
): Array<string> =>
  pendingTransactions
    .filter(transaction => transaction.customer)
    .map(({customer}) => {
      assert(customer, 'Missing customer');
      assert(customer.code, 'Customer is missing customerCode');
      return customer.code;
    });

/**
 * Returns active accounts
 * @param accounts - array of type CustomerAccount that we want to filter
 * @returns an array of type CustomerAccount where recordStatus ==== 'Active'
 */
export const activeAccounts = (
  accounts: Array<TransactionCustomerAccount>,
): Array<TransactionCustomerAccount> =>
  accounts.filter(a => a.recordStatus === 'Active');

/**
 * Returns non-PD customer accounts
 * @param customer - Customer whose accounts we want to filter
 * @param includeDisabled - Boolean indication of whether this method should include accounts that are not Active in return or not
 * @param includeCreditHold - Boolean indication of whether this method should include accounts where creditHoldIndicator is ON/true in return
 * @returns an array of type CustomerAccount where accountType !== 'Payroll Deduction'
 */
export const ars = (
  customer: TransactionCustomer,
  includeDisabled: boolean,
  includeCreditHold: boolean,
): Array<TransactionCustomerAccount> => {
  if (includeDisabled === true && includeCreditHold === true) {
    return customer.accounts.filter(a => !isPD(a));
  } else if (includeDisabled === true && includeCreditHold === false) {
    return customer.accounts.filter(
      a => !isPD(a) && a.creditHoldIndicator === false,
    );
  } else if (includeDisabled === false && includeCreditHold === true) {
    return customer.accounts.filter(
      a => !isPD(a) && a.recordStatus === 'Active',
    );
  } else {
    return customer.accounts.filter(
      a =>
        !isPD(a) &&
        a.recordStatus === 'Active' &&
        a.creditHoldIndicator === false,
    );
  }
};

/**
 * Returns PD customer accounts
 * @param customer - Customer whose accounts we want to filter
 * @param includeDisabled - Boolean indication of whether this method should include accounts  that are not Active in return or not
 * @param includeCreditHold - Boolean indication of whether this method should include accounts where creditHoldIndicator is ON/true in return
 * @returns an array of type CustomerAccount where accountType === 'Payroll Deduction'
 */
export const pds = (
  customer: TransactionCustomer,
  includeDisabled: boolean,
  includeCreditHold: boolean,
): Array<TransactionCustomerAccount> => {
  if (includeDisabled === true && includeCreditHold === true) {
    return customer.accounts.filter(a => isPD(a));
  } else if (includeDisabled === true && includeCreditHold === false) {
    return customer.accounts.filter(
      a => isPD(a) && a.creditHoldIndicator === false,
    );
  } else if (includeDisabled === false && includeCreditHold === true) {
    return customer.accounts.filter(
      a => isPD(a) && a.recordStatus === 'Active',
    );
  } else {
    return customer.accounts.filter(
      a =>
        isPD(a) &&
        a.recordStatus === 'Active' &&
        a.creditHoldIndicator === false,
    );
  }
};

export const isPD = ({accountType}: TransactionCustomerAccount): boolean =>
  accountType === 'Payroll Deduction';
