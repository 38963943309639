import {
  TransactionSignature,
  TransactionSignatureRequest,
} from '@emporos/api-enterprise';

export function ConvertTransactionSignaturesToRequests(
  sigs: TransactionSignature[],
): TransactionSignatureRequest[] {
  const requests: TransactionSignatureRequest[] = [];
  sigs.forEach(sig => {
    requests.push(ConvertTransactionSignatureToRequest(sig));
  });
  return requests;
}

export function ConvertTransactionSignatureToRequest(
  sig: TransactionSignature,
): TransactionSignatureRequest {
  return {
    transactionSignatureId: sig.transactionSignatureId,
    touchless: sig.touchless,
    signatureImageId: sig.signatureImageId,
    signatureTypeId: sig.signatureTypeId,
  };
}
