import {theme} from '@emporos/components';
import {createGlobalStyle} from 'styled-components';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

export const GlobalStyles = createGlobalStyle<{theme: typeof theme}>`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  body {
    background-color: ${({theme: t}) => {
      return t.colors.gray_100;
    }};
    margin: 0;
    padding: 0;
  }

  html,
  body {
    overflow: hidden;
    position: relative;
  }

  .layout-portrait { display: none; }
  .layout-landscape { display:block; }

  @media only screen and (orientation:portrait){
      .layout-landscape { display:none; }
      .layout-portrait { display:block; }
  }

  @media only screen and (orientation:landscape){
      .layout-landscape { display:block; }
      .layout-portrait { display:none; }
  }
`;
