/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WillCallRequest,
    WillCallRequestFromJSON,
    WillCallRequestToJSON,
    WillCallResponseBodyApiResponse,
    WillCallResponseBodyApiResponseFromJSON,
    WillCallResponseBodyApiResponseToJSON,
} from '../models';

export interface ClientWillCallPutRequest {
    siteId?: number;
    stationId?: number;
    willCallRequest?: WillCallRequest;
}

/**
 * 
 */
export class WillCallApi extends runtime.BaseAPI {

    /**
     * Relays will-call to enterprise.
     */
    async clientWillCallPutRaw(requestParameters: ClientWillCallPutRequest): Promise<runtime.ApiResponse<WillCallResponseBodyApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.siteId !== undefined) {
            queryParameters['siteId'] = requestParameters.siteId;
        }

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/will-call`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WillCallRequestToJSON(requestParameters.willCallRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WillCallResponseBodyApiResponseFromJSON(jsonValue));
    }

    /**
     * Relays will-call to enterprise.
     */
    async clientWillCallPut(requestParameters: ClientWillCallPutRequest): Promise<WillCallResponseBodyApiResponse> {
        const response = await this.clientWillCallPutRaw(requestParameters);
        return await response.value();
    }

}
