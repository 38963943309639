import {BarcodeComponent} from '@emporos/api-enterprise';
import {orderBy} from 'lodash';

type Complete<T> = {
  [P in keyof Required<T>]: Required<T>[P] extends null | infer X
    ? X
    : Required<T>[P];
};

export type CompleteBarcodeComponent = Complete<BarcodeComponent>;

const IDENTIFICATION_BARCODE_COMPONENT_NAME = 'HoneywellXenon1900DriverLicense';
const PREFIX = 'prefix';
export interface IdBarcodeResult {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  address1?: string;
  address2?: string;
  city?: string;
  zip?: string;
  expirationDate?: string;
  state?: string;
  idNumber?: string;
}

export function decodeID(
  barcodeComponents: CompleteBarcodeComponent[],
  barcode: string,
): IdBarcodeResult {
  if (!barcode.startsWith('$')) {
    return decodeUsDrivingLicense(barcode);
  }

  let prefix = '';

  barcodeComponents = orderBy(barcodeComponents, ['componentIndex'], ['asc']);

  const delimiterComponents = barcodeComponents.filter(
    barcodeComponent => barcodeComponent.componentIndex === 0,
  );

  const firstDelimiterComponent = delimiterComponents[0];

  if (!firstDelimiterComponent) {
    throw Error('Expecting delimiter BarcodeComponent for ID Scanning.');
  }
  if (!barcode.includes(firstDelimiterComponent.componentMask)) {
    throw Error(
      `Invalid ID barcode format: Expected to be ${firstDelimiterComponent.componentMask} seperated.`,
    );
  }

  const delimiter = firstDelimiterComponent.componentMask;

  barcodeComponents.forEach(barcodeComponent => {
    if (barcodeComponent.componentName.toLowerCase() === PREFIX) {
      prefix = barcodeComponent.componentMask;
    }
  });

  barcodeComponents = barcodeComponents.filter(
    barcodeComponent =>
      barcodeComponent.componentMask != firstDelimiterComponent.componentMask,
  );

  const barcodeSplit = barcode.split(delimiter);
  const components: Array<string> = [];

  barcodeSplit.forEach(component => {
    let componentsAdded = false;
    delimiterComponents.forEach(delimiterComponent => {
      const tmpDelimiter = delimiterComponent.componentMask;
      const tmpComponents = component.split(tmpDelimiter);
      if (tmpComponents.length > 1) {
        componentsAdded = true;
        tmpComponents.forEach(tempComponent => {
          components.push(tempComponent);
          components.push(tmpDelimiter);
        });
        components.pop();
      }
    });
    if (!componentsAdded) {
      components.push(component);
    }
  });

  const leftBracket = prefix.indexOf('[') + 1;
  const rightBracket = prefix.indexOf(']');
  prefix = prefix.substring(leftBracket, rightBracket);
  if (prefix === '\\\\') {
    prefix = '\\';
  }

  const finalComponents: Array<string> = [];
  finalComponents.push('');
  if (barcode.toLowerCase().indexOf(prefix.toLowerCase()) > -1) {
    finalComponents.push(prefix);
  } else {
    finalComponents.push('');
  }

  components.forEach(component => {
    if (component.toLowerCase().indexOf(prefix.toLowerCase()) > -1) {
      const index = component.toLowerCase().indexOf(prefix.toLowerCase());
      let copyComponent = component;
      if (index > 0) {
        copyComponent = component.substring(index);
      }
      const componentValue = copyComponent
        .toUpperCase()
        .replace(prefix.toUpperCase(), '');
      if (
        !barcodeComponents.some(
          barcodeComponent =>
            barcodeComponent.barcodeName ===
            IDENTIFICATION_BARCODE_COMPONENT_NAME,
        )
      )
        finalComponents.push(componentValue);
    } else {
      finalComponents.push(component);
    }
  });

  return barcodeComponents
    .filter(barcodeComponent => barcodeComponent.componentIndex > 0)
    .reduce((accumulator, barcodeComponent) => {
      switch (barcodeComponent.componentName) {
        case 'Address1':
          accumulator.address1 =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Address2':
          accumulator.address2 =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'City':
          accumulator.city = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'State':
          accumulator.state = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Gender':
          accumulator.gender = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'ExpirationDate':
          accumulator.expirationDate =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Birthdate':
          accumulator.dateOfBirth =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'Zipcode':
          accumulator.zip = finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'FirstName':
          accumulator.firstName =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'MiddleName':
          accumulator.middleName =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'LastName':
          accumulator.lastName =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        case 'DLnumber':
          accumulator.idNumber =
            finalComponents[barcodeComponent.componentIndex];
          return accumulator;
        default:
          return accumulator;
      }
    }, {} as IdBarcodeResult);
}

const decodeUsDrivingLicense = (barcode: string): IdBarcodeResult => {
  const result: IdBarcodeResult = {};

  result.idNumber = identifierRegex(barcode, 'DAQ')?.slice(3);
  result.firstName = identifierRegex(barcode, 'DAC')?.slice(3);
  result.middleName = identifierRegex(barcode, 'DAD')?.slice(3);
  result.lastName = identifierRegex(barcode, 'DCS')?.slice(3);
  result.gender = identifierRegex(barcode, 'DBC')?.slice(3) === '1' ? 'M' : 'F';
  result.address1 = identifierRegex(barcode, 'DAG')?.slice(3);
  result.address2 = identifierRegex(barcode, 'DAH')?.slice(3);
  result.city = identifierRegex(barcode, 'DAI')?.slice(3);
  result.zip = identifierRegex(barcode, 'DAK')?.slice(3).trim();
  result.state = identifierRegex(barcode, 'DAJ')?.slice(3);

  const dateOfBirth = identifierRegex(barcode, 'DBB')?.slice(3);
  result.dateOfBirth = dateOfBirth ? formatDate(dateOfBirth) : undefined;

  const expirationDate = identifierRegex(barcode, 'DBA')?.slice(3);
  result.expirationDate = expirationDate
    ? formatDate(expirationDate)
    : undefined;

  return result;
};

const identifierRegex = (
  raw: string,
  identifier: string,
): string | undefined => {
  const regex = `(${identifier})(.+)\\b`;
  return raw.match(regex)?.[0];
};

const formatDate = (value: string): string =>
  `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
