/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransactionPaymentStatusEnum,
    TransactionPaymentStatusEnumFromJSON,
    TransactionPaymentStatusEnumFromJSONTyped,
    TransactionPaymentStatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionPaymentRequest
 */
export interface TransactionPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    transactionPaymentId: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    serverPaymentID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    paymentTypeID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    serverTransactionID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    paymentNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    amountReturned?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    gatewayPaymentID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    cardMember?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TransactionPaymentRequest
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    miscTypeID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    miscAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    recordStatus: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPaymentRequest
     */
    changeIndicator: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    approvalCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    relatedDetailID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    relatedDetailID2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    purCustReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    cashBack?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    cardToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    avsStreetMatch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    avsZipMatch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    cvMatch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    zipcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    state?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    cvNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    signatureStatus?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    signatureProcessor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    sigType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    swipeType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPaymentRequest
     */
    qhpPartial: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    qhpRxQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    qhpRxAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    qhpOtherQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    qhpOtherAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    fsaRequestedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    fsaApprovedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    fsaBalanceAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPaymentRequest
     */
    voiceAuthIndicator: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    hostProcessor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    smartPayProcessor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    ccofTokenPnRef?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    smartPayUserID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    arAccountTypeID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    purchaseOrderNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    ccofPaymentInfoID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    originalPaymentID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionPaymentRequest
     */
    noSigCapRequiredLImitAmount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPaymentRequest
     */
    autoChargeIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionPaymentRequest
     */
    sigCapSentToProcessor: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    jsonPaymentProcessorResponse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentRequest
     */
    dataVersion?: string | null;
    /**
     * 
     * @type {TransactionPaymentStatusEnum}
     * @memberof TransactionPaymentRequest
     */
    transactionPaymentStatus?: TransactionPaymentStatusEnum;
}

export function TransactionPaymentRequestFromJSON(json: any): TransactionPaymentRequest {
    return TransactionPaymentRequestFromJSONTyped(json, false);
}

export function TransactionPaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPaymentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionPaymentId': json['transactionPaymentId'],
        'serverPaymentID': !exists(json, 'serverPaymentID') ? undefined : json['serverPaymentID'],
        'paymentTypeID': !exists(json, 'paymentTypeID') ? undefined : json['paymentTypeID'],
        'serverTransactionID': !exists(json, 'serverTransactionID') ? undefined : json['serverTransactionID'],
        'paymentNumber': !exists(json, 'paymentNumber') ? undefined : json['paymentNumber'],
        'amount': json['amount'],
        'amountReturned': !exists(json, 'amountReturned') ? undefined : json['amountReturned'],
        'gatewayPaymentID': !exists(json, 'gatewayPaymentID') ? undefined : json['gatewayPaymentID'],
        'cardMember': !exists(json, 'cardMember') ? undefined : json['cardMember'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (json['expirationDate'] === null ? null : new Date(json['expirationDate'])),
        'miscTypeID': !exists(json, 'misc_TypeID') ? undefined : json['misc_TypeID'],
        'miscAmount': !exists(json, 'misc_Amount') ? undefined : json['misc_Amount'],
        'recordStatus': json['recordStatus'],
        'changeIndicator': json['changeIndicator'],
        'approvalCode': !exists(json, 'approvalCode') ? undefined : json['approvalCode'],
        'relatedDetailID': !exists(json, 'relatedDetailID') ? undefined : json['relatedDetailID'],
        'relatedDetailID2': !exists(json, 'relatedDetailID2') ? undefined : json['relatedDetailID2'],
        'purCustReference': !exists(json, 'purCustReference') ? undefined : json['purCustReference'],
        'cashBack': !exists(json, 'cashBack') ? undefined : json['cashBack'],
        'cardToken': !exists(json, 'cardToken') ? undefined : json['cardToken'],
        'avsStreetMatch': !exists(json, 'avsStreetMatch') ? undefined : json['avsStreetMatch'],
        'avsZipMatch': !exists(json, 'avsZipMatch') ? undefined : json['avsZipMatch'],
        'cvMatch': !exists(json, 'cvMatch') ? undefined : json['cvMatch'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'cvNumber': !exists(json, 'cvNumber') ? undefined : json['cvNumber'],
        'signatureStatus': !exists(json, 'signatureStatus') ? undefined : json['signatureStatus'],
        'signatureProcessor': !exists(json, 'signatureProcessor') ? undefined : json['signatureProcessor'],
        'sigType': !exists(json, 'sigType') ? undefined : json['sigType'],
        'swipeType': !exists(json, 'swipeType') ? undefined : json['swipeType'],
        'qhpPartial': json['qhpPartial'],
        'qhpRxQuantity': !exists(json, 'qhpRxQuantity') ? undefined : json['qhpRxQuantity'],
        'qhpRxAmount': !exists(json, 'qhpRxAmount') ? undefined : json['qhpRxAmount'],
        'qhpOtherQuantity': !exists(json, 'qhpOtherQuantity') ? undefined : json['qhpOtherQuantity'],
        'qhpOtherAmount': !exists(json, 'qhpOtherAmount') ? undefined : json['qhpOtherAmount'],
        'fsaRequestedAmount': !exists(json, 'fsaRequestedAmount') ? undefined : json['fsaRequestedAmount'],
        'fsaApprovedAmount': !exists(json, 'fsaApprovedAmount') ? undefined : json['fsaApprovedAmount'],
        'fsaBalanceAmount': json['fsaBalanceAmount'],
        'voiceAuthIndicator': json['voiceAuthIndicator'],
        'hostProcessor': !exists(json, 'hostProcessor') ? undefined : json['hostProcessor'],
        'smartPayProcessor': !exists(json, 'smartPayProcessor') ? undefined : json['smartPayProcessor'],
        'ccofTokenPnRef': !exists(json, 'ccofTokenPnRef') ? undefined : json['ccofTokenPnRef'],
        'smartPayUserID': !exists(json, 'smartPayUserID') ? undefined : json['smartPayUserID'],
        'arAccountTypeID': !exists(json, 'arAccountTypeID') ? undefined : json['arAccountTypeID'],
        'purchaseOrderNumber': !exists(json, 'purchaseOrderNumber') ? undefined : json['purchaseOrderNumber'],
        'ccofPaymentInfoID': !exists(json, 'ccofPaymentInfoID') ? undefined : json['ccofPaymentInfoID'],
        'originalPaymentID': !exists(json, 'originalPaymentID') ? undefined : json['originalPaymentID'],
        'noSigCapRequiredLImitAmount': !exists(json, 'noSigCapRequiredLImitAmount') ? undefined : json['noSigCapRequiredLImitAmount'],
        'autoChargeIndicator': json['autoChargeIndicator'],
        'sigCapSentToProcessor': json['sigCapSentToProcessor'],
        'jsonPaymentProcessorResponse': !exists(json, 'jsonPaymentProcessorResponse') ? undefined : json['jsonPaymentProcessorResponse'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
        'transactionPaymentStatus': !exists(json, 'transactionPaymentStatus') ? undefined : TransactionPaymentStatusEnumFromJSON(json['transactionPaymentStatus']),
    };
}

export function TransactionPaymentRequestToJSON(value?: TransactionPaymentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionPaymentId': value.transactionPaymentId,
        'serverPaymentID': value.serverPaymentID,
        'paymentTypeID': value.paymentTypeID,
        'serverTransactionID': value.serverTransactionID,
        'paymentNumber': value.paymentNumber,
        'amount': value.amount,
        'amountReturned': value.amountReturned,
        'gatewayPaymentID': value.gatewayPaymentID,
        'cardMember': value.cardMember,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'misc_TypeID': value.miscTypeID,
        'misc_Amount': value.miscAmount,
        'recordStatus': value.recordStatus,
        'changeIndicator': value.changeIndicator,
        'approvalCode': value.approvalCode,
        'relatedDetailID': value.relatedDetailID,
        'relatedDetailID2': value.relatedDetailID2,
        'purCustReference': value.purCustReference,
        'cashBack': value.cashBack,
        'cardToken': value.cardToken,
        'avsStreetMatch': value.avsStreetMatch,
        'avsZipMatch': value.avsZipMatch,
        'cvMatch': value.cvMatch,
        'street': value.street,
        'zipcode': value.zipcode,
        'state': value.state,
        'cvNumber': value.cvNumber,
        'signatureStatus': value.signatureStatus,
        'signatureProcessor': value.signatureProcessor,
        'sigType': value.sigType,
        'swipeType': value.swipeType,
        'qhpPartial': value.qhpPartial,
        'qhpRxQuantity': value.qhpRxQuantity,
        'qhpRxAmount': value.qhpRxAmount,
        'qhpOtherQuantity': value.qhpOtherQuantity,
        'qhpOtherAmount': value.qhpOtherAmount,
        'fsaRequestedAmount': value.fsaRequestedAmount,
        'fsaApprovedAmount': value.fsaApprovedAmount,
        'fsaBalanceAmount': value.fsaBalanceAmount,
        'voiceAuthIndicator': value.voiceAuthIndicator,
        'hostProcessor': value.hostProcessor,
        'smartPayProcessor': value.smartPayProcessor,
        'ccofTokenPnRef': value.ccofTokenPnRef,
        'smartPayUserID': value.smartPayUserID,
        'arAccountTypeID': value.arAccountTypeID,
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'ccofPaymentInfoID': value.ccofPaymentInfoID,
        'originalPaymentID': value.originalPaymentID,
        'noSigCapRequiredLImitAmount': value.noSigCapRequiredLImitAmount,
        'autoChargeIndicator': value.autoChargeIndicator,
        'sigCapSentToProcessor': value.sigCapSentToProcessor,
        'jsonPaymentProcessorResponse': value.jsonPaymentProcessorResponse,
        'dataVersion': value.dataVersion,
        'transactionPaymentStatus': TransactionPaymentStatusEnumToJSON(value.transactionPaymentStatus),
    };
}


