/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionTax
 */
export interface TransactionTax {
    /**
     * 
     * @type {string}
     * @memberof TransactionTax
     */
    transactionTaxId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionTax
     */
    transactionId: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionTax
     */
    taxID: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionTax
     */
    serverTransactionID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionTax
     */
    taxAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionTax
     */
    taxRateID: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionTax
     */
    taxRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionTax
     */
    taxableSubTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionTax
     */
    taxGroupID?: number | null;
}

export function TransactionTaxFromJSON(json: any): TransactionTax {
    return TransactionTaxFromJSONTyped(json, false);
}

export function TransactionTaxFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionTax {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionTaxId': json['transactionTaxId'],
        'transactionId': json['transactionId'],
        'taxID': json['taxID'],
        'serverTransactionID': !exists(json, 'serverTransactionID') ? undefined : json['serverTransactionID'],
        'taxAmount': !exists(json, 'taxAmount') ? undefined : json['taxAmount'],
        'taxRateID': json['taxRateID'],
        'taxRate': !exists(json, 'taxRate') ? undefined : json['taxRate'],
        'taxableSubTotal': !exists(json, 'taxableSubTotal') ? undefined : json['taxableSubTotal'],
        'taxGroupID': !exists(json, 'taxGroupID') ? undefined : json['taxGroupID'],
    };
}

export function TransactionTaxToJSON(value?: TransactionTax | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionTaxId': value.transactionTaxId,
        'transactionId': value.transactionId,
        'taxID': value.taxID,
        'serverTransactionID': value.serverTransactionID,
        'taxAmount': value.taxAmount,
        'taxRateID': value.taxRateID,
        'taxRate': value.taxRate,
        'taxableSubTotal': value.taxableSubTotal,
        'taxGroupID': value.taxGroupID,
    };
}


