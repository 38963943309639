import React, {memo, PropsWithoutRef} from 'react';
import styled from 'styled-components';
import {Illustrations} from './';

export enum IllustrationSize {
  Default = 'Default',
  Large = 'Large',
}

const illustrationSize = (size: IllustrationSize) => {
  switch (size) {
    case IllustrationSize.Large:
      return `392px`;
    case IllustrationSize.Default:
    default:
      return `164px`;
  }
};

export interface IllustrationProps {
  illustration: keyof typeof Illustrations;
  size?: IllustrationSize;
}
const Wrapper = styled.div<{size: IllustrationSize}>`
  display: inline-block;
  width: ${({size}) => illustrationSize(size)};
  svg {
    display: block;
  }
`;

export const Illustration = memo(
  ({
    size = IllustrationSize.Default,
    ...props
  }: IllustrationProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const IllustrationItem = Illustrations[props.illustration];
    return (
      <Wrapper size={size} data-testid={props.illustration} {...props}>
        <IllustrationItem />
      </Wrapper>
    );
  },
);
