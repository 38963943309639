import {PaymentType} from '@emporos/api-enterprise';
import {Row, Select, Stack} from '@emporos/components';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {BillBoard, PaymentRef as Handle} from './';

interface Props {
  amount: number;
  setAmount: (v: number) => void;
  totalDue: number;
  setPaymentTypeId: (v: number) => void;
  setPaymentTypeDesc: (v: string) => void;
  udps: Array<PaymentType>;
  onChangeActivePayment(): void;
}

export const UDPayment = forwardRef<Handle, Props>(function UDPayment(
  {
    amount,
    setAmount,
    totalDue,
    setPaymentTypeId,
    setPaymentTypeDesc,
    udps,
    onChangeActivePayment,
  },
  ref,
): JSX.Element {
  const [id, setId] = useState<number | null>(null);

  useImperativeHandle(ref, () => ({
    async onConfirmPayment() {
      return null;
    },
    isAcceptablePayment() {
      return !!id;
    },
  }));

  useEffect(() => {
    onChangeActivePayment();
  }, [id]);

  return (
    <Stack style={{flex: 1}}>
      <BillBoard value={amount} onChange={setAmount} totalDue={totalDue} />
      <Row style={{marginTop: 36}}>
        <Select
          name="udp"
          options={udps.map(t => t?.id)}
          optionsText={udps.map(t => t?.description || 'Undefined')}
          value={id}
          onChangeValue={value => {
            setPaymentTypeId(value);
            setPaymentTypeDesc(
              udps.find(t => t?.id === value)?.description || 'Undefined',
            );
            setId(value);
          }}
          label="User Defined Payments"
        />
      </Row>
    </Stack>
  );
});
