import {RouteComponentProps} from '@reach/router';
import {memo, PropsWithChildren} from 'react';
import {CustomerSearchProvider} from '../../../../../';
import {CustomerPaymentIntegration} from './';

function CustomerPayment(
  props: PropsWithChildren<RouteComponentProps>,
): JSX.Element {
  return (
    <CustomerSearchProvider>
      <CustomerPaymentIntegration {...props} />
    </CustomerSearchProvider>
  );
}

export const CustomerPaymentPage = memo(CustomerPayment);
