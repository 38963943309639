/**
 * This function converts a basic regular expression into a one that will
 * match strings that could eventually match the input expression in the
 * future. e.g.
 *
 *   let r = /^abc$/
 *
 *   r.test("a");   -> false
 *   r.test("ab");  -> false
 *   r.test("abc"); -> true
 *
 *   let p = toPartialMatchRegexp(r)
 *
 *   p.test("a")    -> true
 *   p.test("ab")   -> true
 *   p.test("abc")  -> true
 *   p.test("bc")   -> false
 *
 * Originally lifted from:
 * https://stackoverflow.com/questions/22483214/regex-check-if-input-still-has-chances-to-become-matching
 *
 * @param regexp the regular expression to convert to a partial-match expression
 */
export function toPartialMatchRegexp(regexp: RegExp): RegExp {
  const {source} = regexp;

  let i = 0;

  function process() {
    let result = '';
    let tmp: RegExp | RegExpExecArray | number | null;

    function appendRaw(n: number) {
      result += source.substr(i, n);
      i += n;
    }

    function appendOptional(n: number) {
      result += '(?:' + source.substr(i, n) + '|$)';
      i += n;
    }

    while (i < source.length) {
      switch (source[i]) {
        case '\\':
          switch (source[i + 1]) {
            case 'c':
              appendOptional(3);
              break;

            case 'x':
              appendOptional(4);
              break;

            case 'u':
              if (regexp.unicode) {
                if (source[i + 2] === '{') {
                  appendOptional(source.indexOf('}', i) - i + 1);
                } else {
                  appendOptional(6);
                }
              } else {
                appendOptional(2);
              }
              break;

            case 'p':
            case 'P':
              if (regexp.unicode) {
                appendOptional(source.indexOf('}', i) - i + 1);
              } else {
                appendOptional(2);
              }
              break;

            case 'k':
              appendOptional(source.indexOf('>', i) - i + 1);
              break;

            default:
              appendOptional(2);
              break;
          }
          break;

        case '[':
          tmp = /\[(?:\\.|.)*?\]/g;
          tmp.lastIndex = i;
          tmp = tmp.exec(source);
          appendOptional((tmp as RegExpExecArray)[0].length);
          break;

        case '|':
        case '^':
        case '$':
        case '*':
        case '+':
        case '?':
          appendRaw(1);
          break;

        case '{':
          tmp = /\{\d+,?\d*\}/g;
          tmp.lastIndex = i;
          tmp = tmp.exec(source);
          if (tmp) {
            appendRaw(tmp[0].length);
          } else {
            appendOptional(1);
          }
          break;

        case '(':
          if (source[i + 1] == '?') {
            switch (source[i + 2]) {
              case ':':
                result += '(?:';
                i += 3;
                result += process() + '|$)';
                break;

              case '=':
                result += '(?=';
                i += 3;
                result += process() + ')';
                break;

              case '!':
                tmp = i;
                i += 3;
                process();
                result += source.substr(tmp, i - tmp);
                break;

              case '<':
                switch (source[i + 3]) {
                  case '=':
                  case '!':
                    tmp = i;
                    i += 4;
                    process();
                    result += source.substr(tmp, i - tmp);
                    break;

                  default:
                    appendRaw(source.indexOf('>', i) - i + 1);
                    result += process() + '|$)';
                    break;
                }
                break;
            }
          } else {
            appendRaw(1);
            result += process() + '|$)';
          }
          break;

        case ')':
          ++i;
          return result;

        default:
          appendOptional(1);
          break;
      }
    }

    return result;
  }

  return new RegExp(process(), regexp.flags);
}
