/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WillCallRequest
 */
export interface WillCallRequest {
    /**
     * 
     * @type {string}
     * @memberof WillCallRequest
     */
    rx?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WillCallRequest
     */
    fill?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WillCallRequest
     */
    partial?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WillCallRequest
     */
    customerCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WillCallRequest
     */
    mrn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WillCallRequest
     */
    site?: string | null;
}

export function WillCallRequestFromJSON(json: any): WillCallRequest {
    return WillCallRequestFromJSONTyped(json, false);
}

export function WillCallRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WillCallRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rx': !exists(json, 'rx') ? undefined : json['rx'],
        'fill': !exists(json, 'fill') ? undefined : json['fill'],
        'partial': !exists(json, 'partial') ? undefined : json['partial'],
        'customerCode': !exists(json, 'customerCode') ? undefined : json['customerCode'],
        'mrn': !exists(json, 'mrn') ? undefined : json['mrn'],
        'site': !exists(json, 'site') ? undefined : json['site'],
    };
}

export function WillCallRequestToJSON(value?: WillCallRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rx': value.rx,
        'fill': value.fill,
        'partial': value.partial,
        'customerCode': value.customerCode,
        'mrn': value.mrn,
        'site': value.site,
    };
}


