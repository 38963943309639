import {
  CayanConfig as Config,
  fetchWithTimeout,
  ICancelTransactionResponse,
  ICheckStatusResponse,
  IStartOrderResponse,
  IInitiateTransactionResult,
  IAddItemParameters,
  IAddItemResponse,
} from './';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function makeQueryString(obj: any): string {
  return Object.keys(obj)
    .map((key: string) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    })
    .join('&');
}

export class CayanClient {
  config: Config;
  constructor(config: Config) {
    this.config = config;
  }

  static create(config: Config): CayanClient {
    return new CayanClient(config);
  }

  async _fetch<T>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: Object,
  ): Promise<T> {
    const {useSecureProtocol, gatewayTerminal} = this.config;
    const queryString = makeQueryString(params);
    const _useSecureProtocol =
      typeof useSecureProtocol === 'boolean' ? useSecureProtocol : true;
    const port = /\.com$/.test(gatewayTerminal)
      ? ''
      : _useSecureProtocol
      ? ':8443'
      : ':8080';
    const url = _useSecureProtocol
      ? `https://${gatewayTerminal}${port}/${path}?${queryString}`
      : `http://${gatewayTerminal}${port}/${path}?${queryString}`;
    return fetchWithTimeout(url, {
      timeout: this.config.fetchTimeoutSeconds * 1000,
    })
      .then(r => r.json())
      .catch(e => e);
  }

  /**
   * Cancels the current order and resets the screen back to an idle state
   */
  cancel(): Promise<ICancelTransactionResponse> {
    return this._fetch('v2/pos', {Action: 'Cancel', Format: 'JSON'});
  }

  /**
   * Check the status of the CED
   */
  checkStatus(): Promise<ICheckStatusResponse | Error> {
    return this._fetch('v2/pos', {Action: 'Status', Format: 'JSON'});
  }

  /**
   * Start an order
   * @param Order - The order or transaction number associated with the transaction.
   */
  startOrder(Order: string): Promise<IStartOrderResponse | Error> {
    return this._fetch('v2/pos', {
      Action: 'StartOrder',
      Format: 'JSON',
      Order,
    });
  }

  /**
   * Check the status of the CED
   * @param TransportKey - The transport key from the StageTransaction response
   */
  initiateTransaction(
    TransportKey: string,
  ): Promise<IInitiateTransactionResult | Error> {
    return this._fetch('v2/pos', {TransportKey, Format: 'JSON'});
  }

  /**
   * Add an item to the screen. Also allows updating order totals.
   * @param item - The item to be added to the order. See IAddItemParameters
   */
  addItem(item: IAddItemParameters): Promise<IAddItemResponse | Error> {
    return this._fetch('v2/pos', {Action: 'AddItem', Format: 'JSON', ...item});
  }
}
