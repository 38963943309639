/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StationResponse
 */
export interface StationResponse {
    /**
     * 
     * @type {number}
     * @memberof StationResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StationResponse
     */
    stationId: number;
    /**
     * 
     * @type {number}
     * @memberof StationResponse
     */
    siteId: number;
    /**
     * 
     * @type {number}
     * @memberof StationResponse
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof StationResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StationResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof StationResponse
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof StationResponse
     */
    paymentDeviceAddress: string;
    /**
     * 
     * @type {string}
     * @memberof StationResponse
     */
    paymentDevicePort: string;
}

export function StationResponseFromJSON(json: any): StationResponse {
    return StationResponseFromJSONTyped(json, false);
}

export function StationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stationId': json['stationId'],
        'siteId': json['siteId'],
        'number': json['number'],
        'name': json['name'],
        'description': json['description'],
        'type': json['type'],
        'paymentDeviceAddress': json['paymentDeviceAddress'],
        'paymentDevicePort': json['paymentDevicePort'],
    };
}

export function StationResponseToJSON(value?: StationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stationId': value.stationId,
        'siteId': value.siteId,
        'number': value.number,
        'name': value.name,
        'description': value.description,
        'type': value.type,
        'paymentDeviceAddress': value.paymentDeviceAddress,
        'paymentDevicePort': value.paymentDevicePort,
    };
}


