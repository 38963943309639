/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TransactionPayment,
    TransactionPaymentFromJSON,
    TransactionPaymentToJSON,
    TransactionPaymentRequest,
    TransactionPaymentRequestFromJSON,
    TransactionPaymentRequestToJSON,
    ValidationFailure,
    ValidationFailureFromJSON,
    ValidationFailureToJSON,
} from '../models';

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGetRequest {
    sessionId: string;
    transactionId: string;
    queryProcessor?: boolean;
}

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRequest {
    sessionId: string;
    transactionId: string;
    transactionPaymentRequest?: TransactionPaymentRequest;
}

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRequest {
    sessionId: string;
    transactionId: string;
    transactionPaymentRequest?: TransactionPaymentRequest;
}

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRequest {
    sessionId: string;
    transactionId: string;
    transactionPaymentId: string;
}

export interface ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGetRequest {
    sessionId: string;
    transactionId: string;
    transactionPaymentId: string;
    queryProcessor?: boolean;
}

/**
 * 
 */
export class TransactionPaymentsApi extends runtime.BaseAPI {

    /**
     * queryProcessor is ignored for all non-credit card payments, or when no cc gateway is configured.
     * Gets a list of transactionPayments for an Transaction
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGetRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGetRequest): Promise<runtime.ApiResponse<Array<TransactionPayment>>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGet.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.queryProcessor !== undefined) {
            queryParameters['queryProcessor'] = requestParameters.queryProcessor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransactionPaymentFromJSON));
    }

    /**
     * queryProcessor is ignored for all non-credit card payments, or when no cc gateway is configured.
     * Gets a list of transactionPayments for an Transaction
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGet(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGetRequest): Promise<Array<TransactionPayment>> {
        const response = await this.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Inserts an transactionPayment
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRequest): Promise<runtime.ApiResponse<TransactionPayment>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPost.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionPaymentRequestToJSON(requestParameters.transactionPaymentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionPaymentFromJSON(jsonValue));
    }

    /**
     * Inserts an transactionPayment
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPost(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRequest): Promise<TransactionPayment> {
        const response = await this.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an transactionPayment
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRequest): Promise<runtime.ApiResponse<TransactionPayment>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPut.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionPaymentRequestToJSON(requestParameters.transactionPaymentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionPaymentFromJSON(jsonValue));
    }

    /**
     * Updates an transactionPayment
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPut(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRequest): Promise<TransactionPayment> {
        const response = await this.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an transactionPayment
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRequest): Promise<runtime.ApiResponse<TransactionPayment>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDelete.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDelete.');
        }

        if (requestParameters.transactionPaymentId === null || requestParameters.transactionPaymentId === undefined) {
            throw new runtime.RequiredError('transactionPaymentId','Required parameter requestParameters.transactionPaymentId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments/{transactionPaymentId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"transactionPaymentId"}}`, encodeURIComponent(String(requestParameters.transactionPaymentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionPaymentFromJSON(jsonValue));
    }

    /**
     * Deletes an transactionPayment
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDelete(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRequest): Promise<TransactionPayment> {
        const response = await this.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * queryProcessor is ignored for all non-credit card payments, or when no cc gateway is configured.
     * Gets an transactionPayment by session id, transaction id  and transactionPayment id
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGetRaw(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGetRequest): Promise<runtime.ApiResponse<TransactionPayment>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGet.');
        }

        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGet.');
        }

        if (requestParameters.transactionPaymentId === null || requestParameters.transactionPaymentId === undefined) {
            throw new runtime.RequiredError('transactionPaymentId','Required parameter requestParameters.transactionPaymentId was null or undefined when calling clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.queryProcessor !== undefined) {
            queryParameters['queryProcessor'] = requestParameters.queryProcessor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments/{transactionPaymentId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"transactionPaymentId"}}`, encodeURIComponent(String(requestParameters.transactionPaymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionPaymentFromJSON(jsonValue));
    }

    /**
     * queryProcessor is ignored for all non-credit card payments, or when no cc gateway is configured.
     * Gets an transactionPayment by session id, transaction id  and transactionPayment id
     */
    async clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGet(requestParameters: ClientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGetRequest): Promise<TransactionPayment> {
        const response = await this.clientCacheSessionsSessionIdTransactionsTransactionIdPaymentsTransactionPaymentIdGetRaw(requestParameters);
        return await response.value();
    }

}
