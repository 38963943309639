import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  IllustrationSize,
  ScrollContainer,
  Stack,
  Text,
  TextVariant,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import {memo} from 'react';

function SettingsSupportComponent(_: RouteComponentProps): JSX.Element {
  return (
    <Stack gutter={Gutter.None} style={{height: '100%'}}>
      <Header title="Support" />
      <ScrollContainer style={{paddingBottom: 16}}>
        <Stack justify="center" align="center" style={{height: '100%'}}>
          <Illustration
            illustration="LargeUnderConstruction"
            size={IllustrationSize.Large}
          />
          <Text variant={TextVariant.T3}>Under Construction</Text>
        </Stack>
      </ScrollContainer>
      <FooterGroup>
        <Button flex>Okay</Button>
      </FooterGroup>
    </Stack>
  );
}

export const SettingsSupport = memo(SettingsSupportComponent);
