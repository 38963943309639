import {
  Customer,
  CustomerSearchMatch,
  CustomerSearchMatchListApiResponse,
} from '@emporos/api-enterprise';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  Intent,
  Row,
  RowItem,
  ScrollContainer,
  Stack,
  Text,
  TextInput,
  TextVariant as TV,
  Variant as BV,
} from '@emporos/components';
import assert from 'assert';
import moment from 'moment';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {formatNamePart, formatPhoneNumber} from '../../../';

interface Props {
  activeTransactionCustomersCodes: Array<string>;
  addToAr?: boolean;
  disableWillCall?: boolean;
  onSearch: (
    params: SearchParams,
  ) => Promise<CustomerSearchMatchListApiResponse>;
  onAddCustomer: (
    customer: CustomerSearchMatch,
    addWillCall: boolean,
  ) => Promise<unknown>;
  onCancel: () => void;
  online: boolean;
  transactionCustomer?: Customer;
  isMcKesson: boolean;
  variant?: 'customer' | 'account';
}

export interface SearchParams {
  name: string;
  dob: string;
  mrn: string;
}

export function CustomerSearch({
  activeTransactionCustomersCodes,
  addToAr = false,
  disableWillCall = false,
  onSearch,
  onAddCustomer,
  onCancel,
  online,
  transactionCustomer,
  isMcKesson,
  variant,
}: Props): JSX.Element {
  const [customers, setCustomers] = useState<Array<CustomerSearchMatch>>([]);
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerSearchMatch | null>(null);
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [mrn, setMrn] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [addButtonLoading, setAddButtonLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const customerSearchText = hasSearched
    ? 'No customer found'
    : 'Search for customer above';
  const pathname = window.location.pathname;

  useEffect(() => {
    if (online) {
      return;
    }
    setHasSearched(false);
  }, [online]);

  const onClearName = () => {
    setName('');
    setCustomers([]);
    setHasSearched(false);
    setSelectedCustomer(null);
    setMrn('');
  };
  const onClearDate = () => {
    setDob('');
    setCustomers([]);
    setHasSearched(false);
    setSelectedCustomer(null);
  };

  const searchButtonDisabled = () =>
    isMcKesson ? !name || !moment(dob, 'MM/DD/YYYY', true).isValid() : !mrn;

  return (
    <Stack>
      <Header
        style={{paddingTop: 10}}
        title={
          pathname.includes('customer-payment')
            ? 'Select Customer'
            : 'Add Customer'
        }
      />
      <Row
        gutter={Gutter.XL}
        justify="space-between"
        as="form"
        onSubmit={async (event: React.BaseSyntheticEvent) => {
          event.preventDefault();
          setSearchLoading(true);
          try {
            const response = await onSearch({name, dob, mrn});
            response.data && setCustomers(response.data);
            if (response.data && response.data.length === 1) {
              setSelectedCustomer(response.data[0]);
            }
          } catch (e) {
            setCustomers([]);
          }
          setHasSearched(true);
          setSearchLoading(false);
        }}
      >
        {isMcKesson ? (
          <>
            <TextInput
              data-testid="customer-search-last-name"
              style={{flex: 1.5}}
              label="Last Name"
              icon="User"
              value={name}
              onChange={e => setName(e.target.value)}
              onClear={onClearName}
              disabled={!online}
            />
            <TextInput
              data-testid="customer-search-dob"
              style={{flex: 1, minWidth: 180}}
              label="DOB"
              icon="Birthday"
              value={dob}
              mask="99/99/9999"
              onChange={e => setDob(e.target.value)}
              onClear={onClearDate}
              disabled={!online}
              inputMode="numeric"
            />
          </>
        ) : (
          <TextInput
            style={{flex: 1.5}}
            label="MRN"
            icon="Number"
            value={mrn}
            onChange={e => setMrn(e.target.value)}
            onClear={onClearName}
            disabled={!online}
          />
        )}
        <Button
          type="submit"
          variant={BV.Secondary}
          disabled={searchButtonDisabled()}
          loading={searchLoading}
          style={{minWidth: 165}}
        >
          Search
        </Button>
      </Row>

      <ScrollContainer>
        {(!online || !customers.length) && (
          <Stack
            align="center"
            justify="center"
            gutter={Gutter.None}
            style={{height: '100%'}}
          >
            {online ? (
              <Illustration illustration="SearchUser" />
            ) : (
              <Illustration illustration="ConnectionLost" />
            )}
            <Text
              variant={TV.Main}
              intent={online && hasSearched && Intent.Alert}
            >
              {!online
                ? 'Cannot search without a connection'
                : customerSearchText}
            </Text>
          </Stack>
        )}
        {online && !!customers.length && (
          <Stack gutter={Gutter.S}>
            {customers.map(customer => {
              assert(customer.customerCode, 'Customer is missing customerCode');
              const customerExists =
                // This will allow us to reattach a customer to an AR payment
                // in the case they are accidentally switched out with another customer.
                !addToAr &&
                activeTransactionCustomersCodes.includes(
                  customer.customerCode,
                ) &&
                customer.id !== transactionCustomer?.id;

              return (
                <RowItem
                  key={customer.customerCode}
                  title={`${formatNamePart(
                    customer.firstName,
                  )} ${formatNamePart(customer.lastName)}`}
                  subtitle={`DOB: ${
                    customer.dob
                      ? moment.utc(customer.dob).format('MM/DD/YYYY')
                      : ''
                  } • Phone: ${formatPhoneNumber(customer.homePhone || '')}`}
                  selected={
                    customer.customerCode === selectedCustomer?.customerCode
                  }
                  onClick={() =>
                    !addButtonLoading &&
                    setSelectedCustomer(
                      selectedCustomer?.customerCode === customer.customerCode
                        ? null
                        : customer,
                    )
                  }
                  rightText={`MRN: ${
                    isMcKesson ? customer.customerCode : customer.mrn
                  }`}
                  rightBadgeText={customerExists ? 'Active' : ''}
                  inactive={customerExists}
                />
              );
            })}
          </Stack>
        )}
      </ScrollContainer>
      <FooterGroup>
        <Button variant={BV.Secondary} onClick={() => onCancel()} flex>
          Cancel
        </Button>
        <Button
          loading={addButtonLoading}
          disabled={!selectedCustomer || !online}
          flex
          data-testid="add-customer"
          onClick={async () => {
            if (!selectedCustomer) {
              return;
            }
            setAddButtonLoading(true);
            try {
              await onAddCustomer(selectedCustomer, true);
            } finally {
              setAddButtonLoading(false);
            }
          }}
        >
          {variant === 'account' ? 'Add Account' : 'Add Customer'}
        </Button>
        {!disableWillCall && (
          <Button
            loading={addButtonLoading}
            disabled={!selectedCustomer || !online}
            flex
            data-testid="see-willcall"
            onClick={async () => {
              if (!selectedCustomer) {
                return;
              }
              setAddButtonLoading(true);
              try {
                await onAddCustomer(selectedCustomer, false);
              } finally {
                setAddButtonLoading(false);
              }
            }}
          >
            See Will Call
          </Button>
        )}
      </FooterGroup>
    </Stack>
  );
}
