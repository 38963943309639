import {IntermissionThemed} from '../';

export const oidcCallbackComponents = {
  notAuthenticated: function NotAuthenticated(): JSX.Element {
    return <IntermissionThemed error="You are not logged in." />;
  },
  notAuthorized: function NotAuthorized(): JSX.Element {
    return (
      <IntermissionThemed error="You are not authorized to access this resource." />
    );
  },
  authenticating: function Authenticating(): JSX.Element {
    return <IntermissionThemed />;
  },
  callbackComponentOverride: function CallbackComponentOverride(): JSX.Element {
    return <IntermissionThemed />;
  },
  sessionLostComponent: function SessionLostComponent(): JSX.Element {
    return <IntermissionThemed error="Session lost, please log in again." />;
  },
};

const {OIDC_AUTHORITY, OIDC_CLIENT} = process.env;

export const oidcConfiguration = {
  monitorSession: false,
  client_id: OIDC_CLIENT,
  redirect_uri: `${
    typeof window !== 'undefined' ? window.location.origin : ''
  }/signin-oidc`,
  response_type: 'code',
  post_logout_redirect_uri: `${
    typeof window !== 'undefined' ? window.location.origin : ''
  }/login`,
  scope: 'openid profile emp-v10 clientapi',
  authority: OIDC_AUTHORITY,
  silent_redirect_uri: `${
    typeof window !== 'undefined' ? window.location.origin : ''
  }/authentication/silent_callback`,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
};
