import {ScanResult, Symbology} from '@emporos/barcodes';
import {useLayer} from '@emporos/components';
import {useEffect, useRef} from 'react';
import {useBarcodeScanner} from './';

type Props = {
  onScan: (scanResult: ScanResult) => void;
  enabledSymbologies?: Symbology[];
  closeAfterSuccess?: boolean;
};

export function PageBarcodeScanner({
  onScan,
  enabledSymbologies,
  closeAfterSuccess = true,
}: Props) {
  const closeAftterSuccessRef = useRef(closeAfterSuccess);
  const {subscribe, unsubscribe, setOpen: setOpenScanner} = useBarcodeScanner();
  const layer = useLayer();
  const ref = useRef(onScan);

  useEffect(() => {
    closeAftterSuccessRef.current = closeAfterSuccess;
  }, [closeAfterSuccess]);

  useEffect(() => {
    if (setOpenScanner && closeAftterSuccessRef.current) setOpenScanner(false);
  }, [setOpenScanner]);

  useEffect(() => {
    ref.current = onScan;
  }, [onScan]);

  useEffect(() => {
    // We store the onScan callback in a ref and register a listener only when
    // the layer changes (e.g. on mount) to minimize the amount of context
    // updates and child re-renders.
    const listener = (scanResult: ScanResult) => ref.current(scanResult);
    subscribe(layer, {listener, enabledSymbologies});
    return () => {
      if (setOpenScanner) setOpenScanner(false);
      unsubscribe(layer, listener);
    };
  }, [enabledSymbologies, layer, subscribe, unsubscribe, setOpenScanner]);

  return null;
}
