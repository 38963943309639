/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OtcItemAttachment,
    OtcItemAttachmentFromJSON,
    OtcItemAttachmentFromJSONTyped,
    OtcItemAttachmentToJSON,
    OtcItemType,
    OtcItemTypeFromJSON,
    OtcItemTypeFromJSONTyped,
    OtcItemTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OtcItem
 */
export interface OtcItem {
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    id: number;
    /**
     * 
     * @type {OtcItemType}
     * @memberof OtcItem
     */
    itemType: OtcItemType;
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    controlGrams: number;
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OtcItem
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    discountIndicator: boolean;
    /**
     * 
     * @type {string}
     * @memberof OtcItem
     */
    itemNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    taxableIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    salesUnitOfMeasureId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    listPrice: number;
    /**
     * 
     * @type {string}
     * @memberof OtcItem
     */
    receiptDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    qhpIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    qhpPrivateIndicator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    counselPromptIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    requiredAge?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    updateInventoryIndicator: boolean;
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    lastCost?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    proofOfIdRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OtcItem
     */
    signatureIndicator: boolean;
    /**
     * 
     * @type {Array<OtcItemAttachment>}
     * @memberof OtcItem
     */
    attachments?: Array<OtcItemAttachment> | null;
    /**
     * 
     * @type {number}
     * @memberof OtcItem
     */
    taxGroupId: number;
    /**
     * 
     * @type {string}
     * @memberof OtcItem
     */
    taxGroupType: string;
    /**
     * 
     * @type {string}
     * @memberof OtcItem
     */
    recordStatus?: string | null;
}

export function OtcItemFromJSON(json: any): OtcItem {
    return OtcItemFromJSONTyped(json, false);
}

export function OtcItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtcItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'itemType': OtcItemTypeFromJSON(json['itemType']),
        'controlGrams': json['controlGrams'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'discountIndicator': json['discountIndicator'],
        'itemNumber': json['itemNumber'],
        'taxableIndicator': json['taxableIndicator'],
        'salesUnitOfMeasureId': !exists(json, 'salesUnitOfMeasureId') ? undefined : json['salesUnitOfMeasureId'],
        'listPrice': json['listPrice'],
        'receiptDescription': !exists(json, 'receiptDescription') ? undefined : json['receiptDescription'],
        'qhpIndicator': json['qhpIndicator'],
        'qhpPrivateIndicator': json['qhpPrivateIndicator'],
        'counselPromptIndicator': json['counselPromptIndicator'],
        'requiredAge': !exists(json, 'requiredAge') ? undefined : json['requiredAge'],
        'updateInventoryIndicator': json['updateInventoryIndicator'],
        'lastCost': !exists(json, 'lastCost') ? undefined : json['lastCost'],
        'proofOfIdRequired': json['proofOfIdRequired'],
        'signatureIndicator': json['signatureIndicator'],
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(OtcItemAttachmentFromJSON)),
        'taxGroupId': json['taxGroupId'],
        'taxGroupType': json['taxGroupType'],
        'recordStatus': !exists(json, 'recordStatus') ? undefined : json['recordStatus'],
    };
}

export function OtcItemToJSON(value?: OtcItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'itemType': OtcItemTypeToJSON(value.itemType),
        'controlGrams': value.controlGrams,
        'departmentId': value.departmentId,
        'description': value.description,
        'discountIndicator': value.discountIndicator,
        'itemNumber': value.itemNumber,
        'taxableIndicator': value.taxableIndicator,
        'salesUnitOfMeasureId': value.salesUnitOfMeasureId,
        'listPrice': value.listPrice,
        'receiptDescription': value.receiptDescription,
        'qhpIndicator': value.qhpIndicator,
        'qhpPrivateIndicator': value.qhpPrivateIndicator,
        'counselPromptIndicator': value.counselPromptIndicator,
        'requiredAge': value.requiredAge,
        'updateInventoryIndicator': value.updateInventoryIndicator,
        'lastCost': value.lastCost,
        'proofOfIdRequired': value.proofOfIdRequired,
        'signatureIndicator': value.signatureIndicator,
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(OtcItemAttachmentToJSON)),
        'taxGroupId': value.taxGroupId,
        'taxGroupType': value.taxGroupType,
        'recordStatus': value.recordStatus,
    };
}


