import {Dispatch, SetStateAction, memo, useCallback, useMemo} from 'react';
import {ScanResult, Symbology} from '@emporos/barcodes';
import {useWindowResize} from '@emporos/components';
import {BarcodeScanner, FloatingWrapper} from './';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import {
  mapScanditSymbologyToSymbology,
  mapSymbologyToSymbologyByScandit,
} from './ScannerSettings/SymbologiesMappings';
import {formatBarcode} from './ScannerSettings/ScanSymbologiesLogic';
export type FloatingScannerProps = {
  enabled?: boolean;
  onScan: (result: ScanResult) => void;
  onScanError: (error: Error) => void;
  onError: (error: Error) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  enabledSymbologies?: Symbology[];
  licenseKey?: string;
};

export const FloatingScanner = memo(
  ({
    enabled = true,
    onScan,
    onScanError,
    onError,
    enabledSymbologies,
    licenseKey,
    open,
    setOpen,
  }: FloatingScannerProps): JSX.Element => {
    const {width, height} = useWindowResize();
    const _onScan = useCallback(
      (scanResult: SDCBarcode.Barcode) => {
        const result: ScanResult = {
          raw: formatBarcode(scanResult),
          prefix: null,
          suffix: null,
          symbology: mapScanditSymbologyToSymbology(
            scanResult.symbology as SDCBarcode.Symbology,
          ),
        };

        onScan(result);
      },
      [onScan],
    );

    const enabledSymbs = useMemo(() => {
      return (
        enabledSymbologies?.map(es => mapSymbologyToSymbologyByScandit(es)) ??
        []
      );
    }, [enabledSymbologies]);

    return (
      <FloatingWrapper
        icon="Barcode"
        originX={width - 116}
        originY={height - 206}
        visible={enabled}
        open={open}
        setOpen={setOpen}
      >
        <BarcodeScanner
          isScanning={enabled}
          onScan={_onScan}
          onScanError={onScanError}
          onError={onError}
          licenseKey={licenseKey}
          enabledSymbologies={enabledSymbs}
        />
      </FloatingWrapper>
    );
  },
);
