import {StatusType} from '@datadog/browser-logs';
import {AlertBannerStack, NotificationAlert} from '@emporos/components';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {v4 as uuid} from 'uuid';
import {AlertLogTypes, useLog} from './';

export type NotificationFn = (alert: Omit<NotificationAlert, 'id'>) => void;

export type AlertStateContext = {
  notification: NotificationFn;
  reset: () => void;
};
export interface AlertStateProviderProps {
  notification: NotificationFn;
  reset: () => void;
}

export const alertStateContext = createContext<AlertStateContext>({
  notification: () => {
    return;
  },
  reset: () => {
    return;
  },
});

function AlertStateProvider({children}: {children: ReactNode}): ReactElement {
  const {logAlert} = useLog();
  const [alerts, setAlerts] = useState<NotificationAlert[]>([]);

  const notification = useCallback(
    (alert: Omit<NotificationAlert, 'id'>) => {
      setAlerts(prevAlerts => {
        const id = uuid();
        setTimeout(() => removeAlert(id), 1000 * 30);
        return [...prevAlerts, {...alert, id}];
      });
      let statusType: StatusType | undefined;
      switch (alert.type) {
        case 'warning':
          statusType = 'warn';
          break;
        case 'general':
        case 'success':
          statusType = 'info';
          break;
        default:
          statusType = alert.type;
          break;
      }
      logAlert(AlertLogTypes.AlertTriggered, {...alert}, statusType);
    },
    [logAlert],
  );

  const reset = useCallback(() => setAlerts([]), []);

  const removeAlert = useCallback(
    (_id: string) =>
      setAlerts(prevAlerts => prevAlerts.filter(({id}) => id !== _id)),
    [],
  );

  return (
    <>
      <AlertBannerStack alerts={alerts} onDismiss={removeAlert} />
      <alertStateContext.Provider
        value={{
          notification,
          reset,
        }}
      >
        {children}
      </alertStateContext.Provider>
    </>
  );
}
const useAlertState = (): AlertStateProviderProps =>
  useContext(alertStateContext);
export {AlertStateProvider, useAlertState};
