import {
  Gutter,
  RemovePadding,
  Row,
  RowSidebar,
  Stack,
  Text,
  TextVariant as Variant,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useEffect, useState} from 'react';
import {behindTabBarStackingContext, fullHeightStack} from '../../../';

function SettingsSidebarComponent({
  requestedUrl,
  onConfirmNavigation,
  navigate,
}: RouteComponentProps & {
  requestedUrl: string | null;
  onCancelNavigation(): void;
  onConfirmNavigation(): void;
}) {
  const [selected, setSelected] = useState<
    'my-session' | 'developer' | 'support'
  >('my-session');

  const {NODE_ENV} = process.env;

  assert(
    navigate,
    '<SettingsSidebar /> must have a `navigate` prop.' + String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );

  // autoconfirm navigation
  useEffect(() => {
    if (requestedUrl) {
      onConfirmNavigation();
    }
  }, [requestedUrl, onConfirmNavigation]);
  return (
    <Stack gutter={Gutter.XL} style={fullHeightStack}>
      <Row align="center">
        <Text variant={Variant.T1}>Settings</Text>
      </Row>
      <RemovePadding style={behindTabBarStackingContext}>
        <RowSidebar
          title={'My Session'}
          icon="CashRegister"
          iconColor="primary"
          selected={selected === 'my-session'}
          onClick={() => {
            setSelected('my-session');
            return navigate('/sales/settings');
          }}
        />
        {String(NODE_ENV) !== 'production' && (
          <RowSidebar
            title={'Developer'}
            icon="Desktop"
            iconColor="purple"
            selected={selected === 'developer'}
            onClick={() => {
              setSelected('developer');
              return navigate('/sales/settings/developer');
            }}
          />
        )}
        <RowSidebar
          title={'Support'}
          icon="Support"
          iconColor="warning"
          selected={selected === 'support'}
          onClick={() => {
            setSelected('support');
            return navigate('/sales/settings/support');
          }}
        />
      </RemovePadding>
    </Stack>
  );
}

export const SettingsSidebar = memo(SettingsSidebarComponent);
