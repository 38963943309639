import {datadogLogs, StatusType} from '@datadog/browser-logs';
import {TransactionItem} from '@emporos/api-enterprise';
import {NotificationAlert} from '@emporos/components';
import {createContext, useContext} from 'react';
import {ComplianceForm} from '../';
import {TrackingEvent} from './';

export enum LoggingType {
  Alert = 'alertLogger',
  Api = 'apiLogger',
  Network = 'networkLogger',
  User = 'userSelectionLogger',
}

export enum AlertLogTypes {
  AlertTriggered = 'alert_triggered',
  DeviceConnecting = 'device_connecting_modal_triggered',
  DeviceConnected = 'device_connected_modal_triggered',
  ChangeDue = 'change_due_modal_triggered',
  PendingChanges = 'pending_changes_modal_triggered',
  Verification = 'birthday_verification_modal_triggered',
  Void = 'void_modal_triggered',
}

export enum ApiLogTypes {
  ApiError = 'api_error',
  ApiSuccess = 'api_success',
  CreditPaymentPending = 'credit_payment_initiated',
  CreditPaymentResult = 'credit_payment_result',
  CustomerPending = 'getting_customer_information',
  EligibilityPending = 'getting_employee_eligibility_information',
  EmployeePending = 'getting_employee_information',
  FetchNPLEX = 'fetching_NPLEX_eligibility',
  VoidPending = 'credit_card_void_pending',
}

export enum NavigationLogTypes {
  UserLogin = 'user_logging_in',
  UserLogout = 'user_logging_out',
  UserNavigating = 'navigating_to_new_page',
  UserNavigationCancelled = 'navigation_cancelled',
  UserNavigationRequested = 'navigation_requested',
}

export enum NetworkLogTypes {
  NetworkLostConnection = 'network_back_from_lost_connection',
}

export enum TransactionLogTypes {
  CustomerAdded = 'customer_attached_to_transaction',
  CustomerAccountAdded = 'created_new_AR_account_for_customer',
  EmployeeAccountAdded = 'created_new_PD_account_for_employee',
  CustomerUpdated = 'customer_information_updated',
  NewAccountCreated = 'created_new_payment_account',
  NewTransaction = 'created_new_transaction',
  RemoveTransaction = 'removed_transaction',
  Selection = 'transaction_selected',
  SyncTransaction = 'syncing_transaction',
  // For items
  AddItem = 'adding_items_to_transaction',
  RemoveItem = 'removing_items_from_transaction',
  UpdateQuantity = 'updating_item_quantity',
}

export enum UserLogTypes {
  ComplianceFormSubmitted = 'compliance_form_submitted',
  FeatureFlag = 'feature_flag_toggled',
  ScannedBadge = 'scanned_employee_id',
  ScannedCustID = 'scanned_customer_id',
  ScannedOTC = 'scanned_OTC_item',
  ScannedRX = 'scanned_RX_item',
  Tab = 'tab_selected',
  UserError = 'user_error',
  UserIdentified = 'user_identified',
}

interface GeneralLoggingConfig {
  data?: string | Array<TransactionItem> | ComplianceForm | Array<string>;
  info?: string;
  location?: string;
}

interface NavigationLog {
  url?: string | null;
  info?: string;
}

interface ScanOrPaymentResult {
  result?: string | null;
}

interface UserSelection {
  item: string | Array<TransactionItem> | Array<string>;
  value?: boolean | number | string | null;
  info?: string;
}

type LoggingConfig =
  | GeneralLoggingConfig
  | NavigationLog
  | NotificationAlert
  | ScanOrPaymentResult
  | TrackingEvent
  | UserSelection;

export interface LoggerProviderContext {
  logAlert: (
    message: string,
    config?: LoggingConfig,
    status?: StatusType,
  ) => void;
  logApi: (
    message: string,
    config?: LoggingConfig,
    status?: StatusType,
  ) => void;
  logNetwork: (
    message: string,
    config?: LoggingConfig,
    status?: StatusType,
  ) => void;
  logUserSelection: (
    message: string,
    config?: LoggingConfig,
    status?: StatusType,
  ) => void;
}

const loggerProviderContext = createContext<LoggerProviderContext>({
  logAlert: () => null,
  logApi: () => null,
  logNetwork: () => null,
  logUserSelection: () => null,
});

export type LoggerProviderProps = {children: React.ReactNode};

const {DATADOG_CLIENT_TOKEN, NODE_ENV} = process.env;

/* istanbul ignore next */
// Setup and configuration details found here:
// https://docs.datadoghq.com/logs/log_collection/javascript/?_ga=2.110307333.1267743483.1616440329-1208993645.1616440329#npm
datadogLogs.init({
  clientToken: DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  env: String(NODE_ENV),
});
datadogLogs.setLoggerGlobalContext({env: String(NODE_ENV)});

datadogLogs.createLogger(LoggingType.Alert, {
  level: 'info',
  handler: String(NODE_ENV) === 'development' ? 'silent' : 'http',
});
datadogLogs.createLogger(LoggingType.Api, {
  level: 'info',
  handler: String(NODE_ENV) === 'development' ? 'silent' : 'http',
});
datadogLogs.createLogger(LoggingType.Network, {
  level: 'info',
  handler: String(NODE_ENV) === 'development' ? 'silent' : 'http',
});
datadogLogs.createLogger(LoggingType.User, {
  level: 'info',
  handler: String(NODE_ENV) === 'development' ? 'silent' : 'http',
});

/* istanbul ignore next */
export function LoggingProvider(props: LoggerProviderProps): JSX.Element {
  const logAlert = (
    message: string,
    lConfig?: LoggingConfig,
    status?: StatusType,
  ) => {
    const alertLogger = datadogLogs.getLogger(LoggingType.Alert);
    alertLogger?.log(message, lConfig, status);
  };

  const logApi = (
    message: string,
    lConfig?: LoggingConfig,
    status?: StatusType,
  ) => {
    const apiLogger = datadogLogs.getLogger(LoggingType.Api);
    apiLogger?.log(message, lConfig, status);
  };

  const logNetwork = (
    message: string,
    lConfig?: LoggingConfig,
    status?: StatusType,
  ) => {
    const networkLogger = datadogLogs.getLogger(LoggingType.Network);
    networkLogger?.log(message, lConfig, status);
  };

  const logUserSelection = (
    message: string,
    lConfig?: LoggingConfig,
    status?: StatusType,
  ) => {
    const activityLogger = datadogLogs.getLogger(LoggingType.User);
    activityLogger?.log(message, lConfig, status);
  };

  return (
    <loggerProviderContext.Provider
      value={{logAlert, logApi, logUserSelection, logNetwork}}
    >
      {props.children}
    </loggerProviderContext.Provider>
  );
}

/* istanbul ignore next */
export const useLog = (): LoggerProviderContext =>
  useContext(loggerProviderContext);
