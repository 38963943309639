// istanbul ignore file
/**
 * Utility functions for environment variables.
 * Use these to access environment variables instead of process.env directly,
 * as these function can be mocked in tests.
 */
export const isDevelopment = (): boolean => {
  const nodeEnv = String(process.env.NODE_ENV) || '';
  return nodeEnv === 'development' || nodeEnv === 'test';
};
