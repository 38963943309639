import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import {Symbology} from '@emporos/barcodes';

const symbologyByScanditSymbology: Partial<
  Record<SDCBarcode.Symbology, Symbology>
> = {
  [SDCBarcode.Symbology.Code128]: Symbology.Code128,
  [SDCBarcode.Symbology.Code39]: Symbology.Code39,
  [SDCBarcode.Symbology.PDF417]: Symbology.PDF417,
  [SDCBarcode.Symbology.MicroPDF417]: Symbology.MicroPDF417,
  [SDCBarcode.Symbology.Aztec]: Symbology.Aztec,
  [SDCBarcode.Symbology.EAN13UPCA]: Symbology.EAN13UPCA,
  [SDCBarcode.Symbology.EAN8]: Symbology.EAN8,
  [SDCBarcode.Symbology.UPCE]: Symbology.UPCE,
  [SDCBarcode.Symbology.QR]: Symbology.QR,
  [SDCBarcode.Symbology.MicroQR]: Symbology.MicroQR,
};

const symbologyToSymbologyByScandit: Partial<
  Record<Symbology, SDCBarcode.Symbology>
> = {
  [Symbology.Code128]: SDCBarcode.Symbology.Code128,
  [Symbology.Code39]: SDCBarcode.Symbology.Code39,
  [Symbology.PDF417]: SDCBarcode.Symbology.PDF417,
  [Symbology.MicroPDF417]: SDCBarcode.Symbology.MicroPDF417,
  [Symbology.Aztec]: SDCBarcode.Symbology.Aztec,
  [Symbology.EAN13UPCA]: SDCBarcode.Symbology.EAN13UPCA,
  [Symbology.EAN8]: SDCBarcode.Symbology.EAN8,
  [Symbology.UPCE]: SDCBarcode.Symbology.UPCE,
  [Symbology.QR]: SDCBarcode.Symbology.QR,
  [Symbology.MicroQR]: SDCBarcode.Symbology.MicroQR,
};

export const mapScanditSymbologyToSymbology = (
  symbology: SDCBarcode.Symbology,
): Symbology | null => {
  return symbologyByScanditSymbology[symbology] || null;
};

export const mapSymbologyToSymbologyByScandit = (
  symbology: Symbology,
): SDCBarcode.Symbology => {
  return symbologyToSymbologyByScandit[symbology]!;
};
