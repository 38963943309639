import styled from 'styled-components';

const PortraitNotSupportedWrapper = styled.div`
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: ${props => props.theme.colors.gray_100};
`;

const PortraitNotSupportedImageWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PortraitNotSupportedImage = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1596.000000 700.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M8704 6371 c-98 -44 -144 -131 -144 -271 l0 -76 -42 -19 c-78 -37
-83 -51 -86 -263 -4 -235 0 -266 43 -309 l33 -33 295 0 c397 0 377 -16 377
308 0 200 -1 210 -23 243 -12 19 -41 43 -63 54 l-41 20 -5 95 c-4 69 -10 106
-26 135 -26 51 -77 100 -124 119 -51 22 -143 20 -194 -3z m169 -113 c39 -20
57 -69 57 -155 l0 -83 -126 0 -126 0 4 94 c3 84 6 97 27 120 40 43 109 53 164
24z"
        />
        <path
          d="M7800 6057 c-57 -21 -58 -22 -320 -280 -278 -274 -289 -290 -290
-398 0 -115 -5 -110 572 -686 503 -502 525 -522 577 -537 68 -20 94 -20 161 0
49 14 73 35 299 257 135 133 254 252 264 264 30 38 49 95 50 153 1 95 -16 125
-131 243 l-106 107 -88 -87 -88 -88 85 -85 85 -85 -225 -225 -225 -225 -495
495 -495 495 227 227 228 228 80 -80 80 -80 85 85 85 85 -90 91 c-103 105
-163 139 -240 138 -27 0 -66 -6 -85 -12z"
        />
        <path
          d="M6653 4978 c14 -246 103 -504 250 -723 211 -314 537 -538 917 -627
121 -28 413 -36 529 -14 46 8 86 18 89 21 4 3 -156 168 -353 365 l-360 360
-75 -75 -75 -75 185 -185 c102 -102 182 -185 178 -185 -4 0 -40 7 -80 15 -241
50 -444 161 -629 345 -205 203 -321 430 -364 710 -8 52 -15 114 -15 138 l0 42
-102 0 -101 0 6 -112z"
        />
        <path
          d="M7003 3133 c-49 -5 -53 -16 -18 -49 22 -20 25 -31 25 -94 l0 -70 -54
6 c-128 15 -232 -49 -256 -159 -19 -82 -9 -203 20 -255 26 -46 85 -82 136 -82
31 0 127 41 138 59 14 23 28 11 21 -19 l-7 -30 96 0 c75 0 96 3 96 14 0 7 -11
21 -25 30 l-25 16 0 320 0 320 -52 -2 c-29 -1 -72 -3 -95 -5z m-33 -263 c29
-15 40 -65 40 -182 0 -124 -12 -160 -60 -177 -44 -15 -78 1 -96 45 -37 88 -25
254 22 300 24 25 63 31 94 14z"
        />
        <path
          d="M14333 3133 c-49 -5 -53 -16 -18 -49 22 -20 25 -31 25 -94 l0 -70
-54 6 c-128 15 -232 -49 -256 -159 -19 -82 -9 -203 20 -255 26 -46 85 -82 136
-82 31 0 127 41 138 59 14 23 28 11 21 -19 l-7 -30 96 0 c75 0 96 3 96 14 0 7
-11 21 -25 30 l-25 16 0 320 0 320 -52 -2 c-29 -1 -72 -3 -95 -5z m-33 -263
c29 -15 40 -65 40 -182 0 -124 -12 -160 -60 -177 -44 -15 -78 1 -96 45 -37 88
-25 254 22 300 24 25 63 31 94 14z"
        />
        <path d="M4440 3050 l0 -60 65 0 65 0 0 60 0 60 -65 0 -65 0 0 -60z" />
        <path
          d="M9988 3096 c-59 -16 -94 -39 -118 -79 -65 -105 -17 -211 130 -292 92
-50 120 -83 120 -139 0 -23 -7 -51 -15 -63 -18 -26 -71 -43 -111 -36 -38 7
-81 48 -89 84 -6 25 -11 29 -41 29 l-34 0 0 -74 0 -73 30 -7 c17 -3 65 -8 108
-12 184 -15 292 55 292 191 0 89 -40 141 -167 214 -83 48 -113 81 -113 125 0
67 66 107 138 83 29 -9 41 -22 57 -54 17 -38 23 -43 52 -43 l33 0 0 69 0 69
-63 11 c-81 14 -149 13 -209 -3z"
        />
        <path
          d="M1470 3090 c0 -7 11 -23 25 -36 l25 -23 0 -261 0 -261 -25 -23 c-45
-42 -35 -46 105 -46 139 0 159 8 100 42 l-30 17 0 104 0 104 84 5 c96 6 164
33 195 76 46 66 53 177 14 230 -10 15 -41 38 -69 52 -46 24 -60 25 -236 28
-144 3 -188 1 -188 -8z m343 -80 c24 -26 27 -38 27 -96 0 -69 -17 -116 -48
-133 -10 -5 -42 -13 -70 -17 l-52 -7 0 148 0 148 58 -6 c47 -5 62 -11 85 -37z"
        />
        <path
          d="M5290 3089 c0 -7 11 -22 25 -35 l25 -23 0 -261 0 -261 -25 -23 c-42
-39 -33 -46 65 -46 69 0 90 3 90 13 0 8 -11 19 -24 25 -19 8 -26 21 -31 54 -8
58 -2 447 7 428 4 -8 40 -112 81 -230 l73 -215 44 0 43 0 81 222 c45 123 85
226 89 229 9 9 9 -387 0 -439 -4 -27 -13 -42 -29 -49 -13 -6 -24 -17 -24 -25
0 -10 27 -13 125 -13 100 0 125 3 125 14 0 7 -11 21 -25 30 l-25 16 0 270 0
270 25 16 c14 9 25 23 25 30 0 11 -23 14 -113 14 l-112 0 -70 -195 c-39 -107
-72 -195 -75 -195 -3 0 -35 88 -70 195 l-64 195 -118 0 c-81 0 -118 -4 -118
-11z"
        />
        <path
          d="M8030 3089 c0 -6 11 -22 25 -35 l26 -24 -3 -266 c-3 -264 -3 -267
-25 -282 -13 -8 -23 -21 -23 -28 0 -11 20 -14 90 -14 69 0 90 3 90 13 0 8 -11
19 -24 25 -14 6 -26 22 -30 42 -10 54 -6 417 5 400 5 -8 69 -118 142 -245
l133 -230 62 -3 62 -3 0 296 0 296 25 23 c42 39 33 46 -65 46 -97 0 -109 -8
-66 -45 24 -20 24 -23 28 -217 3 -109 1 -198 -2 -198 -4 0 -17 21 -29 48 -12
26 -69 128 -125 227 l-103 180 -97 3 c-68 2 -96 -1 -96 -9z"
        />
        <path
          d="M3120 3010 c0 -47 -26 -99 -55 -110 -36 -14 -33 -40 5 -40 l30 0 0
-162 c0 -186 10 -220 70 -250 59 -30 138 -16 205 35 18 14 19 17 4 32 -14 14
-19 14 -52 0 -40 -17 -69 -9 -81 21 -3 8 -6 85 -6 170 l0 154 65 0 65 0 0 30
0 30 -65 0 -65 0 0 60 0 60 -60 0 -60 0 0 -30z"
        />
        <path
          d="M4760 3010 c0 -47 -26 -99 -55 -110 -36 -14 -33 -40 5 -40 l30 0 0
-162 c0 -186 10 -220 70 -250 59 -30 138 -16 205 35 18 14 19 17 4 32 -14 14
-19 14 -52 0 -40 -17 -69 -9 -81 21 -3 8 -6 85 -6 170 l0 154 65 0 65 0 0 30
0 30 -65 0 -65 0 0 60 0 60 -60 0 -60 0 0 -30z"
        />
        <path
          d="M9310 3010 c0 -47 -26 -99 -55 -110 -36 -14 -33 -40 5 -40 l30 0 0
-162 c0 -186 10 -220 70 -250 59 -30 138 -16 205 35 18 14 19 17 4 32 -14 14
-19 14 -52 0 -40 -17 -69 -9 -81 21 -3 8 -6 85 -6 170 l0 154 65 0 65 0 0 30
0 30 -65 0 -65 0 0 60 0 60 -60 0 -60 0 0 -30z"
        />
        <path
          d="M13190 3010 c0 -47 -26 -99 -55 -110 -36 -14 -33 -40 5 -40 l30 0 0
-162 c0 -186 10 -220 70 -250 59 -30 138 -16 205 35 18 14 19 17 4 32 -14 14
-19 14 -52 0 -40 -17 -69 -9 -81 21 -3 8 -6 85 -6 170 l0 154 65 0 65 0 0 30
0 30 -65 0 -65 0 0 60 0 60 -60 0 -60 0 0 -30z"
        />
        <path
          d="M2210 2921 c-111 -34 -160 -109 -160 -246 0 -152 58 -225 193 -241
112 -13 203 31 250 120 31 60 31 203 -1 263 -26 50 -72 87 -127 102 -45 12
-118 13 -155 2z m118 -64 c13 -13 27 -39 32 -58 13 -45 13 -204 -1 -252 -20
-73 -95 -89 -136 -29 -52 79 -39 332 19 355 31 12 66 6 86 -16z"
        />
        <path
          d="M2870 2914 c-19 -8 -48 -27 -64 -42 l-29 -27 6 38 6 37 -95 0 c-102
0 -112 -6 -69 -46 25 -23 25 -24 25 -194 0 -170 0 -171 -25 -194 -44 -41 -34
-46 95 -46 127 0 141 6 95 42 -25 19 -25 21 -25 169 0 146 1 151 25 174 32 33
60 31 87 -5 18 -24 29 -30 60 -30 l39 0 -3 68 -3 67 -45 2 c-25 2 -61 -4 -80
-13z"
        />
        <path
          d="M3690 2914 c-19 -8 -48 -27 -64 -42 l-29 -27 6 38 6 37 -95 0 c-102
0 -112 -6 -69 -46 25 -23 25 -24 25 -194 0 -170 0 -171 -25 -194 -44 -41 -34
-46 95 -46 127 0 141 6 95 42 -25 19 -25 21 -25 169 0 146 1 151 25 174 32 33
60 31 87 -5 18 -24 29 -30 60 -30 l39 0 -3 68 -3 67 -45 2 c-25 2 -61 -4 -80
-13z"
        />
        <path
          d="M4012 2916 c-29 -7 -71 -21 -92 -31 -38 -17 -40 -21 -40 -62 l0 -43
44 0 c40 0 45 3 51 28 17 71 94 97 134 46 15 -19 21 -41 21 -79 l0 -53 -67
-12 c-149 -25 -219 -82 -210 -170 12 -109 138 -142 251 -65 47 32 49 31 44 -5
l-5 -30 89 0 c95 0 112 12 63 44 -25 16 -25 17 -26 174 0 172 -9 214 -50 245
-34 25 -135 31 -207 13z m118 -300 c0 -55 -3 -67 -26 -90 -52 -51 -119 -27
-119 43 0 53 34 88 95 100 25 5 46 9 48 10 1 1 2 -28 2 -63z"
        />
        <path
          d="M6290 2921 c-111 -34 -160 -109 -160 -246 0 -152 58 -225 193 -241
112 -13 203 31 250 120 31 60 31 203 -1 263 -26 50 -72 87 -127 102 -45 12
-118 13 -155 2z m118 -64 c13 -13 27 -39 32 -58 13 -45 13 -204 -1 -252 -20
-73 -95 -89 -136 -29 -52 79 -39 332 19 355 31 12 66 6 86 -16z"
        />
        <path
          d="M7445 2921 c-110 -27 -164 -107 -165 -241 0 -141 39 -207 138 -236
93 -27 175 -10 260 54 l33 25 -20 20 -20 20 -41 -28 c-58 -41 -128 -41 -169
-1 -17 16 -32 42 -36 57 -3 16 -8 43 -11 59 l-6 30 156 0 156 0 0 28 c0 53
-31 135 -64 168 -47 47 -129 65 -211 45z m106 -71 c11 -14 22 -46 25 -72 l7
-48 -82 0 c-80 0 -81 0 -81 25 0 34 27 97 48 113 25 19 62 11 83 -18z"
        />
        <path
          d="M8860 2921 c-111 -34 -160 -109 -160 -246 0 -152 58 -225 193 -241
112 -13 203 31 250 120 31 60 31 203 -1 263 -26 50 -72 87 -127 102 -45 12
-118 13 -155 2z m118 -64 c13 -13 27 -39 32 -58 13 -45 13 -204 -1 -252 -20
-73 -95 -89 -136 -29 -52 79 -39 332 19 355 31 12 66 6 86 -16z"
        />
        <path
          d="M11202 2915 c-18 -8 -46 -24 -62 -35 -36 -26 -37 -26 -32 10 l4 30
-96 0 c-79 0 -96 -3 -96 -15 0 -8 4 -15 9 -15 5 0 17 -7 25 -16 14 -13 16 -55
16 -300 0 -272 -1 -285 -20 -301 -42 -36 -27 -43 95 -43 124 0 134 5 90 46
-22 20 -25 31 -25 92 l0 68 88 0 c110 -1 157 21 199 91 26 45 28 55 28 163 0
101 -3 120 -23 158 -38 70 -125 100 -200 67z m22 -65 c33 -12 55 -57 62 -129
15 -136 -21 -233 -87 -239 -27 -2 -40 3 -59 23 -23 25 -25 35 -28 147 -4 137
4 169 51 192 38 19 31 18 61 6z"
        />
        <path
          d="M11792 2915 c-18 -8 -46 -24 -62 -35 -36 -26 -37 -26 -32 10 l4 30
-96 0 c-79 0 -96 -3 -96 -15 0 -8 4 -15 9 -15 5 0 17 -7 25 -16 14 -13 16 -55
16 -300 0 -272 -1 -285 -20 -301 -42 -36 -27 -43 95 -43 124 0 134 5 90 46
-22 20 -25 31 -25 92 l0 68 88 0 c110 -1 157 21 199 91 26 45 28 55 28 163 0
101 -3 120 -23 158 -38 70 -125 100 -200 67z m22 -65 c33 -12 55 -57 62 -129
15 -136 -21 -233 -87 -239 -27 -2 -40 3 -59 23 -23 25 -25 35 -28 147 -4 137
4 169 51 192 38 19 31 18 61 6z"
        />
        <path
          d="M12280 2921 c-111 -34 -160 -109 -160 -246 0 -152 58 -225 193 -241
112 -13 203 31 250 120 31 60 31 203 -1 263 -26 50 -72 87 -127 102 -45 12
-118 13 -155 2z m118 -64 c13 -13 27 -39 32 -58 13 -45 13 -204 -1 -252 -20
-73 -95 -89 -136 -29 -52 79 -39 332 19 355 31 12 66 6 86 -16z"
        />
        <path
          d="M12940 2914 c-19 -8 -48 -27 -64 -42 l-29 -27 6 38 6 37 -95 0 c-102
0 -112 -6 -69 -46 25 -23 25 -24 25 -194 0 -170 0 -171 -25 -194 -44 -41 -34
-46 95 -46 127 0 141 6 95 42 -25 19 -25 21 -25 169 0 146 1 151 25 174 32 33
60 31 87 -5 18 -24 29 -30 60 -30 l39 0 -3 68 -3 67 -45 2 c-25 2 -61 -4 -80
-13z"
        />
        <path
          d="M13655 2921 c-110 -27 -164 -107 -165 -241 0 -141 39 -207 138 -236
93 -27 175 -10 260 54 l33 25 -20 20 -20 20 -41 -28 c-58 -41 -128 -41 -169
-1 -17 16 -32 42 -36 57 -3 16 -8 43 -11 59 l-6 30 156 0 156 0 0 28 c0 53
-31 135 -64 168 -47 47 -129 65 -211 45z m106 -71 c11 -14 22 -46 25 -72 l7
-48 -82 0 c-80 0 -81 0 -81 25 0 34 27 97 48 113 25 19 62 11 83 -18z"
        />
        <path
          d="M4390 2909 c0 -7 11 -22 25 -35 25 -23 25 -24 25 -194 0 -170 0 -171
-25 -194 -44 -41 -34 -46 95 -46 127 0 141 6 95 42 l-25 19 0 210 0 209 -95 0
c-64 0 -95 -4 -95 -11z"
        />
        <path
          d="M10330 2909 c0 -7 11 -22 25 -35 25 -23 25 -26 26 -176 0 -91 5 -167
12 -187 29 -83 132 -102 226 -43 29 17 55 32 57 32 3 0 3 -13 -1 -30 l-7 -30
96 0 c75 0 96 3 96 14 0 7 -11 21 -25 30 l-25 16 0 210 0 210 -95 0 c-103 0
-113 -6 -70 -46 24 -22 25 -27 25 -158 0 -80 -5 -145 -11 -160 -16 -34 -72
-60 -99 -46 -36 19 -40 45 -40 229 l0 181 -95 0 c-64 0 -95 -4 -95 -11z"
        />
        <path
          d="M2530 2004 c0 -3 11 -15 25 -26 l25 -19 0 -244 c0 -208 -2 -246 -16
-259 -8 -9 -20 -16 -25 -16 -5 0 -9 -4 -9 -10 0 -6 33 -10 80 -10 80 0 98 9
60 30 -19 10 -20 21 -20 285 l0 275 -60 0 c-33 0 -60 -3 -60 -6z"
        />
        <path
          d="M6420 2004 c0 -3 11 -15 25 -26 l25 -19 0 -244 c0 -208 -2 -246 -16
-259 -8 -9 -20 -16 -25 -16 -5 0 -9 -4 -9 -10 0 -6 33 -10 80 -10 80 0 98 9
60 30 -18 10 -20 21 -20 134 l0 124 32 31 c39 38 85 42 114 10 16 -18 20 -40
23 -144 3 -122 3 -124 -23 -150 -14 -14 -26 -28 -26 -30 0 -3 36 -5 80 -5 80
0 98 9 60 30 -18 10 -20 21 -20 128 -1 193 -21 237 -109 237 -40 0 -56 -6 -90
-33 l-41 -33 0 131 0 130 -60 0 c-33 0 -60 -3 -60 -6z"
        />
        <path
          d="M9530 2000 c0 -5 5 -10 10 -10 6 0 19 -9 30 -20 16 -16 20 -33 20
-96 0 -69 -2 -75 -17 -69 -10 4 -40 10 -68 12 -71 6 -131 -27 -164 -91 -19
-38 -24 -62 -24 -121 1 -173 106 -243 230 -155 l43 30 0 -30 c0 -30 0 -30 60
-30 64 0 76 10 35 28 l-25 12 0 275 0 275 -65 0 c-37 0 -65 -4 -65 -10z m27
-234 c28 -24 28 -26 28 -136 l0 -112 -33 -29 c-34 -30 -77 -38 -111 -19 -47
24 -67 170 -35 250 28 69 99 90 151 46z"
        />
        <path
          d="M13330 2000 c0 -5 5 -10 10 -10 6 0 19 -9 30 -20 16 -16 20 -33 20
-96 0 -69 -2 -75 -17 -69 -10 4 -40 10 -68 12 -71 6 -131 -27 -164 -91 -19
-38 -24 -62 -24 -121 1 -173 106 -243 230 -155 l43 30 0 -30 c0 -30 0 -30 60
-30 64 0 76 10 35 28 l-25 12 0 275 0 275 -65 0 c-37 0 -65 -4 -65 -10z m27
-234 c28 -24 28 -26 28 -136 l0 -112 -33 -29 c-34 -30 -77 -38 -111 -19 -47
24 -67 170 -35 250 28 69 99 90 151 46z"
        />
        <path
          d="M2070 1974 c0 -3 11 -15 25 -26 l25 -19 0 -229 0 -229 -25 -19 c-14
-11 -25 -23 -25 -26 0 -3 41 -6 90 -6 85 0 115 11 69 25 -29 9 -39 45 -39 134
l0 78 80 6 c136 9 185 54 185 167 0 62 -3 72 -28 98 -17 17 -48 35 -72 41 -46
11 -285 16 -285 5z m259 -48 c33 -18 51 -54 51 -106 0 -84 -40 -120 -135 -120
l-55 0 0 120 0 120 55 0 c31 0 68 -6 84 -14z"
        />
        <path
          d="M7990 1974 c0 -3 11 -15 25 -26 l25 -19 0 -229 0 -229 -25 -19 c-14
-11 -25 -23 -25 -26 0 -3 83 -6 184 -6 l184 0 4 80 c3 71 1 80 -15 80 -10 0
-20 -8 -23 -17 -32 -98 -38 -103 -135 -103 l-79 0 0 230 0 231 25 25 c14 14
25 27 25 29 0 3 -38 5 -85 5 -47 0 -85 -3 -85 -6z"
        />
        <path
          d="M11990 1976 c0 -3 11 -16 25 -30 l25 -25 0 -221 0 -221 -25 -25 c-14
-14 -25 -27 -25 -29 0 -3 34 -5 75 -5 72 0 97 12 55 25 -11 3 -22 16 -25 28
-7 34 -11 427 -4 419 4 -4 40 -96 79 -204 97 -263 91 -263 195 -5 43 105 81
196 85 200 4 5 6 -87 3 -205 -5 -194 -7 -214 -24 -227 -34 -24 -20 -31 61 -31
80 0 98 9 60 30 -19 10 -20 21 -20 250 0 229 1 240 20 250 39 21 21 31 -47 28
l-67 -3 -72 -179 c-39 -99 -75 -186 -80 -193 -6 -9 -31 48 -79 179 l-70 193
-72 3 c-40 2 -73 1 -73 -2z"
        />
        <path d="M5590 1930 l0 -40 40 0 40 0 0 40 0 40 -40 0 -40 0 0 -40z" />
        <path
          d="M5824 1873 c-9 -51 -20 -70 -44 -78 -30 -9 -24 -25 9 -25 l30 0 3
-155 c3 -145 4 -156 26 -177 32 -32 105 -32 150 1 37 26 31 52 -6 32 -33 -18
-69 -13 -86 11 -13 18 -16 51 -16 155 l0 133 65 0 c58 0 65 2 65 20 0 18 -7
20 -65 20 l-65 0 0 50 c0 49 -1 50 -30 50 -26 0 -30 -4 -36 -37z"
        />
        <path
          d="M7114 1873 c-9 -51 -20 -70 -44 -78 -30 -9 -24 -25 9 -25 l30 0 3
-155 c3 -145 4 -156 26 -177 32 -32 105 -32 150 1 37 26 31 52 -6 32 -33 -18
-69 -13 -86 11 -13 18 -16 51 -16 155 l0 133 65 0 c58 0 65 2 65 20 0 18 -7
20 -65 20 l-65 0 0 50 c0 49 -1 50 -30 50 -26 0 -30 -4 -36 -37z"
        />
        <path
          d="M2870 1798 c-64 -35 -95 -97 -94 -192 1 -95 28 -150 87 -177 23 -10
55 -19 71 -19 72 0 163 49 151 82 -7 17 -9 16 -37 -4 -17 -11 -52 -23 -78 -26
-41 -4 -51 -1 -80 25 -28 25 -34 38 -38 86 l-4 57 126 0 126 0 0 38 c0 119
-122 187 -230 130z m117 -23 c20 -14 43 -69 43 -102 0 -10 -25 -13 -90 -13
-100 0 -102 2 -76 64 25 60 79 82 123 51z"
        />
        <path
          d="M3285 1806 c-66 -21 -85 -35 -85 -61 0 -20 5 -25 25 -25 14 0 25 5
25 10 0 24 41 50 79 50 52 0 81 -35 81 -95 l0 -43 -52 -7 c-65 -9 -126 -30
-150 -51 -54 -50 -38 -143 29 -165 46 -15 92 -5 142 31 l41 30 0 -30 c0 -30 1
-30 55 -30 30 0 55 5 55 10 0 6 -4 10 -9 10 -5 0 -17 7 -25 16 -13 12 -16 40
-16 143 0 71 -5 143 -11 161 -19 54 -96 74 -184 46z m125 -242 c0 -37 -5 -51
-29 -75 -53 -52 -141 -32 -141 33 0 31 43 68 90 77 81 16 80 16 80 -35z"
        />
        <path
          d="M3663 1801 c-47 -22 -63 -45 -63 -93 0 -44 25 -70 103 -109 79 -40
97 -56 97 -89 0 -38 -20 -60 -62 -67 -44 -7 -82 14 -100 53 -6 13 -19 24 -30
24 -15 0 -18 -8 -18 -44 0 -50 5 -54 89 -62 114 -12 186 31 186 111 0 54 -19
76 -100 114 -76 36 -95 54 -95 91 0 34 31 60 71 60 30 0 79 -34 79 -56 0 -8 9
-14 20 -14 17 0 20 7 20 44 0 42 -1 44 -37 50 -66 10 -117 6 -160 -13z"
        />
        <path
          d="M4050 1798 c-64 -35 -95 -97 -94 -192 1 -95 28 -150 87 -177 23 -10
55 -19 71 -19 72 0 163 49 151 82 -7 17 -9 16 -37 -4 -17 -11 -52 -23 -78 -26
-41 -4 -51 -1 -80 25 -28 25 -34 38 -38 86 l-4 57 126 0 126 0 0 38 c0 119
-122 187 -230 130z m117 -23 c20 -14 43 -69 43 -102 0 -10 -25 -13 -90 -13
-100 0 -102 2 -76 64 25 60 79 82 123 51z"
        />
        <path
          d="M4613 1801 c-47 -22 -63 -45 -63 -93 0 -44 25 -70 103 -109 79 -40
97 -56 97 -89 0 -38 -20 -60 -62 -67 -44 -7 -82 14 -100 53 -6 13 -19 24 -30
24 -15 0 -18 -8 -18 -44 0 -50 5 -54 89 -62 114 -12 186 31 186 111 0 54 -19
76 -100 114 -76 36 -95 54 -95 91 0 34 31 60 71 60 30 0 79 -34 79 -56 0 -8 9
-14 20 -14 17 0 20 7 20 44 0 42 -1 44 -37 50 -66 10 -117 6 -160 -13z"
        />
        <path
          d="M6184 1809 c-35 -10 -89 -65 -106 -108 -6 -14 -10 -58 -10 -97 0
-115 44 -179 132 -191 70 -10 171 43 157 82 -5 12 -12 11 -43 -10 -48 -32
-103 -33 -132 -2 -31 33 -42 71 -42 141 0 120 68 196 136 151 14 -9 29 -26 31
-36 3 -12 14 -19 29 -19 22 0 24 4 24 44 0 42 -1 44 -37 50 -50 8 -101 6 -139
-5z"
        />
        <path
          d="M7479 1810 c-77 -23 -121 -93 -122 -193 -1 -81 16 -129 58 -165 59
-52 167 -54 227 -4 69 58 91 193 46 282 -36 72 -125 106 -209 80z m104 -40
c35 -27 51 -81 51 -166 -1 -53 -6 -81 -20 -105 -52 -89 -147 -70 -175 36 -13
48 -7 159 12 198 25 54 88 71 132 37z"
        />
        <path
          d="M8535 1806 c-66 -21 -85 -35 -85 -61 0 -20 5 -25 25 -25 14 0 25 5
25 10 0 24 41 50 79 50 52 0 81 -35 81 -95 l0 -43 -52 -7 c-65 -9 -126 -30
-150 -51 -54 -50 -38 -143 29 -165 46 -15 92 -5 142 31 l41 30 0 -30 c0 -30 1
-30 55 -30 30 0 55 5 55 10 0 6 -4 10 -9 10 -5 0 -17 7 -25 16 -13 12 -16 40
-16 143 0 71 -5 143 -11 161 -19 54 -96 74 -184 46z m125 -242 c0 -37 -5 -51
-29 -75 -53 -52 -141 -32 -141 33 0 31 43 68 90 77 81 16 80 16 80 -35z"
        />
        <path
          d="M9042 1810 c-13 -6 -38 -22 -57 -36 l-35 -26 0 31 c0 31 0 31 -55 31
-59 0 -68 -9 -32 -32 21 -15 22 -21 22 -163 0 -140 -1 -148 -22 -165 -13 -10
-23 -21 -23 -24 0 -3 36 -6 80 -6 44 0 80 3 80 6 0 3 -10 14 -22 24 -22 17
-23 26 -23 140 l0 122 33 29 c19 17 43 29 60 29 60 0 72 -30 72 -184 0 -115
-2 -126 -20 -136 -38 -21 -20 -30 60 -30 44 0 80 3 80 6 0 3 -11 15 -25 26
-24 19 -25 23 -25 147 0 71 -5 142 -11 160 -18 50 -80 73 -137 51z"
        />
        <path
          d="M9853 1801 c-47 -22 -63 -45 -63 -93 0 -44 25 -70 103 -109 79 -40
97 -56 97 -89 0 -38 -20 -60 -62 -67 -44 -7 -82 14 -100 53 -6 13 -19 24 -30
24 -15 0 -18 -8 -18 -44 0 -50 5 -54 89 -62 114 -12 186 31 186 111 0 54 -19
76 -100 114 -76 36 -95 54 -95 91 0 34 31 60 71 60 30 0 79 -34 79 -56 0 -8 9
-14 20 -14 17 0 20 7 20 44 0 42 -1 44 -37 50 -66 10 -117 6 -160 -13z"
        />
        <path
          d="M10264 1809 c-35 -10 -89 -65 -106 -108 -6 -14 -10 -58 -10 -97 0
-115 44 -179 132 -191 70 -10 171 43 157 82 -5 12 -12 11 -43 -10 -48 -32
-103 -33 -132 -2 -31 33 -42 71 -42 141 0 120 68 196 136 151 14 -9 29 -26 31
-36 3 -12 14 -19 29 -19 22 0 24 4 24 44 0 42 -1 44 -37 50 -50 8 -101 6 -139
-5z"
        />
        <path
          d="M10615 1806 c-66 -21 -85 -35 -85 -61 0 -20 5 -25 25 -25 14 0 25 5
25 10 0 24 41 50 79 50 52 0 81 -35 81 -95 l0 -43 -52 -7 c-65 -9 -126 -30
-150 -51 -54 -50 -38 -143 29 -165 46 -15 92 -5 142 31 l41 30 0 -30 c0 -30 1
-30 55 -30 30 0 55 5 55 10 0 6 -4 10 -9 10 -5 0 -17 7 -25 16 -13 12 -16 40
-16 143 0 71 -5 143 -11 161 -19 54 -96 74 -184 46z m125 -242 c0 -37 -5 -51
-29 -75 -53 -52 -141 -32 -141 33 0 31 43 68 90 77 81 16 80 16 80 -35z"
        />
        <path
          d="M11105 1805 c-16 -9 -40 -26 -52 -37 l-23 -21 0 31 0 32 -60 0 c-33
0 -60 -4 -60 -10 0 -5 4 -10 9 -10 5 0 17 -7 25 -16 14 -13 16 -50 16 -250 0
-223 -1 -234 -20 -246 -42 -26 -23 -38 60 -38 47 0 80 4 80 10 0 6 -4 10 -9
10 -28 0 -41 34 -41 102 0 63 2 69 18 63 45 -19 117 -16 162 7 62 32 90 88 90
181 0 87 -15 139 -51 173 -35 33 -101 42 -144 19z m88 -65 c42 -50 48 -180 10
-253 -27 -53 -99 -62 -144 -18 -28 29 -29 32 -29 131 l0 102 34 34 c46 46 93
47 129 4z"
        />
        <path
          d="M11490 1798 c-64 -35 -95 -97 -94 -192 1 -95 28 -150 87 -177 23 -10
55 -19 71 -19 72 0 163 49 151 82 -7 17 -9 16 -37 -4 -17 -11 -52 -23 -78 -26
-41 -4 -51 -1 -80 25 -28 25 -34 38 -38 86 l-4 57 126 0 126 0 0 38 c0 119
-122 187 -230 130z m117 -23 c20 -14 43 -69 43 -102 0 -10 -25 -13 -90 -13
-100 0 -102 2 -76 64 25 60 79 82 123 51z"
        />
        <path
          d="M12789 1810 c-77 -23 -121 -93 -122 -193 -1 -81 16 -129 58 -165 59
-52 167 -54 227 -4 69 58 91 193 46 282 -36 72 -125 106 -209 80z m104 -40
c35 -27 51 -81 51 -166 -1 -53 -6 -81 -20 -105 -52 -89 -147 -70 -175 36 -13
48 -7 159 12 198 25 54 88 71 132 37z"
        />
        <path
          d="M13680 1798 c-64 -35 -95 -97 -94 -192 1 -95 28 -150 87 -177 23 -10
55 -19 71 -19 72 0 163 49 151 82 -7 17 -9 16 -37 -4 -17 -11 -52 -23 -78 -26
-41 -4 -51 -1 -80 25 -28 25 -34 38 -38 86 l-4 57 126 0 126 0 0 38 c0 119
-122 187 -230 130z m117 -23 c20 -14 43 -69 43 -102 0 -10 -25 -13 -90 -13
-100 0 -102 2 -76 64 25 60 79 82 123 51z"
        />
        <path
          d="M4870 1802 c0 -4 9 -13 19 -19 13 -8 38 -70 77 -188 56 -168 60 -175
85 -175 25 0 30 7 55 78 15 42 40 111 54 152 l27 75 26 -85 c14 -47 34 -114
44 -150 18 -60 21 -65 47 -65 26 0 31 7 96 170 38 94 78 177 89 187 12 9 21
19 21 22 0 3 -31 6 -70 6 -40 0 -70 -4 -70 -10 0 -5 4 -10 10 -10 5 0 14 -9
21 -21 9 -17 3 -42 -31 -140 -24 -65 -45 -119 -49 -119 -3 0 -16 37 -29 83
-61 218 -60 217 -90 217 -26 0 -29 -5 -66 -117 -22 -65 -46 -135 -55 -157
l-17 -38 -37 123 c-20 68 -37 130 -37 136 0 7 9 18 20 25 35 22 22 28 -60 28
-44 0 -80 -3 -80 -8z"
        />
        <path
          d="M5550 1801 c0 -5 10 -15 23 -23 21 -15 22 -21 22 -163 0 -140 -1
-148 -22 -165 -13 -10 -23 -21 -23 -24 0 -3 36 -6 80 -6 44 0 80 2 80 5 0 2
-11 15 -25 29 -25 25 -25 27 -25 191 l0 165 -55 0 c-30 0 -55 -4 -55 -9z"
        />
      </g>
    </svg>
  );
};

export function PortraitNotSupported() {
  return (
    <PortraitNotSupportedWrapper>
      <PortraitNotSupportedImageWrapper>
        <PortraitNotSupportedImage></PortraitNotSupportedImage>
      </PortraitNotSupportedImageWrapper>
    </PortraitNotSupportedWrapper>
  );
}
