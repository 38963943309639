/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiResponseMeta,
    ApiResponseMetaFromJSON,
    ApiResponseMetaFromJSONTyped,
    ApiResponseMetaToJSON,
    PaymentInitiateResponse,
    PaymentInitiateResponseFromJSON,
    PaymentInitiateResponseFromJSONTyped,
    PaymentInitiateResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentInitiateResponseApiResponse
 */
export interface PaymentInitiateResponseApiResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentInitiateResponseApiResponse
     */
    error?: string | null;
    /**
     * 
     * @type {ApiResponseMeta}
     * @memberof PaymentInitiateResponseApiResponse
     */
    meta?: ApiResponseMeta;
    /**
     * 
     * @type {PaymentInitiateResponse}
     * @memberof PaymentInitiateResponseApiResponse
     */
    data?: PaymentInitiateResponse;
}

export function PaymentInitiateResponseApiResponseFromJSON(json: any): PaymentInitiateResponseApiResponse {
    return PaymentInitiateResponseApiResponseFromJSONTyped(json, false);
}

export function PaymentInitiateResponseApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInitiateResponseApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'meta': !exists(json, 'meta') ? undefined : ApiResponseMetaFromJSON(json['meta']),
        'data': !exists(json, 'data') ? undefined : PaymentInitiateResponseFromJSON(json['data']),
    };
}

export function PaymentInitiateResponseApiResponseToJSON(value?: PaymentInitiateResponseApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'meta': ApiResponseMetaToJSON(value.meta),
        'data': PaymentInitiateResponseToJSON(value.data),
    };
}


