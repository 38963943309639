import {Container, createContainer} from 'brandi';
import {useInjection} from 'brandi-react';
import {
  ITransactionDatabaseAccess,
  ITransactionPaymentsDatabaseAccess,
} from './localDb/IDatabaseAccess';
import {TransactionPaymentLocaldb} from './localDb/TransactionPaymentLocaldb';
import {TransactionLocaldb} from './localDb/TransactionsLocaldb';
import {ITransactionService} from './services/ITransactionService';
import {TransactionService} from './services/TransactionService';
import {TOKENS} from './tokens';

export class DIFactory {
  static buildDefaultContainer = (): Container => {
    const container = createContainer();

    container
      .bind(TOKENS.TransactionService)
      .toInstance(TransactionService)
      .inTransientScope();

    container
      .bind(TOKENS.TransactionDatabaseAccess)
      .toInstance(TransactionLocaldb)
      .inTransientScope();

    container
      .bind(TOKENS.TransactionPaymentsDatabaseAccess)
      .toInstance(TransactionPaymentLocaldb)
      .inTransientScope();

    return container;
  };

  static getTransactionService = (
    sessionId: string,
    token: string,
  ): ITransactionService => {
    const svc = useInjection(TOKENS.TransactionService);
    svc.initialize(sessionId, token);
    return svc;
  };

  static getTransactionDatabaseAccess = (
    sessionId: string,
  ): ITransactionDatabaseAccess => {
    const axs = useInjection(TOKENS.TransactionDatabaseAccess);
    axs.initialize(sessionId);
    return axs;
  };

  static getTransactionPaymentsDatabaseAccess = (
    sessionId: string,
  ): ITransactionPaymentsDatabaseAccess => {
    const axs = useInjection(TOKENS.TransactionPaymentsDatabaseAccess);
    axs.initialize(sessionId);
    return axs;
  };
}
