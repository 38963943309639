import React, {ReactNode, PropsWithoutRef, memo, useRef} from 'react';
import styled from 'styled-components';
import {useSpring, animated} from 'react-spring';
import {Text, TextVariant as Variant} from './';

export interface SegmentSliderProps {
  items: Array<ReactNode>;
  active: number;
  onSelectIndex(select: number): void;
  'data-testid'?: string;
}

const width = 130;
const spacing = 8;
const Container = styled.div`
  background: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.gray_200};
  border-radius: 40px;
  display: inline-flex;
  flex-direction: row;
  padding: ${spacing}px;
  position: relative;
`;

const Item = styled.button<{active: boolean}>`
  border: 0;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  width: ${width}px;
  padding: 6px;
  transition: all 300ms ease-out;
  cursor: pointer;
  position: relative;

  &:focus {
    outline: none;
  }
`;

const Txt = styled(Text)<{active: boolean}>`
  color: ${({theme, active}) =>
    active ? theme.colors.blue : theme.colors.steel};
`;

const BackgroundDiv = styled.div`
  background: ${({theme}) => theme.colors.gray_100};
  border-radius: 40px;
  position: absolute;
  width: ${width}px;
  top: ${spacing}px;
  bottom: ${spacing}px;
`;
const Background = animated(BackgroundDiv);

export const SegmentSlider = memo(
  ({
    items,
    active,
    onSelectIndex,
    'data-testid': dataTestId,
  }: PropsWithoutRef<JSX.IntrinsicElements['div']> & SegmentSliderProps) => {
    const ref = useRef(null);
    const spring = useSpring({
      value: active,
      from: {value: 0},
    });
    return (
      <Container data-testid={dataTestId || 'SegmentSlider'}>
        <Background
          style={{
            transform: spring.value.to(o => `translateX(${o * width}px)`),
          }}
        />
        {items.map((item, index) => {
          return (
            <Item
              onClick={_ => onSelectIndex(index)}
              active={active === index}
              ref={active === index ? ref : null}
              key={index}
              type="button"
            >
              <Txt active={active === index} variant={Variant.SubMain}>
                {item}
              </Txt>
            </Item>
          );
        })}
      </Container>
    );
  },
);
