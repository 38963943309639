/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentVaultRequest
 */
export interface PaymentVaultRequest {
    /**
     * The site id
     * @type {number}
     * @memberof PaymentVaultRequest
     */
    siteId: number;
    /**
     * Can be 'Transport' or 'ECOM'
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    credentialType: string;
    /**
     * The Token to be used for a vault request
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    token: string;
    /**
     * The amount for all QHP elgible items
     * @type {number}
     * @memberof PaymentVaultRequest
     */
    qhpAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentVaultRequest
     */
    totalAmount: number;
    /**
     * Internal Id to identify the transaction
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    transactionId?: string | null;
    /**
     * The unique Id for the terminal, if required.
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    terminalId: string;
    /**
     * Identifier for the user processing
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    userId: string;
    /**
     * Unique number to pass to gateway.
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    purchaseOrderNumber?: string | null;
    /**
     * The raw response from the transaction processor when transaction was staged
     * @type {string}
     * @memberof PaymentVaultRequest
     */
    rawTransactionStagingResponse?: string | null;
}

export function PaymentVaultRequestFromJSON(json: any): PaymentVaultRequest {
    return PaymentVaultRequestFromJSONTyped(json, false);
}

export function PaymentVaultRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentVaultRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteId': json['siteId'],
        'credentialType': json['credentialType'],
        'token': json['token'],
        'qhpAmount': json['qhpAmount'],
        'totalAmount': json['totalAmount'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'terminalId': json['terminalId'],
        'userId': json['userId'],
        'purchaseOrderNumber': !exists(json, 'purchaseOrderNumber') ? undefined : json['purchaseOrderNumber'],
        'rawTransactionStagingResponse': !exists(json, 'rawTransactionStagingResponse') ? undefined : json['rawTransactionStagingResponse'],
    };
}

export function PaymentVaultRequestToJSON(value?: PaymentVaultRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteId': value.siteId,
        'credentialType': value.credentialType,
        'token': value.token,
        'qhpAmount': value.qhpAmount,
        'totalAmount': value.totalAmount,
        'transactionId': value.transactionId,
        'terminalId': value.terminalId,
        'userId': value.userId,
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'rawTransactionStagingResponse': value.rawTransactionStagingResponse,
    };
}


