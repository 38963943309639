/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SignatureImage,
    SignatureImageFromJSON,
    SignatureImageToJSON,
    SignatureImageRequest,
    SignatureImageRequestFromJSON,
    SignatureImageRequestToJSON,
} from '../models';

export interface ClientCacheSignaturesImagesPutRequest {
    signatureImageRequest?: SignatureImageRequest;
}

export interface ClientCacheSignaturesImagesSignatureImageIdDeleteRequest {
    signatureImageId: string;
}

export interface ClientCacheSignaturesImagesSignatureImageIdGetRequest {
    signatureImageId: string;
}

/**
 * 
 */
export class SignatureImagesApi extends runtime.BaseAPI {

    /**
     * Inserts or updates a session transaction signature image
     */
    async clientCacheSignaturesImagesPutRaw(requestParameters: ClientCacheSignaturesImagesPutRequest): Promise<runtime.ApiResponse<SignatureImage>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; v=1.5';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/signatures/images`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignatureImageRequestToJSON(requestParameters.signatureImageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureImageFromJSON(jsonValue));
    }

    /**
     * Inserts or updates a session transaction signature image
     */
    async clientCacheSignaturesImagesPut(requestParameters: ClientCacheSignaturesImagesPutRequest): Promise<SignatureImage> {
        const response = await this.clientCacheSignaturesImagesPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an signatureImage
     */
    async clientCacheSignaturesImagesSignatureImageIdDeleteRaw(requestParameters: ClientCacheSignaturesImagesSignatureImageIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signatureImageId === null || requestParameters.signatureImageId === undefined) {
            throw new runtime.RequiredError('signatureImageId','Required parameter requestParameters.signatureImageId was null or undefined when calling clientCacheSignaturesImagesSignatureImageIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/signatures/images/{signatureImageId}`.replace(`{${"signatureImageId"}}`, encodeURIComponent(String(requestParameters.signatureImageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an signatureImage
     */
    async clientCacheSignaturesImagesSignatureImageIdDelete(requestParameters: ClientCacheSignaturesImagesSignatureImageIdDeleteRequest): Promise<void> {
        await this.clientCacheSignaturesImagesSignatureImageIdDeleteRaw(requestParameters);
    }

    /**
     * Gets a signature image by id
     */
    async clientCacheSignaturesImagesSignatureImageIdGetRaw(requestParameters: ClientCacheSignaturesImagesSignatureImageIdGetRequest): Promise<runtime.ApiResponse<SignatureImage>> {
        if (requestParameters.signatureImageId === null || requestParameters.signatureImageId === undefined) {
            throw new runtime.RequiredError('signatureImageId','Required parameter requestParameters.signatureImageId was null or undefined when calling clientCacheSignaturesImagesSignatureImageIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["clientapi"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/client/cache/signatures/images/{signatureImageId}`.replace(`{${"signatureImageId"}}`, encodeURIComponent(String(requestParameters.signatureImageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignatureImageFromJSON(jsonValue));
    }

    /**
     * Gets a signature image by id
     */
    async clientCacheSignaturesImagesSignatureImageIdGet(requestParameters: ClientCacheSignaturesImagesSignatureImageIdGetRequest): Promise<SignatureImage> {
        const response = await this.clientCacheSignaturesImagesSignatureImageIdGetRaw(requestParameters);
        return await response.value();
    }

}
