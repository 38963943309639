import {Transaction} from '@emporos/api-enterprise';
import * as yup from 'yup';
import {diffYears, isAfterNow, isBeforeNow} from '../../../../../';
import {mapIdCheckIndicators} from './';

/*
 *  Fields that are always required:
 *      - First Name
 *      - Last Name
 *      - Zip Code
 *      - DOB
 *
 *  Fields that are only required when there is PSE/Controlled OTC Item:
 *      - ID State
 *      - Address 1
 *      - City
 *      - State
 *
 *  Fields that are only required when OTHER ID Type is not used:
 *      - ID Number
 *      - ID Expiration Date
 *
 *  Fields that are only required when OTHER ID Type is used:
 *      - One of the options selected. Valid Custom entry should check the box for that option.
 *      - Reason for Override
 *
 *  Fields that are never required:
 *      - Middle Name/Initial
 *      - Address 2
 */
export const mapSchema = (
  transaction: Transaction,
  maxAge = 125,
  maxIdExpiration = 50,
): yup.ObjectSchema<yup.AnyObject> => {
  const idNumber = yup.string().max(50).required();
  const firstName = yup.string().max(50).required();
  const middleName = yup.string().max(50);
  const lastName = yup.string().max(50).required();

  let dob = yup
    .string()
    .required()
    .test(
      'dob',
      'Invalid dob',
      _dateOfBirth =>
        !!_dateOfBirth &&
        isBeforeNow(_dateOfBirth) &&
        Math.abs(diffYears(_dateOfBirth as string)) <= Math.abs(maxAge),
    );

  const idExpirationDate = yup
    .string()
    .required()
    .test('idExpirationDate', 'Invalid idExpirationDate', _expirationDate => {
      return (
        !!_expirationDate &&
        isAfterNow(_expirationDate) &&
        Math.abs(diffYears(_expirationDate as string)) <=
          Math.abs(maxIdExpiration)
      );
    });

  let address1 = yup
    .string()
    .matches(/^[A-Za-z0-9- ,.'#/]*$/)
    .max(100);
  const address2 = yup
    .string()
    .matches(/^[A-Za-z0-9- ,.'#/]*$/)
    .max(100);
  let city = yup.string().max(50);
  let state = yup.string().nullable();
  const zipCode = yup
    .string()
    .matches(/^\d{5}$/)
    .required();
  const gender = yup.string().nullable();

  const {isNplex, isControlledSubstance, isControlledOTC, isRequiredAge, age} =
    mapIdCheckIndicators(transaction);

  // if NPLEX or controlled substance, require name and address fields
  if (isNplex || isControlledSubstance || isControlledOTC) {
    address1 = address1.required();
    city = city.required();
    state = state.required();
  }

  if (isRequiredAge && age) {
    dob = dob.test(
      'dob',
      '',
      _dateOfBirth => !!_dateOfBirth && diffYears(_dateOfBirth) >= age,
    );
  }

  return yup.object().shape({
    idNumber,
    firstName,
    middleName,
    lastName,
    dob,
    idExpirationDate,
    address1,
    address2,
    city,
    state,
    zipCode,
    gender,
  });
};
