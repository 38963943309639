import {trimEnd, trimStart} from 'lodash';
import {Symbology} from '../../KeyboardScannerService';
import {BarcodeParsedResult, DELIMITER, PREFIX} from '../IPMSStrategy';
import {CompleteBarcodeComponent} from '../../BarcodeScannerService';
import {BaseStrategy} from '../BaseStrategy';

export class McKessonStrategy extends BaseStrategy {
  validSymbologies = [Symbology.Code128, Symbology.Code39];

  getDelimiter(
    components: CompleteBarcodeComponent[],
    rawBarcode: string,
  ): string | undefined {
    const delimiter = components.find(
      c => c.componentName.toLowerCase() === DELIMITER,
    )?.componentMask;

    return delimiter !== undefined && rawBarcode.includes(delimiter)
      ? delimiter.trim()
      : undefined;
  }

  generateRegexPattern(
    componentsCopy: CompleteBarcodeComponent[],
    componentNames: string[],
    delimiter: string | undefined,
  ): string {
    let regexPattern = '';
    componentsCopy.forEach(component => {
      if (
        component.componentIndex > 0 &&
        component.componentName &&
        component.componentMask
      ) {
        const name = component.componentName.toLowerCase();
        componentNames.push(name);
        const mask = trimEnd(trimStart(component.componentMask, '^'), '$');

        if (name === PREFIX) {
          regexPattern = regexPattern + '(' + mask + ')';
        } else {
          if (delimiter) {
            regexPattern = regexPattern + '(\\' + delimiter + mask + ')';
          } else {
            regexPattern = regexPattern + '(' + mask + ')';
          }
        }
      }
    });
    regexPattern = '^' + regexPattern + '$';

    return regexPattern;
  }

  getComponentsValues(
    componentValues: string[],
    matches: RegExpMatchArray,
    delimiter: string | undefined,
  ): void {
    for (let index = 1; index < matches.length; index++) {
      const match = matches[index];
      if (!delimiter) {
        componentValues.push(match);
      } else {
        componentValues.push(match.replace(delimiter, ''));
      }
    }
  }

  reduceComponents(
    parsedResult: BarcodeParsedResult,
    componentNames: string[],
    componentValues: string[],
  ): BarcodeParsedResult {
    return componentNames.reduce((accumulator, name, index) => {
      const value = name ? componentValues[index] : '';
      switch (name) {
        case 'rxnumber':
          accumulator.rx = value;
          return accumulator;
        case 'refillnumber':
          accumulator.fill = value;
          return accumulator;
        case 'partial':
          accumulator.partial = value;
          return accumulator;
        case 'store':
          accumulator.site = value;
          return accumulator;
        default:
          return accumulator;
      }
    }, parsedResult);
  }
}
