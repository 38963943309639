import {useReactOidc} from '@axa-fr/react-oidc-context';
import {User} from 'oidc-client';
import {createContext, useContext, useEffect, useMemo} from 'react';
import {IdleTimer} from '../components/IdleTimer';
import {AuthClaim, PreLogin, TEST_USER_KEY} from '../';
import {NavigationLogTypes, useAnalyticsProvider, useLog} from './';
import {deleteDatabase} from '../localDb/dbcontext';

interface Context {
  user: User | null;
  login: () => Promise<void>;
  logout: () => void;
}

export const SESSION_TIMEOUT = 1000 * 60 * 10; // 10 minutes

export const AuthenticationContext = createContext<Context>({
  user: null,
  login: () => Promise.reject('Unable to find AuthenticationProvider.'),
  logout: () => () => {
    return;
  },
});

interface Props {
  children: JSX.Element;
}
export function AuthenticationProvider({children}: Props): JSX.Element {
  const {identify, reset} = useAnalyticsProvider();
  const {logUserSelection} = useLog();
  const {NODE_ENV} = process.env;
  const {login: oidcLogin, logout: oidcLogout, oidcUser} = useReactOidc();
  const user: User = useMemo(() => {
    const serializedTestUser = localStorage.getItem(TEST_USER_KEY);
    return (
      oidcUser || (serializedTestUser ? JSON.parse(serializedTestUser) : null)
    );
  }, [oidcUser]);

  useEffect(() => {
    if (user) {
      identify(`${user.profile[AuthClaim.UserId]}`, user);
    }
  }, [user]);

  const login = async () => {
    logUserSelection(NavigationLogTypes.UserLogin);
    if (String(NODE_ENV) === 'test') {
      window.location.assign('/sales');
    } else {
      oidcLogin();
    }
  };

  const logout = async () => {
    logUserSelection(NavigationLogTypes.UserLogout);
    localStorage.removeItem(TEST_USER_KEY);
    deleteDatabase(user.profile[AuthClaim.UserId]);
    reset();
    oidcLogout();
  };

  const api = {
    user,
    login,
    logout,
  };

  return (
    <AuthenticationContext.Provider value={api}>
      {user ? (
        <>
          {children}
          <IdleTimer
            // TODO: make timeout configurable
            timeout={SESSION_TIMEOUT}
            onIdle={logout}
            debounce={250}
          />
        </>
      ) : (
        <PreLogin onRedirect={login} />
      )}
    </AuthenticationContext.Provider>
  );
}
export const useAuthentication = (): Context =>
  useContext(AuthenticationContext);
